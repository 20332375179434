import React, { useEffect, useState } from 'react';
import { getDocs, collection, query } from 'firebase/firestore';
import { db } from '../../firebase-config';
import MonthlyExpensesIncomeChart from './MonthlyExpensesIncomeChart';
import BreadCrumb from '../BreadCrumb';
import { Link } from 'react-router-dom';
import AccessDenied from '../AccessDenied';
import useRoleRestriction from '../../context/useRoleRestriction';


const parseDate = (dateString) => {
  if (!dateString) return null;
  let date;
  if (dateString?.toDate) {
    // Firestore timestamp
    date = dateString.toDate();
  } else {
    // Try parsing as a string
    date = new Date(dateString);
  }
  if (!isNaN(date)) return date;
  console.error("Could not parse date:", dateString);
  return null;
};



const pages = [
  { name: 'Owners Center', to: '/settings', component: Link, current: false },  
  { name: 'Financial Reports', to: '/settings/finance', component: Link, current: false },
  { name: 'Income Reports', to: '/settings/finance/incomereport', component: Link, current: true },     
]

const IncomeExpenseReport = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const transactionsRef = collection(db, 'transactions');
      const transactionsQuery = query(transactionsRef);
      const querySnapshot = await getDocs(transactionsQuery);

      const data = {};

      querySnapshot.forEach((doc) => {
        const transaction = doc.data();
        const date = parseDate(transaction.dateOfPosting || transaction.dateIncurred);
        if (!date) {
          console.error('Invalid transaction date', transaction);
          return;
        }
        const monthYear = `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;

        if (!data[monthYear]) {
          data[monthYear] = { monthYear, totalExpenses: 0, totalIncome: 0 }; // Use new key names here
        }

        if (transaction.transactionType === 'income') {
          data[monthYear].totalIncome += Math.abs(transaction.amount); // Update key to totalIncome
        } else if (transaction.transactionType === 'expense') {
          data[monthYear].totalExpenses += Math.abs(transaction.amount); // Update key to totalExpenses
        }
      });

      setChartData(Object.values(data));
    };

    fetchData();
  }, []);

  
  const allowedRoles = ["Admin", "Finance"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return < AccessDenied />
  }

  return (
    <>
    <BreadCrumb pages={pages}/>
    
    <div>
      <h1>Monthly Expenses and Income Chart</h1>
      <MonthlyExpensesIncomeChart data={chartData} />
    </div>

    </>
  );
};

export default IncomeExpenseReport;
