import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { useUserRole } from "../context/UserRoleContext";
import { doc, getDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import biglogo from "../assets/biglogo.png";

export default function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { logIn } = useUserAuth();
    const { setUserRole } = useUserRole();
    const [authMessage, setAuthMessage] = useState('');
    let navigate = useNavigate();

    
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const userCredential = await logIn(email, password);
         const user = userCredential.user;
        // Fetch user role
  const userRef = doc(collection(db, "users"), user.uid);
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) {
    const userData = userDoc.data();
    setUserRole(userData.role);
    setAuthMessage('Logged in successfully!');
  } else {
    setUserRole(null);
    setAuthMessage(`Error: ${error.message}`);
  }
      navigate("/");
    } catch (error) {
      setAuthMessage(`Error: ${error.message}`);
    }



  };

   
   


  return (
    <>
    
      
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-auto w-auto"
            src={biglogo }
            alt="Elite Funerals"
          />
          <p className="mt-4 text-center text-2xl leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-4 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" onSubmit={handleSubmit} method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

           
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
                <Link to="/login/passwordreset">Forgot Password?</Link>
              </div>
            </form>
            {authMessage && <p>{authMessage}</p>}

           
          </div>

     
        </div>
      </div>
    </>
  )
}
