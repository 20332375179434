import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db , auth } from '../../firebase-config';
import { Link } from 'react-router-dom'
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';
import { formatCurrency } from '../Deceased/helpers';
import PaymentModal from './PaymentModal';
import BreadCrumb from '../BreadCrumb';
import { convertDateToFirestoreTimestamp } from '../Deceased/helpers';

const pages = [
    { name: 'Finance', to: '/finance', component: Link, current: false },
    { name: 'Referal Agents', to: '/finance/agent', component: Link, current: false },
    { name: 'Partner Agents Commissions Payable', to: '/finance/agent/pay-storage', component: Link, current: true },
   
  ]

const PartnerAgentsCommissionList = () => {
    const [partnerAgents, setPartnerAgents] = useState([]);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [selectedCommission, setSelectedCommission] = useState(null);
    const user = auth.currentUser;


    const fetchPartnerAgents = async () => {
      // Fetch all Partner Agents
      const q = query(collection(db, 'referralAgents'), where('agentType', '==', 'Partner'));
      const agentsSnapshot = await getDocs(q);
      const agents = agentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), deceasedCommissions: [] }));

      // Fetch approved commissions and deceased details for each Partner Agent
      for (let agent of agents) {
          const deceasedQuery = query(
              collection(db, 'deceased'), 
              where('referralAgentId', '==', agent.id),
              where('commissionApproved', '==', true),
              where('commissionPaid', '!=', true)
          );
          const deceasedSnapshot = await getDocs(deceasedQuery);
          agent.deceasedCommissions = deceasedSnapshot.docs.map(doc => {
              const deceasedData = doc.data();
              const duration = calculateDuration(deceasedData.dateDeposited, deceasedData.exitDate);
              return {
                id: doc.id,
                  name: `${deceasedData.firstName} ${deceasedData.lastName}`,
                  commissionAmount: deceasedData.commissionAmount || 0,
                  duration: duration
              };
          });
      }

      setPartnerAgents(agents);
  };

  useEffect(() => {
      fetchPartnerAgents();
  }, []);

    const calculateDuration = (startDate, endDate) => {
        if (!startDate || !endDate) return 0;
        const start = new Date(startDate);
        const end = endDate.toDate(); // Convert Firebase Timestamp to JavaScript Date
        return Math.ceil((end - start) / (1000 * 60 * 60 * 24)); // Days difference
    };

   
    const openPaymentModal = (deceased, agent) => {
      if (!deceased || !agent) {
          console.error('Missing deceased or agent data', deceased, agent);
          return;
      }
  
      const commissionData = {
          deceasedId: deceased.id,
          commissionAmount: deceased.commissionAmount,
          agentFirstName: agent.firstName,
          agentLastName: agent.lastName,
          agentEmail: agent.email // Assuming agent's email is in the agent object
      };
  
      setSelectedCommission(commissionData);
      setShowPaymentModal(true);
  };
  
  
    
  
 
  const handlePaymentSubmit = async (paymentDetails) => {
    if (selectedCommission) {
        await handleCommissionPayment(selectedCommission.deceasedId, paymentDetails, selectedCommission);
    } else {
        console.error('Error: Commission details not available');
    }
};



const handleCommissionPayment = async (deceasedId, paymentData) => {
  console.log('Selected Commission:', selectedCommission);

  try {
       const deceasedRef = doc(db, 'deceased', deceasedId);
      await updateDoc(deceasedRef, { commissionPaid: true });

      
      await addDoc(collection(db, 'transactions'), {
          amount: paymentData.amount,
          dateIncurred: convertDateToFirestoreTimestamp(paymentData.dateOfPayment),
          dateOfPosting: new Date(), // Current date and time
          modeOfPayment: paymentData.modeOfPayment,
          transactionType: 'expense', // Static string as per requirement
          text: `Payment of Commission for ${selectedCommission.agentFirstName} ${selectedCommission.agentLastName}`,
          user: user.email 
           });

           setPartnerAgents(prevAgents => prevAgents.map(agent => {
            if (agent.id === paymentData.agentId) {
                return {
                    ...agent,
                    deceasedCommissions: agent.deceasedCommissions.map(deceased => {
                        if (deceased.id === deceasedId) {
                            return { ...deceased, commissionPaid: true };
                        }
                        return deceased;
                    })
                };
            }
            return agent;
        }));
        await fetchPartnerAgents();
      setShowPaymentModal(false);
    
  } catch (error) {
      console.error('Error processing commission payment:', error);
      
  }
};



    const allowedRoles = ["Admin", "Finance", "Front-Desk"];
    const isAllowed = useRoleRestriction(allowedRoles);
  
    if (!isAllowed) {
      return < AccessDenied />
    }

    return (
        <>
     <BreadCrumb pages={pages} />

    <PaymentModal
                isOpen={showPaymentModal}
                onClose={() => setShowPaymentModal(false)}
                selectedCommission={selectedCommission}
                onSubmit={handlePaymentSubmit}
               
            />

        <div className="container mx-auto p-4">
            <h2 className="text-xl font-semibold mb-4">Unpaid Partner Agents Commissions</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3">Agent Name</th>
                            <th scope="col" className="px-6 py-3">Deceased Name</th>
                            <th scope="col" className="px-6 py-3">Duration (Days)</th>
                            <th scope="col" className="px-6 py-3">Daily Storage Commission</th>
                            <th scope="col" className="px-6 py-3">Commission Payable</th>
                        </tr>
                    </thead>
                    <tbody>
                        {partnerAgents.map(agent => agent.deceasedCommissions.map((deceased, index) => (
                            <tr key={`${agent.id}-${index}`} className="bg-white border-b">
                                <td className="px-6 py-4">{index === 0 ? `${agent.firstName} ${agent.lastName}` : ''}</td>
                                <td className="px-6 py-4">{deceased.name}</td>
                                <td className="px-6 py-4">{deceased.duration}</td>
                                <td className="px-6 py-4">{formatCurrency(agent.partnerStorageFee)}</td>
                                <td className="px-6 py-4">
                                  {deceased.commissionAmount !== 0 ? 
                               formatCurrency(deceased.commissionAmount) : deceased.commissionAmount}
                                 </td>
                                 <td className="px-6 py-4">
                                    {deceased.commissionPaid ? (
                                        <span className="text-green-500 font-bold">Paid</span>
                                    ) : (
                                      
                                      <button 
                                      onClick={() => openPaymentModal(deceased, agent)}
                                        className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                    >
                                        Pay
                                    </button>

                                    )}
                                 </td>
                            </tr>
                        )))}
                    </tbody>
                </table>
            </div>
        </div>
        </>
    );
};

export default PartnerAgentsCommissionList;
