import React, { useState } from 'react';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import useRoleRestriction from '../context/useRoleRestriction';
import AccessDenied from './AccessDenied';

const pages = [
    { name: 'Exit Clearance', to: '/security', component: Link, current: true },
   
  ]

const ExitCodeLookup = () => {
  const [exitCode, setExitCode] = useState("");
  const [result, setResult] = useState("");

  const handleSearch = async () => {
    const db = getFirestore();
    const deceasedRef = collection(db, "deceased");
    const q = query(deceasedRef, where("exitCode", "==", exitCode));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      let deceasedData = querySnapshot.docs[0].data();
      setResult(`Cleared for Exit: ${deceasedData.firstName} ${deceasedData.lastName}`);
    } else {
      setResult("Invalid code");
    }
  };

  const allowedRoles = ["Admin", "Finance", "Front-Desk"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return < AccessDenied />
  }

  return (
    <>

        <nav className="flex  py-2 px-14 sm:px-14" aria-label="Breadcrumb">
            <ol  className="flex items-center space-x-4">
                <li>
                <div>
                    <Link to="/" className="text-gray-400 hover:text-gray-500">
                    <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                    </Link>
                </div>
                </li>
                {pages.map((page) => (
                <li key={page.name}>
                    <div className="flex items-center">
                    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <Link to={page.to}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        aria-current={page.current ? 'page' : undefined}
                    >
                        {page.name}
                    </Link>
                    </div>
                </li>
                ))}
            </ol>
            </nav>

    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-2 px-4 sm:px-6 lg:px-8">
        
      <div className="max-w-md w-full space-y-10"> 
                
        <div>
          <h2 className="mt-1 text-center text-3xl font-extrabold text-gray-900">
            Deceased Exit Clearance 
          </h2>
        </div>
        <div className="rounded-md shadow-sm space-y-px">
          <div>
            <input 
              type="text"
              value={exitCode}
              onChange={e => setExitCode(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 
              placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
              focus:z-10 sm:text-sm"
              placeholder="Enter exit code"
            />
          </div>
        </div>
        <div>
          <button 
            onClick={handleSearch}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Search
          </button>
        </div>
        {result && <div className="mt-2 text-center text-lg font-medium text-gray-900">{result}</div>}
      </div>
    </div>
  );

</>
  )
};



export default ExitCodeLookup;
