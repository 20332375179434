import { collection, addDoc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import app from '../../firebase-config';
import {generateReceipt} from '../../utils/generateReceipt';

const firestore = getFirestore(app);

export const createPayment = async (nextOfKinId, deceasedId, amount, paymentDate, paymentType, status) => {
  const paymentData = {
    nextOfKinId,
    deceasedId,
    amount,
    paymentDate,
    paymentType,
    status,
  };
  const paymentDocRef = await addDoc(collection(firestore, 'payments'), paymentData);
  console.log('Payment document written with ID: ', paymentDocRef.id);

  const auth = getAuth(app);
  const currentUser = auth.currentUser;

  if (currentUser) {
    const auditData = {
      action: 'create',
      userId: currentUser.uid,
      collectionName: 'payments',
      documentId: paymentDocRef.id,
      timestamp: new Date().toISOString(),
    };
    await addDoc(collection(firestore, 'auditLogs'), auditData);
  }
};
