import { Fragment } from 'react'


import { BuildingStorefrontIcon, HomeIcon,  ShoppingCartIcon,
    BanknotesIcon,   ChevronRightIcon} from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom'
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';


const pages = [
  { name: 'Finance', to: '/finance', component: Link, current: false },
  { name: 'Referal Agents', to: '/finance/agent', component: Link, current: true },
 
]



const cardsData = [
  { id: 1, title: 'Agent Registration', content: 'Add new Agent and Edit or Remove Existing Referral Agent.', link: '/finance/agent/manage', icon: <BanknotesIcon className="h-6 w-6 text-red-500 mb-2 mx-auto" /> },
  { id: 2, title: 'All Commissions', content: 'View All Rejected and Approved Commissions to registered Agents.', link: '/finance/agent/all', icon: <ShoppingCartIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
  { id: 3, title: 'Due Partner Payments', content: 'Approved Storage Partner Agent Commission Due for Payments.', link: '/finance/agent/pay-storage', icon: <ShoppingCartIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
 
  
];




export default function AgentContainer() {
 


  const allowedRoles = ["Admin", "Finance", "Front-Desk"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return < AccessDenied />
  }



  return (
    <>
        
    
      
      <main>
      <nav className="flex gap-10 px-14 sm:px-14" aria-label="Breadcrumb">
      <ol  className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <Link to={page.to}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>

        <div className="container mx-auto py-5">
        <div className="flex flex-wrap">
  {cardsData.map((card) => (
    <div key={card.id} className="w-1/4 p-4">
      <Link to={card.link}>
        <div className="bg-white shadow-md rounded p-4">
          {card.icon}
          <h3 className="text-xl font-bold mb-2">{card.title}</h3>
          <p>{card.content}</p>
        </div>
      </Link>
    </div>
  ))}
</div>








    </div>

 

      
      </main>
    </>
  )
}
