import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchProductList, deleteProduct } from './productService';
import AccessDenied from '../AccessDenied';
import useRoleRestriction from '../../context/useRoleRestriction';
import {formatCurrency} from '../Deceased/helpers';
import BreadCrumb from '../BreadCrumb';


const pages = [
  { name: 'Owner', to: '/settings', component: Link, current: false },
  { name: 'Manage Inventory', to: '/settings/products', component: Link, current: true }, 
]


function ProductCard({ product, onDelete }) {
  const handleDelete = async (productId) => {
    try {
      await deleteProduct(productId);
      onDelete(productId);
    } catch (error) {
      console.error('Error deleting product:', error.message);
    }
  };

  

  const allowedRoles = ["Admin", "Finance"];
  const isAllowed = useRoleRestriction(allowedRoles);
  if (isAllowed === null) return <div>Loading...</div>;

  if (!isAllowed) {
    return < AccessDenied />
  }



    return (


    <div className="bg-white rounded-md shadow-lg p-4">
      <h2 className="text-xl font-semibold mb-2">{product.description}</h2>
      <p>Cost Price: {formatCurrency(product.costPrice)}</p>
      <p>Selling Price: {formatCurrency(product.sellingPrice)}</p>
      <p>Quantity: {product.quantity}</p>
      <div className="mt-4">
        <Link
          to={`/finance/products/update/${product.id}`}
          className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
        >
          Update
        </Link>
        <button
          onClick={() => handleDelete(product.id)}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Delete
        </button>
       
      </div>
    </div>
  );
}

function ProductPage() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await fetchProductList();
      setProducts(data);
    }
    fetchData();
  }, []);

  const handleProductDelete = (deletedProductId) => {
    setProducts(products.filter((product) => product.id !== deletedProductId));
  };



  return (
    <>
       <BreadCrumb pages={pages} />

    <div className="container mx-auto py-3">

    <div className="flex justify-center px-9 py-3">
       
        <Link to="/settings/products/add">
          
        <button className="bg-amber-600 text-white px-4 py-2 rounded pr-3 mr-3 hover:bg-green-700">  Add New Product   </button>
         
        </Link>
        

        
        <div className="flex justify-center">   
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {products.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          onDelete={handleProductDelete}
        />
      ))}
      </div>
      </div>
    </div>
    </div>
    </>
  );
}

export default ProductPage;
