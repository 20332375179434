import { collection, addDoc, doc, updateDoc, getDocs,setDoc,getDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';

export const addReferralAgent = async (referralAgentData) => {
  const newReferralAgentRef = doc(collection(db, 'referralAgents'));
  await setDoc(newReferralAgentRef, referralAgentData);
  return { ...referralAgentData, id: newReferralAgentRef.id };
};

export const fetchReferralAgents = async () => {
  const querySnapshot = await getDocs(collection(db, 'referralAgents'));
  return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

export const updateReferralAgent = async (referralAgentId, deceasedId) => {
  const referralAgentRef = doc(db, 'referralAgents', referralAgentId);

  // Fetch existing deceasedIds array
  const referralAgentDoc = await getDoc(referralAgentRef);
  const existingDeceasedIds = referralAgentDoc.data().deceasedIds || [];

  // Update the referral agent with the new deceased ID
  await updateDoc(referralAgentRef, {
    deceasedIds: [...existingDeceasedIds, deceasedId],
  });
};
