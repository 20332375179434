export const calculateDaysInStorage = (allocations) => {
  if (!Array.isArray(allocations)) {
    console.error('Expected allocations to be an array, but received:', allocations);
    return "N/A";  // or return a default value suitable for your application
  }
  
  let totalDaysInStorage = 0;

  const sortedAllocations = [...allocations].sort((a, b) => new Date(a.dateAssigned) - new Date(b.dateAssigned));
  
  for (let i = 0; i < sortedAllocations.length; i++) {
    const startDate = new Date(sortedAllocations[i].dateAssigned);
    const endDate = sortedAllocations[i + 1]
      ? new Date(sortedAllocations[i + 1].dateAssigned)
      : new Date(); // if there is no next allocation, use the current date

    const diffInMilliseconds = endDate - startDate;
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const daysInAllocation = Math.floor(diffInMilliseconds / millisecondsInADay);

    totalDaysInStorage += daysInAllocation;
  }

  return totalDaysInStorage;
};

export function calculateDeceasedTotalCost(dec) {
  let total = 0;

  if (!Array.isArray(dec.allocations)) {
    console.log("No allocations for this dec object");
    return 0;  // or handle this situation in another way
  }

  // sort allocations by date
  const allocations = [...dec.allocations].sort((a, b) => new Date(a.dateAssigned) - new Date(b.dateAssigned));

  // calculate cost for each allocation period
  for (let i = 0; i < allocations.length; i++) {
    const allocation = allocations[i];
    const nextAllocation = allocations[i + 1];

    const startDate = new Date(allocation.dateAssigned);
    const endDate = nextAllocation
      ? new Date(nextAllocation.dateAssigned)
      : dec.exitDate
      ? new Date(dec.exitDate)
      : new Date();

    const diffInMilliseconds = endDate - startDate;
    const millisecondsInADay = 1000 * 60 * 60 * 24;

    const daysInStorage = Math.floor(diffInMilliseconds / millisecondsInADay);
    const cost = daysInStorage * Number(allocation.storageFees);

    total += cost;
  }

  return total;
}
