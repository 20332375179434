import React, { useState } from 'react';
import { MoonLoader } from 'react-spinners';
import { db } from '../../firebase-config';
import { Link } from 'react-router-dom'
import { collection, query, where, getDocs } from 'firebase/firestore';
import BreadCrumb from '../BreadCrumb';
import { formatCurrency } from '../Deceased/helpers';

const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: false },  
    { name: 'Financial Reports', to: '/settings/finance', component: Link, current: false },   
    { name: 'Invoice Income', to: '/settings/finance/invoice-income', component: Link, current: true },     
  ]


  const InvoiceIncomeReport = () => {
    const [loading, setLoading] = useState(false);
    const [incomeData, setIncomeData] = useState({});
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); 
  
    const fetchMonthlyIncome = async (month) => {
      setLoading(true);
      const startOfMonth = new Date(currentYear, month, 1);
      const endOfMonth = new Date(currentYear, month + 1, 0);
  
      const invoicesRef = collection(db, "invoices");
      const q = query(invoicesRef,
                      where("invoiceDate", ">=", startOfMonth),
                      where("invoiceDate", "<=", endOfMonth));
  
      try {
        const querySnapshot = await getDocs(q);
       
        let totalIncome = {
          "Embalment": 0, "White Cloth": 0, "Washing and Dressing": 0,
          "Tag": 0, "Ambulance": 0, "Ambulance Fee": 0, "Storage Registration": 0, "New Storage":0,
        };

         // Mapping variations to canonical names
         const descriptionMapping = {
          "embalment": "Embalment",
          "white cloth": "White Cloth",
          "washing and dressing": "Washing and Dressing",
          "tag": "Tag",
          "ambulance": "Ambulance",
          "ambulance fee": "Ambulance Fee",
          "storage registration": "Storage Registration",
          "new storage": "New Storage",
          // Add other variations as discovered
        };
        
  
          querySnapshot.forEach((doc) => {
            const invoice = doc.data();
            invoice.invoiceItems.forEach((item) => {
              // Normalize the item description by trimming, converting to lowercase, and mapping
              const normalizedDescription = item.description.trim().toLowerCase();
              const canonicalDescription = descriptionMapping[normalizedDescription] || normalizedDescription;
      
              if (totalIncome.hasOwnProperty(canonicalDescription)) {
                totalIncome[canonicalDescription] += (item.price * item.quantity);
              }
            });
          });
      
          setIncomeData(prevState => ({ ...prevState, [month]: totalIncome }));
      } catch (error) {
        console.error("Error fetching invoices:", error);
      } finally {
        setLoading(false);
      }
    };

    const calculateTotalIncomeForMonth = (monthData) => {
      // Assuming monthData is an object where keys are service names and values are amounts
      return Object.values(monthData).reduce((total, amount) => total + amount, 0);
    };


  return (
    <>  
    <BreadCrumb pages={pages} />

    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold text-center mb-6"> Invoice Income {currentYear}</h2>
      <div className="flex flex-wrap justify-center items-center gap-4">
        {loading && <MoonLoader />}
        {[...Array(currentMonth + 1).keys()].map((month) => (
          <div key={month} className="w-full max-w-xs">
            <button 
              onClick={() => fetchMonthlyIncome(month)} 
              className="w-full bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-2"
            >
              Get Amount Invoiced for {new Date(currentYear, month).toLocaleString('default', { month: 'long' })}
            </button>
            {incomeData[month] && (
              <div className="overflow-x-auto bg-white shadow rounded-lg p-4">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Service
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Amount
                      </th>
                                           
                    
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(incomeData[month]).map(([item, amount]) => (
                      <tr key={item}>
                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">{item}</p>
                        </td>
                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">{formatCurrency(amount)}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                      <tr>
                        <td className="px-5 py-2 border-t border-gray-200 bg-white text-sm font-semibold">
                          Total Invoice Value
                        </td>
                        <td className="px-5 py-2 border-t border-gray-200 bg-white text-sm">
                          {formatCurrency(calculateTotalIncomeForMonth(incomeData[month]))}
                        </td>
                      </tr>
                    </tfoot>
                 
                </table>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>

    </>
  );
};

export default InvoiceIncomeReport;
