// ActionButtons.js
import React from 'react';

const ActionButtons = ({ deceased, setStorageModalOpen, openModal, setRefundModalOpen, handleGenerateStatementClick }) => {
  return (

    <div className="inline-flex rounded-md shadow-sm" role="group">    
      <button
        type='button'
        disabled={deceased.status === "Checked Out"}
        className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border
         border-gray-900 rounded-s-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2
          focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white
           dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"     
            onClick={() => setStorageModalOpen(true)}   >
        Change Storage
      </button>

      <button
        onClick={openModal}
        disabled={deceased.status === "Checked Out"}
        className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
      >
        Fund Wallet
      </button>

      <button
        onClick={() => setRefundModalOpen(true)}
        disabled={(deceased.status === "Checked Out") || (deceased.wallet === 0)}
        className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-e-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
      >
        Refund Customer
      </button>

      <button
        onClick={handleGenerateStatementClick}
        // disabled={deceased.status === "Checked Out"}
        type='button'
        className="px-4 py-2 text-xs font-medium text-gray-900 bg-transparent border border-gray-900 rounded-e-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
      >
        CheckOut / Statement
      </button>                                                                                                   
    </div>
  );
};

export default ActionButtons;
