import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../firebase-config';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { groupByMonth, formatMonthLabel, generateColor } from '../Reports/reportingUtils'; 
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import BreadCrumb from '../BreadCrumb';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const pages = [
    { name: 'Home', to: '/', component: Link, current: false },
    { name: 'Owners Center', to: '/settings', component: Link, current: false },
    { name: 'Body Intake Report', to: '/settings/intake-report', component: Link, current: true },
    ]

const BodyIntakeReport = () => {
    const [storageAllocations, setStorageAllocations] = useState([]);
    const [monthlyReport, setMonthlyReport] = useState({});
    const [selectedMonth, setSelectedMonth] = useState('');
    const allowedRoles = ["Admin"];
  const isAllowed = useRoleRestriction(allowedRoles);
    const [loading, setLoading] = useState(true);
    const storageTypes = [...new Set(storageAllocations.map(item => item.storageType))];
    const labels = Object.keys(monthlyReport).map(key => formatMonthLabel(key));
    const datasets = storageTypes.map(storageType => {
        const storageLabels = {
            'vipStorage': 'VIP Storage',
            'generalStorage': 'General Storage',
            'premiumStorage': 'Premium Storage'
        };
        const label = storageLabels[storageType];
        return {
            label: label,
            data: labels.map(monthLabel => {
                const monthIndex = labels.indexOf(monthLabel);
                const monthData = monthlyReport[Object.keys(monthlyReport)[monthIndex]];
                return Array.isArray(monthData) ? monthData.filter(item => item.storageType === storageType).length : 0;
            }),
            backgroundColor: generateColor(label),
        };
    });

    useEffect(() => {
        const fetchStorageAllocations = async () => {
            setLoading(true);
            try {
                const allocationsSnapshot = await getDocs(collection(db, 'storageAllocations'));
                const allocationsData = allocationsSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    assignedAt: doc.data().assignedAt.toDate() // Convert Firestore Timestamp to JavaScript Date
                }));
                setStorageAllocations(allocationsData);
                const newMonthlyReport = groupByMonth(allocationsData);
                setMonthlyReport(newMonthlyReport);
                setSelectedMonth(Object.keys(newMonthlyReport)[0] || '');
                console.log("Allocation Data", allocationsData);
                console.log("Monthly Report", newMonthlyReport);
            } catch (error) {
                console.error("Error fetching storage allocations:", error);
            }
            setLoading(false);
        };
    
        fetchStorageAllocations();
    }, []);

   
      

    const renderChart = (monthlyReport) => {
        if (!monthlyReport || Object.keys(monthlyReport).length === 0) {
            return <p>No chart data available.</p>;
        }

        const storageLabels = {
            'vipStorage': 'VIP Storage',
            'generalStorage': 'General Storage',
            'premiumStorage': 'Premium Storage'
        };
    
        
        // Generate an array of storage types for the chart labels
        const chartStorageTypes = Object.values(storageLabels);
    
       
    
        const datasets = chartStorageTypes.map(storageDisplayName => {
            // Find the key for the current storageDisplayName
            const storageTypeKey = Object.keys(storageLabels).find(key => storageLabels[key] === storageDisplayName);
    
            return {
                label: storageDisplayName, // Use the display name for the label
                data: labels.map(monthLabel => {
                    const [monthName, year] = monthLabel.split(' ');
                    const monthIndex = new Date(Date.parse(monthName + " 1, " + year)).getMonth() + 1;
                    const monthKey = `${year}-${monthIndex.toString().padStart(2, '0')}`;
                    const monthData = monthlyReport[monthKey];
                    return Array.isArray(monthData) ? monthData.filter(item => item.storageType === storageTypeKey).length : 0;
                }),
                backgroundColor: generateColor(storageDisplayName), // Use the display name for the color
            };
        });
    
        const data = {
            labels, // Month labels
            datasets
        };
    
        const options = {
            scales: {
                x: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Month'
                    }
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Number of Deceased Occupants'
                    }
                }
            },
            plugins: {
                legend: {
                    display: true
                },
                title: {
                    display: true,
                    text: 'Monthly Body Intake by Storage Type'
                },
                tooltip: {
                    mode: 'index',
                    intersect: false
                }
            }
        };
    
        return <Bar data={{ labels, datasets }} options={options} />;
    };
    
 

     // Render Table
     const renderTable = (datasets, labels) => {
        return (
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Month
                        </th>
                        {datasets.map((dataset) => (
                            <th key={dataset.label} className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {dataset.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {labels.map((label, index) => (
                        <tr key={label}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{label}</td>
                            {datasets.map((dataset) => (
                                <td key={dataset.label} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {dataset.data[index]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };
    

    if (!isAllowed) {
        return < AccessDenied />
      }
    
      if (loading) {
        return <div>Loading...</div>;
    }

    
    return (
        <>

<BreadCrumb pages={pages} />

        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-semibold text-center mb-4 text-gray-800">Monthly Body Intake Report</h2>
            <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
            <div className="flex-grow" style={{ flexBasis: '35%' }}>
                    <h3 className="text-lg font-bold mb-3 text-center text-blue-700">Monthly Occupancy Table</h3>
                    {renderTable(datasets, labels)}
                </div>
                <div className="flex-grow" style={{ flexBasis: '65%' }}>
                    <h3 className="text-lg font-bold mb-3 text-center text-blue-700">Monthly Occupancy Chart</h3>
                    {Object.keys(monthlyReport).length > 0 ? renderChart(monthlyReport) : <p>Loading chart data...</p>}
                </div>
               
            </div>
        </div>
        </>
    );
};

export default BodyIntakeReport;
