import React  from 'react';
import AddTransaction from './AddTransaction';
import IncomeExpenses from './IncomeExpenses';
import { Link } from 'react-router-dom';
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import BreadCrumb from '../BreadCrumb';



const pages = [
  { name: 'Owners Center', to: '/settings', component: Link, current: false },  
  { name: 'Financial Reports', to: '/settings/finance', component: Link, current: false }, 
  { name: 'Book Keeping', to: '/settings/finance/posting', component: Link, current: true },     
]

const FinancePost = () => {

  const allowedRoles = ["Admin", "Finance"];
  const isAllowed = useRoleRestriction(allowedRoles);
  if (isAllowed === null) return <div>Loading...</div>;

  if (!isAllowed) {
    return < AccessDenied />
  }


  return (

    <>
    <BreadCrumb pages={pages} />
    <div className="container mx-auto px-4 py-2">
            <div className="flex flex-col items-center justify-center">
                <h1 className="text-3xl font-bold text-gray-800 mb-2">Finance Dashboard</h1>
                <div className="grid grid-cols-1 md:grid-cols-1 gap-6 mb-6 w-full max-w-4xl">
                    <IncomeExpenses />
                </div>
                <div className="w-full max-w-4xl">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-2">Add New Transaction</h2>
                    <AddTransaction />
                </div>
            </div>
        </div>


    </>
  );
};

export default FinancePost;
