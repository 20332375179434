import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { formatCurrency } from '../Deceased/helpers';
import { MoonLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';
import Papa from 'papaparse';

const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: false },  
    { name: 'Financial Reports', to: '/settings/finance', component: Link, current: false },   
    { name: 'Expenses', to: '/settings/finance/display-expenses', component: Link, current: true },     
  ]


const DisplayExpenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [search, setSearch] = useState('');
  const [sortField, setSortField] = useState('dateIncurred');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setIsLoading(true);
    const fetchExpenses = async () => {
      const transactionsRef = collection(db, 'transactions');
      const querySnapshot = await getDocs(transactionsRef);
      const loadedExpenses = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(transaction => transaction.transactionType === 'expense');
      setExpenses(loadedExpenses);
      setIsLoading(false);
    };
    fetchExpenses();
  }, []);

  const filteredExpenses = expenses.filter(expense => 
    expense.text.toLowerCase().includes(search.toLowerCase())
  );

  const sortedExpenses = filteredExpenses.sort((a, b) => {
    const fieldA = a[sortField];
    const fieldB = b[sortField];

    if (fieldA < fieldB) return sortOrder === 'asc' ? -1 : 1;
    if (fieldA > fieldB) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const totalExpensesAmount = sortedExpenses.reduce((sum, expense) => sum + Math.abs(expense.amount), 0);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedExpenses.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedExpenses.length / itemsPerPage);

  const handlePreviousPage = () => setCurrentPage(Math.max(1, currentPage - 1));
  const handleNextPage = () => setCurrentPage(Math.min(totalPages, currentPage + 1));

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };


  const exportToCSV = () => {
    
    const csvData = expenses.map(expense => ({
      Description: expense.text,
      Amount: expense.amount, 
      DateIncurred: expense.dateIncurred.toDate().toLocaleString(),
      DateOfPosting: expense.dateOfPosting.toDate().toLocaleString(),
      ModeOfPayment: expense.modeOfPayment,
      PostedBy: expense.user,
    }));
  
     const csv = Papa.unparse(csvData);
  
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `Expenses-${new Date().toISOString()}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
 


  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <MoonLoader color="#000000" />
      </div>
    );
  }


  return (
    <>
      <BreadCrumb pages={pages} />

      <div className="container mx-auto p-4">
    <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 mb-2">Expenses</h2>

          
    <div className="flex flex-wrap mx-2 mb-2">
        <input
          type="text"
          placeholder="Search by Description"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="border border-gray-300 p-2 rounded-lg mb-2 text-sm text-center"
        />
      </div>
      


      <table className="table-auto border-collapse border border-gray-200 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('text')}>Description</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('amount')}>Amount</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('dateIncurred')}>Date Incurred</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('dateOfPosting')}>Date of Posting</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('modeOfPayment')}>Mode of Payment</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('user')}>Posted By</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"> 
            <div className="text-right font-bold"> Total Expenses: {formatCurrency(totalExpensesAmount)}  </div>
            <div className="text-left font-bold"> <button onClick={exportToCSV} className="bg-green-700 hover:bg-amber-400 text-xs text-white font-bold py-1 px-2 rounded" >
               Export to CSV </button> </div>  </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentItems.map((expense) => (
            <tr key={expense.id}>
              <td className="px-4 py-1 text-xs text-gray-900">{expense.text}</td>
              <td className="px-4 py-1 text-xs text-gray-900">{formatCurrency(Math.abs(expense.amount))}</td>
              <td className="px-4 py-1 text-xs text-gray-900">{expense.dateIncurred.toDate().toLocaleString()}</td>
              <td className="px-4 py-1 text-xs text-gray-900">{expense.dateOfPosting.toDate().toLocaleString()}</td>
              <td className="px-4 py-1 text-xs text-gray-900">{expense.modeOfPayment}</td>
              <td className="px-4 py-1 text-xs text-gray-900">{expense.user}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
        <div className="flex-1 flex justify-between sm:hidden">
          <button onClick={handlePreviousPage} className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Previous
          </button>
          <button onClick={handleNextPage} className="ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Next
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to <span className="font-medium">{indexOfLastItem > sortedExpenses.length ? sortedExpenses.length : indexOfLastItem}</span> of <span className="font-medium">{sortedExpenses.length}</span> results
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <button onClick={handlePreviousPage} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span>Previous</span>
              </button>
              {/* Dynamic pagination buttons */}
              {[...Array(totalPages).keys()].map(number => (
                <button
                  key={number}
                  className={`bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === number + 1 ? 'bg-gray-100' : ''}`}
                  onClick={() => setCurrentPage(number + 1)}
                >
                  {number + 1}
                </button>
              ))}
              <button onClick={handleNextPage} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span>Next</span>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>

    </>
  );
};

export default DisplayExpenses;