import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../firebase-config';

const OrphanedStorageAllocations = () => {
  const [orphanedAllocations, setOrphanedAllocations] = useState([]);

  useEffect(() => {
    const fetchStorageAllocations = async () => {
      try {
        const allocationsSnapshot = await getDocs(collection(db, 'storageAllocations'));
        const allocations = allocationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        for (const allocation of allocations) {
          const deceasedRef = doc(db, 'deceased', allocation.deceasedId);
          const deceasedSnap = await getDoc(deceasedRef);

          if (!deceasedSnap.exists()) {
            setOrphanedAllocations(prev => [...prev, allocation]);
          }
        }
      } catch (error) {
        console.error("Error fetching storage allocations: ", error);
      }
    };

    fetchStorageAllocations();
  }, []);


  const handleDelete = async (allocationId) => {
    try {
      await deleteDoc(doc(db, 'storageAllocations', allocationId));
      setOrphanedAllocations(orphanedAllocations.filter(allocation => allocation.id !== allocationId));
    } catch (error) {
      console.error('Error deleting storage allocation:', error);
    }
  };


  return (
    <div>
      <h2>Orphaned Storage Allocations</h2>
      <div className='items-center ml-10'>
      <table>
        <thead>
          <tr>
            <th className='uppercase px-2 py-1.5'>Allocation ID</th>
            <th className='uppercase px-2 py-1.5'>Storage ID</th>
            <th className='uppercase px-2 py-1.5'>Storage Type</th>
          </tr>
        </thead>
        <tbody>
          {orphanedAllocations.map((allocation, index) => (
            <tr key={index}>
              <td className='px-2 py-1.5 text-sm'>{allocation.id}</td>
              <td className='px-2 py-1.5 text-sm'>{allocation.storageId}</td>
              <td className='px-2 py-1.5 text-sm'>{allocation.storageType}</td>
              <td>
                <button onClick={() => handleDelete(allocation.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default OrphanedStorageAllocations;
