// src/components/DeceasedReports.js
import React, { useEffect, useState } from 'react';
import { fetchDeceasedList } from './deceasedService';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { ChevronRightIcon, PlusCircleIcon,HomeIcon,HomeModernIcon,QueueListIcon,
  ShoppingCartIcon,PhoneIcon,BanknotesIcon } from '@heroicons/react/20/solid'
import {UsersIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import AccessDenied from '../AccessDenied';
import useRoleRestriction from "../../context/useRoleRestriction";

const pages = [
  { name: 'Home', to: '/', component: Link, current: false },
  { name: 'Reports', to: '/reports', component: Link, current: true },
  
 
]

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF00FF', '#800000', '#808000', '#00FF00', '#008080', '#000080'];

const monthNames = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const getMonthNameAbbreviation = (monthNumber) => {
  return monthNames[monthNumber - 1];
};



const DeceasedReports = () => {
  const [deceasedList, setDeceasedList] = useState([]);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchDeceasedData = async () => {
      setIsLoading(true);
      try {
        const deceasedData = await fetchDeceasedList();
        setDeceasedList(deceasedData);
        setError(null);
      } catch (err) {
        setError('Failed to fetch data');
        console.error(err);
      }
      setIsLoading(false);
    };
    fetchDeceasedData();
  }, []);
 
//Deceased Gender
  const demographicsData = deceasedList.reduce((acc, deceased) => {
    const gender = deceased.gender;

    if (gender === 'Male' || gender === 'Female' || gender === 'Other') {
      const index = acc.findIndex(item => item.name === gender);

      if (index === -1) {
        acc.push({ name: gender, count: 1 });
      } else {
        acc[index].count++;
      }
    }

    return acc;
  }, []);

  // Cause of Death
  const causeOfDeathData = deceasedList.reduce((acc, deceased) => {
    const cause = deceased.causeOfDeath;
  
    if (cause) {
      const index = acc.findIndex(item => item.name === cause);
  
      if (index === -1) {
        acc.push({ name: cause, count: 1 });
      } else {
        acc[index].count++;
      }
    }
  
    return acc;
  }, []);
               

      // Age range of Deceased
      const ageDistributionData = deceasedList.reduce((acc, deceased) => {
        // Convert age to a number
        const age = Number(deceased.age);
      
        if (!isNaN(age)) {
          const ageRange = Math.floor(age / 10) * 10;
          const rangeLabel = `${ageRange}-${ageRange + 9}`;
          const index = acc.findIndex(item => item.name === rangeLabel);
      
          if (index === -1) {
            acc.push({ name: rangeLabel, count: 1 });
          } else {
            acc[index].count++;
          }
        }
      
        return acc;
      }, []);      
      ageDistributionData.sort((a, b) => {
        const rangeA = parseInt(a.name.split('-')[0], 10); // Extracts the starting age of the range from the name
        const rangeB = parseInt(b.name.split('-')[0], 10); // Same for the second item
        return rangeA - rangeB; // Sorts in ascending order
      });

      // Age of Death By Gender
      const groupedAgeData = deceasedList.reduce((acc, deceased) => {
        const age = Number(deceased.age);
        const gender = deceased.gender;
        const ageRange = `${Math.floor(age / 10) * 10}-${Math.floor(age / 10) * 10 + 9}`;
      
        if (!acc[ageRange]) {
          acc[ageRange] = { Male: 0, Female: 0, Other: 0 };
        }
      
        if (['Male', 'Female', 'Other'].includes(gender)) {
          acc[ageRange][gender]++;
        }
      
        return acc;
      }, {});
      
      const chartData = Object.entries(groupedAgeData).map(([ageRange, genderCounts]) => ({
        ageRange,
        ...genderCounts
      }));
      
      // Sort the chartData array by the start of each age range
      chartData.sort((a, b) => {
        const rangeAStart = parseInt(a.ageRange.split('-')[0], 10); // Extracts the starting age of the range from the name
        const rangeBStart = parseInt(b.ageRange.split('-')[0], 10); // Same for the second item
        return rangeAStart - rangeBStart; // Sorts in ascending order
      });
               

    //gender with cause of death
    const groupedData = deceasedList.reduce((acc, deceased) => {
      const { causeOfDeath, gender } = deceased;
    
      if (!acc[causeOfDeath]) {
        acc[causeOfDeath] = { Male: 0, Female: 0, Other: 0 };
      }
    
      if (['Male', 'Female', 'Other'].includes(gender)) {
        acc[causeOfDeath][gender]++;
      }
    
      return acc;
    }, {});
    
    const CauseGenderData = Object.keys(groupedData).map(cause => ({
      cause,
      ...groupedData[cause]
    }));


    // Monthly Deceased
   const monthlyDeceasedData = deceasedList.reduce((acc, deceased) => {
  const deceasedDate = new Date(deceased.dateOfDeath);
  const deceasedYear = deceasedDate.getFullYear();
  const deceasedMonth = deceasedDate.getMonth() + 1;

  // Check if the date is valid
  if (!isNaN(deceasedDate.getTime())) {
    if (deceasedYear === currentYear && deceasedMonth <= currentMonth) {
      const index = acc.findIndex(item => item.name === deceasedMonth);

      if (index === -1) {
        acc.push({ name: deceasedMonth, count: 1 });
      } else {
        acc[index].count++;
      }
    }
  } else {
    console.log('Invalid date:', deceased.dateOfDeath);
  }

  return acc;
}, []);


const allowedRoles = ["Admin", "Finance", "User", "Auditor", "Mortician", "Front-Desk"];
const isAllowed = useRoleRestriction(allowedRoles);
if (isAllowed === null) return <div>Loading...</div>;

if (!isAllowed) {
  return < AccessDenied />
}


if (isLoading) {
  return <div>Loading...</div>;
}

if (error) {
  return <div>Error: {error}</div>;
}




  return (
    <>
         <nav className="flex gap-10 px-10 sm:px-16" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <Link to={page.to}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>


    <div className="container mx-auto px-4">

      <h2 className="text-xl font-semibold mb-2">Deceased Demographics</h2>

      <div className=" grid grid-cols-1 md:grid-cols-3 gap-2 ">
       
      
                    {/* First Div */}
                    <div className="  bg-white shadow-md rounded-md p-4 text-sm justify-center items-center">
                      <h3 className="text-xl font-semibold mb-2">Cause of Death</h3>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={causeOfDeathData}
                          cx={200}
                          cy={200}
                          labelLine={false}
                          label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="count"
                        >
                          {causeOfDeathData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </div>
                    
                    {/* Second Div */}
                    <div className="bg-white shadow-md rounded-md p-4" style={{ flex: '0 0 20%' }}>
                          <h3 className="text-xl font-semibold mb-4">Deceased Gender</h3>
                          <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                              <Pie
                                data={demographicsData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                outerRadius={90}
                                fill="#8884d8"
                                dataKey="count"
                              >
                                {demographicsData.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                            </PieChart>
                          </ResponsiveContainer>
                     </div>
         
                   {/* Third Age Distribution Chart */}
                   <div className="bg-white shadow-md rounded-md p-4">
                        <h3 className="text-xl font-semibold mb-14">Age Distribution</h3>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            data={ageDistributionData}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="count" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>  

            </div>
                      <div className=" grid grid-cols-1 md:grid-cols-2 gap-2 ">
       
                          <div className="bg-white shadow-md rounded-md p-4">
                                <h3 className="text-xl font-semibold mb-4">Demise by Age and Gender</h3>
                                <BarChart
                                width={500}
                                height={300}
                                data={chartData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="ageRange" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Male" fill="#8884d8" />
                                <Bar dataKey="Female" fill="#82ca9d" />
                                <Bar dataKey="Other" fill="#ffc658" />
                              </BarChart>
                          </div>


     


                          <div className="bg-white shadow-md rounded-md p-4">
                                <h3 className="text-xl font-semibold mb-4">Monthly Deceased</h3>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={monthlyDeceasedData}
                                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                  >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" tickFormatter={(tickItem) => getMonthNameAbbreviation(tickItem)} />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="count" fill="#8884d8" />
                                  </BarChart>
                                </div>
                          </div>
                   


                       



      

      
      <div className="bg-white shadow-md flex w-full rounded-md p-4">
            <h3 className="text-xl font-semibold mb-4">Cause of Demise by Gender</h3>
            <BarChart
              width={600}
              height={300}
              data={CauseGenderData}
              margin={{
                top: 20, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="cause" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Male" stackId="a" fill="#8884d8" />
              <Bar dataKey="Female" stackId="a" fill="#82ca9d" />
              <Bar dataKey="Other" stackId="a" fill="#ffc658" />
            </BarChart>
      </div>

      


    </div>

    </>
  );
};


export default DeceasedReports;
