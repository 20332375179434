import { Fragment } from 'react'

import {  DocumentIcon, BanknotesIcon, InboxIcon, HandThumbUpIcon} from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom'
import useRoleRestriction from "../context/useRoleRestriction";
import AccessDenied from './AccessDenied';
import BreadCrumb from './BreadCrumb';


const pages = [
  { name: 'Finance', to: '/finance', component: Link, current: true },
 
]


const cardsData = [
  
 
 
  { id: 1, title: 'Agent Referral Program', content: 'Register Agents and Manage Commission for Referrals.', link: '/finance/agent', icon: <HandThumbUpIcon className="h-6 w-6 text-purple-500 mb-2 mx-auto" /> },
   { id: 2, title: 'Generate Invoice', content: 'Generate an Invoice for ad-hoc or value-added services.', link: '/finance/invoice', icon: <BanknotesIcon className="h-6 w-6 text-pink-500 mb-2 mx-auto" /> },
  { id: 3, title: 'Receive Payment', content: 'Issue a receipt as confirmation of payment.', link: '/finance/receipt', icon: <InboxIcon className="h-6 w-6 text-teal-500 mb-2 mx-auto" /> },
   { id: 4, title: 'Track Invoices', content: 'Track Paid and Partially Paid Invoices.', link: '/finance/trackInvoice', icon: <InboxIcon className="h-6 w-6 text-teal-500 mb-2 mx-auto" /> },
  
];




export default function Finance() {
  


  const allowedRoles = ["Admin", "Finance", "Front-Desk"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return < AccessDenied />
  }



  return (
    <>
        
    
      
      <main>

     <BreadCrumb pages = {pages} />

        <div className="container mx-auto py-5">
        <div className="flex flex-wrap">
  {cardsData.map((card) => (
    <div key={card.id} className="w-1/4 p-4">
      <Link to={card.link}>
        <div className="bg-white shadow-md rounded p-4">
          {card.icon}
          <h3 className="text-xl font-bold mb-2">{card.title}</h3>
          <p>{card.content}</p>
        </div>
      </Link>
    </div>
  ))}
</div>








    </div>

 

      
      </main>
    </>
  )
}
