import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase-config'; 
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { capitalizeFirstLetter } from '../Deceased/helpers';
import useRoleRestriction from '../../context/useRoleRestriction';
import { Link } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';
import AccessDenied from '../AccessDenied';
import { MoonLoader } from 'react-spinners';



const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: false },
 { name: 'Monthly Body Details', to: '/settings/monthlybody', component: Link, current: true },
 ]


const MonthlyBodyDetails = () => {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [deceasedData, setDeceasedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalBodies, setTotalBodies] = useState(0);
    const [totalMales, setTotalMales] = useState(0);
    const [totalFemales, setTotalFemales] = useState(0);


    const handleMonthChange = (event) => {
      setSelectedMonth(event.target.value);
    };

   
    const fetchData = useCallback(async () => {
      setLoading(true);
      const startOfMonth = new Date(selectedYear, selectedMonth, 1);
      const endOfMonth = new Date(selectedYear, selectedMonth + 1, 0, 23, 59, 59);
  
      const startTimestamp = Timestamp.fromDate(startOfMonth);
      const endTimestamp = Timestamp.fromDate(endOfMonth);
  
      const deceasedRef = collection(db, 'deceased');
      const q = query(deceasedRef, 
                      where('dateDepositedTimestamp', '>=', startTimestamp),
                      where('dateDepositedTimestamp', '<=', endTimestamp));
  
      try {
        const querySnapshot = await getDocs(q);
        let fetchedData = [];
        let males = 0;
        let females = 0;
  
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          fetchedData.push(data);
          if(data.gender === 'Male') males++;
          if(data.gender === 'Female') females++;
        });
  
        setDeceasedData(fetchedData);
        setTotalBodies(fetchedData.length);
        setTotalMales(males);
        setTotalFemales(females);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    }, [selectedMonth, selectedYear]);
    
   
    

    useEffect(() => {
      fetchData();
    }, [fetchData]);

   
    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A'; 
      
        let date;
        try {
          if (typeof timestamp === 'string' || timestamp instanceof String) {           
            date = new Date(Date.parse(timestamp.replace(' at ', ' ')));
          } else if (timestamp.seconds) {          
            date = new Date(timestamp.seconds * 1000);
          } else {           
            date = timestamp;
          }
        } catch (error) {
          console.error('Error parsing date:', timestamp, error);
          return 'Invalid Date';
        }
      
        if (isNaN(date)) return 'Invalid Date';       
        return date.toLocaleDateString(); 
      };
      
      
      const calculateWeekOfMonth = (date) => {
        const startOfMonth = moment(date).startOf('month');
        const endOfFirstWeek = startOfMonth.clone().endOf('week');
        const dayOfWeek = date.day();     
             if (date.isSameOrBefore(endOfFirstWeek)) {
          return 1;
        }
        const adjustedDate = date.clone().subtract(dayOfWeek, 'days');
        return adjustedDate.diff(startOfMonth, 'weeks') + 2; 
      };
      

      const getWeekOfMonth = (date) => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const firstDayOfWeek = firstDayOfMonth.getDay();
        let adjustedDateDay = date.getDate() + firstDayOfWeek;
        const weekNumber = Math.ceil(adjustedDateDay / 7);
        return weekNumber;
      };

    
      const getDataForWeek = (week) => {
        return deceasedData.filter((deceased) => {
          let deceasedDate = moment(deceased.dateDeposited, "YYYY-MM-DD");
          const weekNumber = calculateWeekOfMonth(deceasedDate);
          return weekNumber === week;
        });
      };
      
    
      
      const getWeekDataAndCounts = (week) => {
        const filteredData = deceasedData.filter((deceased) => {
            const deceasedDate = new Date(deceased.dateDepositedTimestamp.seconds * 1000);
            return getWeekOfMonth(deceasedDate) === week;
        });

        const totals = { total: filteredData.length, males: 0, females: 0 };
        filteredData.forEach(deceased => {
            if (deceased.gender === 'Male') totals.males++;
            if (deceased.gender === 'Female') totals.females++;
        });

        return {
            dataForWeek: filteredData,
            ...totals
        };
    };

    

      const allowedRoles = ["Admin", "Owner"];
      const isAllowed = useRoleRestriction(allowedRoles);

      if (!isAllowed) {
        return < AccessDenied />
      }
      

      return (
        <>
        
        <BreadCrumb pages={pages} />
        <div className="container mx-auto px-4">  
       
                      <div className="flex justify-end items-center mb-2">
                      <select 
                            className="border border-gray-300 text-sm rounded-md mr-2 p-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
                            value={selectedMonth} 
                            onChange={handleMonthChange}
                            >
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i} value={i}>
                              {new Date(0, i).toLocaleString('default', { month: 'long' })}
                            </option>
                          ))}
                        </select>
                      <select 
                            className="border border-gray-300 text-sm rounded-md mr-2 p-2 shadow-sm"
                            value={selectedYear} 
                            onChange={(e) => setSelectedYear(Number(e.target.value))}
                          >
                            {Array.from({ length: 5 }, (_, i) => {
                              const year = new Date().getFullYear() - i;
                              return (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                                    <button 
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 text-sm px-4 rounded shadow"
                            onClick={fetchData}
                            >
                            Search
                            </button>
                      </div>
      

           {loading ? (
              <div className="text-center py-4 flex justify-center items-center">
                <MoonLoader color="#00008B" size={50} />
              </div>
            ) : (
              <>
              <div className="mb-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="p-4 shadow rounded-lg bg-white">
                    <h2 className="font-bold text-xl">Total Bodies</h2>
                    <p className="text-lg">{totalBodies}</p>
                  </div>
                  <div className="p-4 shadow rounded-lg bg-white">
                    <h2 className="font-bold text-xl">Total Males</h2>
                    <p className="text-lg">{totalMales}</p>
                  </div>
                  <div className="p-4 shadow rounded-lg bg-white">
                    <h2 className="font-bold text-xl">Total Females</h2>
                    <p className="text-lg">{totalFemales}</p>
                  </div>
                </div>
              </div>



            <div className="flex flex-wrap -mx-2">
              {[1, 2, 3, 4,5].map((week) => {
                const { total, males, females } = getWeekDataAndCounts(week);

                return (
              <div key={week} className="border border-gray-300 rounded-lg shadow-sm overflow-hidden p2">

                  <div className="border border-gray-300 rounded shadow p-4">
                  <h3 className="font-semibold text-lg">Week {week}</h3>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg p-2">
                          <div className="px-4 py-2 sm:p-2">
                            <dt className="text-sm font-medium text-gray-500 truncate">Total Bodies</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900">{total}</dd>
                          </div>
                        </div>
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg p-2">
                          <div className="px-4 py-2 sm:p-2">
                            <dt className="text-sm font-medium text-gray-500 truncate">Total Males</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900">{males}</dd>
                          </div>
                        </div>
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg p-2">
                          <div className="px-4 py-2 sm:p-2">
                            <dt className="text-sm font-medium text-gray-500 truncate">Total Females</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900">{females}</dd>
                          </div>
                        </div>
                  </div>


                   
                  <table className="min-w-full leading-normal">
                      <thead>
                        <tr>
                        <th className="px-4 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Name</th>
                        <th className="px-4 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Arrival Date</th>
                        <th className="px-4 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Storage Number</th>
                        <th className="px-4 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Exit Date</th>
                        </tr>
                      </thead>
                      <tbody className='text-sm'>
                        {getDataForWeek(week).map((deceased, index) => (
                          <tr key={index}>
                             <td className="px-4 py-2 border-b border-gray-200 bg-white text-xs">
                                {capitalizeFirstLetter(deceased.firstName) + ' ' + capitalizeFirstLetter (deceased.lastName)}</td>
                      <td  className="px-4 py-1 border-b border-gray-200 bg-white text-xs">{formatDate(deceased.dateDeposited)}</td>
                      <td className="px-4 py-1 border-b border-gray-200 bg-white text-xs">{deceased.allocations[0]?.storageNumber}</td>
                       <td className="px-4 py-1 border-b border-gray-200 bg-white text-xs">{deceased.exitDate ? formatDate(deceased.exitDate)  : 'N/A'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                  </div>
                );
              })}
            </div>

                </>
          )}

          
            </div>
            

        

        </>
      
      
      )

            }
export default MonthlyBodyDetails;