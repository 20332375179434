import React, { useEffect, useState,useMemo } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config'; 
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Papa from 'papaparse';
import BreadCrumb from '../BreadCrumb';
import { formatCurrency, capitalizeFirstLetter } from '../Deceased/helpers';


const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: false },  
    { name: 'Financial Reports', to: '/settings/finance', component: Link, current: false },
    { name: 'Storage Receivable', to: '/settings/finance/storage-receivable', component: Link, current: true },     
  ]

const DeceasedStorageOverview = () => {
    const [deceasedInStorage, setDeceasedInStorage] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortField, setSortField] = useState('dateDeposited');
    const [sortOrder, setSortOrder] = useState('asc');
    const allowedRoles = ["Admin"];
    const isAllowed = useRoleRestriction(allowedRoles);

    useEffect(() => {
        fetchDeceasedInStorage();
    }, []);

    const fetchDeceasedInStorage = async () => {
        setIsLoading(true); // Start loading
        try {
            const q = query(collection(db, 'deceased'), where('status', '==', 'in storage'));
            const querySnapshot = await getDocs(q);
            const deceasedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setDeceasedInStorage(deceasedData);
        } catch (error) {
            toast.error("Failed to fetch deceased data");
        }
        setIsLoading(false); // End loading
    };


    const sortedDeceasedInStorage = useMemo(() => {
        return [...deceasedInStorage].sort((a, b) => {
            let valA = sortField === 'dateDeposited' ? new Date(a[sortField]) : a[sortField];
            let valB = sortField === 'dateDeposited' ? new Date(b[sortField]) : b[sortField];
    
            if (typeof valA === 'string') {
                valA = valA.toLowerCase();
                valB = valB.toLowerCase();
            }
    
            return (valA < valB) ? sortOrder === 'asc' ? -1 : 1 : (valA > valB) ? sortOrder === 'asc' ? 1 : -1 : 0;
        });
    }, [deceasedInStorage, sortField, sortOrder]);

    const calculateTotalDue = (deceased) => {
        const totalStorageFees = deceased.allocations.reduce((total, allocation) => {
            const daysInStorage = Math.max((new Date() - new Date(allocation.dateAssigned.toDate())) / (1000 * 60 * 60 * 24), 0);
            return total + (allocation.storageFees * daysInStorage);
        }, 0);
        const totalDue = Math.max(totalStorageFees - deceased.wallet, 0); // Prevent negative totals
        return totalDue;
    };
    
    const calculateTotalDueAndExcess = (deceased) => {
      const totalStorageFees = deceased.allocations.reduce((total, allocation) => {
          const daysInStorage = Math.round((new Date() - new Date(allocation.dateAssigned.toDate())) / (1000 * 60 * 60 * 24));
          return total + (allocation.storageFees * daysInStorage);
      }, 0);
  
      // Make sure to round the total due to avoid decimals if all charges are whole numbers
      const totalDue = Math.max(Math.round(totalStorageFees) - deceased.wallet, 0);
      const excessBalance = totalDue === 0 ? Math.abs(Math.round(totalStorageFees) - deceased.wallet) : 0;
      
      return { totalDue, excessBalance };
  };
  
    
    function formatDate(date) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        let formattedDate = new Date(date).toLocaleDateString('en-GB', options);
      
        const dayOfMonth = new Date(date).getDate();
        let ordinalIndicator;
      
        switch (dayOfMonth) {
          case 1:
          case 21:
          case 31:
            ordinalIndicator = 'st';
            break;
          case 2:
          case 22:
            ordinalIndicator = 'nd';
            break;
          case 3:
          case 23:
            ordinalIndicator = 'rd';
            break;
          default:
            ordinalIndicator = 'th';
        }
      
        return formattedDate.replace(new RegExp(' ' + dayOfMonth), `${dayOfMonth}${ordinalIndicator}`);
      }



      const downloadCSV = () => {
        const csvData = deceasedInStorage.map(deceased => ({
            Name: deceased.firstName + ' ' + deceased.lastName,
            BodyNumber: deceased.bodyNumber,
            ArrivalDate: deceased.dateDeposited, 
        }));
        const csv = Papa.unparse(csvData);
    
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `DeceasedStorage-${new Date().toLocaleDateString()}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
   

    const handleSortChange = (field) => {
        if (sortField === field) {
           
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
         
            setSortField(field);
            setSortOrder('asc');
        }
    };
    
    
    const grandTotalDue = useMemo(() => deceasedInStorage.reduce((total, deceased) => total + calculateTotalDue(deceased), 0), [deceasedInStorage]);

    const totalPages = useMemo(() => Math.ceil(sortedDeceasedInStorage.length / itemsPerPage), [sortedDeceasedInStorage, itemsPerPage]);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = useMemo(() => sortedDeceasedInStorage.slice(indexOfFirstItem, indexOfLastItem), [indexOfFirstItem, indexOfLastItem, sortedDeceasedInStorage]);

    function handlePreviousPage() {
        setCurrentPage(currentPage => Math.max(1, currentPage - 1));
      }
      
      function handleNextPage() {
        setCurrentPage(currentPage => Math.min(totalPages, currentPage + 1));
      }



     
      if (isAllowed === null) return <div>Loading...</div>;    
      if (!isAllowed) {
        return < AccessDenied />
      }
             

    return (
        <>
        <BreadCrumb pages={pages} />
        <div className="container mx-auto p-4">

            <ToastContainer />
          
            {isLoading ? (
            <div className="flex justify-center items-center">
                <MoonLoader color="#000000" />
            </div>
        ) : (
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg my-6">
                  <h4 className="text-xl md:text-xl lg:text-2xl font-bold text-gray-900 mb-2">Storage Receivable</h4>
              
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg my-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                    Grand Total Due: {formatCurrency(grandTotalDue)}
                </h3>
            <table className="min-w-full divide-y divide-gray-200">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" onClick={() => handleSortChange('firstName')}>Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Body Number</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" onClick={() => handleSortChange('dateDeposited')}>Arrival Date</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Current Storage</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Due </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Credit Balance</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> <button
        className="bg-green-500 hover:bg-green-700 text-white text-sm font-bold py-2 px-4 rounded mb-4"
        onClick={downloadCSV}> Export to CSV </button></th>
                        </tr>
                    </thead>
                    <tbody>
                    {currentItems.map((deceased, index) => {
                                const { totalDue, excessBalance } = calculateTotalDueAndExcess(deceased);
                            return (
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-1.5 whitespace-nowrap text-sm text-gray-900">{capitalizeFirstLetter(deceased.firstName)} {capitalizeFirstLetter(deceased.lastName)}</td>
                                <td className="px-6 py-1.5 whitespace-nowrap text-sm text-gray-900">{deceased.bodyNumber}</td>
                                <td className="px-6 py-1.5 whitespace-nowrap text-sm text-gray-900">{formatDate(deceased.dateDeposited)}</td>
                                <td className="px-6 py-1.5 whitespace-nowrap text-sm text-gray-900">
                                {deceased.allocations.length > 0 ? deceased.allocations[deceased.allocations.length - 1].storageNumber : "N/A"}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatCurrency(totalDue)}</td>


                              {totalDue === 0 && (
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                   {formatCurrency(excessBalance)}
                                </td>
                              )}
                              {totalDue !== 0 && <td className="px-6 py-4 whitespace-nowrap ml-4 text-sm text-gray-500"> NIL</td>}
                            </tr>
                          );
                        })}
                      </tbody>
                </table>

                <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to <span className="font-medium">{Math.min(indexOfLastItem, sortedDeceasedInStorage.length)}</span> of{' '}
            <span className="font-medium">{sortedDeceasedInStorage.length}</span> results
          </p>
        </div>
        {/* Dynamic Pagination Buttons */}
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50"
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </button>
            
            {/* Dynamically generate page buttons based on totalPages */}
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                aria-current={currentPage === index + 1 ? "page" : undefined}
                onClick={() => setCurrentPage(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${currentPage === index + 1 ? "z-10 bg-indigo-600 text-white" : "text-gray-700 bg-white hover:bg-gray-50"}`}
              >
                {index + 1}
              </button>
            ))}

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50"
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </button>
          </nav>
        </div>
      </div>
                </div>

            </div>
            </div>
            )}
             </div>
        </>
    );
    
    
    
};

export default DeceasedStorageOverview;
