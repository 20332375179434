import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, onSnapshot, where } from 'firebase/firestore';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { db } from '../../firebase-config';
import { Link } from 'react-router-dom';



const DeceasedList = () => {
  const [deceasedList, setDeceasedList] = useState([]);  
  const [searchTerm, setSearchTerm] = useState("");
  


  //  filtered list based on the search term
  const filteredDeceasedList = deceasedList.filter((deceased) => {
    const firstName = deceased.firstName ? deceased.firstName.toLowerCase() : '';
    const lastName = deceased.lastName ? deceased.lastName.toLowerCase() : '';
    
    return firstName.includes(searchTerm.toLowerCase()) || lastName.includes(searchTerm.toLowerCase());
  });
  
  

const calculateDaysInStorage = (dateDeposited) => {
  const dateDepositedObj = new Date(dateDeposited);
  const currentDate = new Date();
  
  // Remove the time part from both dates to only compare the date
  dateDepositedObj.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const diffInMilliseconds = currentDate - dateDepositedObj;
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const daysInStorage = Math.floor(diffInMilliseconds / millisecondsInADay);
  
  return daysInStorage;
};




useEffect(() => {
  const unsubscribe = onSnapshot(collection(db, 'deceased'), async (querySnapshot) => {
    const fetchedDeceased = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
          }));

         
    const fetchNextOfKin = async () => {
      const nextOfKinPromises = fetchedDeceased.map(async (deceased) => {
          const nextOfKinQuery = query(
          collection(db, 'nextOfKin'),
          where('deceasedId', '==', deceased.nextOfKinId)
        );
        const nextOfKinSnapshot = await getDocs(nextOfKinQuery);
      
        


        if (nextOfKinSnapshot.docs && nextOfKinSnapshot.docs.length > 0) {
          return nextOfKinSnapshot.docs[0].data();
        } else {
          console.warn('No matching next of kin found for deceased ID:', deceased.id);
          return null;
        }
      });
      
      const nextOfKinList = await Promise.all(nextOfKinPromises);
      setDeceasedList(
        fetchedDeceased.map((deceased, index) => ({ ...deceased, nextOfKin: nextOfKinList[index] }))
      );
    };

    fetchNextOfKin();
  });

  return () => {
    unsubscribe();
  };
}, []);



  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
    }).format(amount);
  };


 


  const exportToPDF = () => {
    const doc = new jsPDF("portrait", "px", "a4");
  
    doc.setFontSize(18);
    doc.text("Deceased List", 15, 30);
  
    doc.autoTable({
      head: [
        ["Name", "Status", "Balance"], // Add other table columns here
      ],
      body: deceasedList.map((deceased) => [
        deceased.firstName,
        deceased.lastName,
        deceased.status,
        deceased.balance,
        deceased.dateDeposited

      ])
    
  })
}

  return (


    <div className='flex flex-col justify-center items-center p-8'>
    <h3 className="text-2xl font-bold mb-4">Deceased List</h3>
    <input
      type="text"
      placeholder="Search by name"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="border border-gray-300 p-2 rounded-lg mb-4 w-64"
    />

    <table className="table-auto border-collapse border border-gray-200">
      {/* Table header and structure */}
      <thead className="bg-gray-200">
        <tr>
          <th className="py-3 px-6 bg-gray-100 font-bold uppercase text-sm">First Name</th>
          <th className="py-3 px-6 bg-gray-100 font-bold uppercase text-sm">Last Name</th>
          <th className="py-3 px-6 bg-gray-100 font-bold uppercase text-sm">Days in Storage</th>
          <th className="py-3 px-6 bg-gray-100 font-bold uppercase text-sm">Status</th>
          <th className="py-3 px-6 bg-gray-100 font-bold uppercase text-sm">Balance</th>
          <th className="py-3 px-6 bg-gray-100 font-bold uppercase text-sm">Actions</th>
          {/* Other table columns */}
        </tr>
      </thead>
      <tbody>
        {filteredDeceasedList.map((deceased, index) => (
          <tr key={deceased.id} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
             <td className="py-3 px-6">{deceased.firstName}</td>
             <td className="py-3 px-6">{deceased.lastName}</td>
             <td className="py-3 px-6">{calculateDaysInStorage(deceased.dateDeposited)}</td>
             <td className="py-3 px-6">{deceased.status}</td>
             <td className="py-3 px-6">{formatCurrency(deceased.balance)}</td>
             <td className="py-3 px-6">
             <Link
                to={{
                  pathname: `/deceased/${deceased.id}`,
                  state: { deceased: deceased, nextOfKin: deceased.nextOfKin },
                }}
              >
                Check-in
              </Link>
              </td>
          </tr>
        ))}
      </tbody>
    </table>

    <button onClick={exportToPDF} className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4 hover:bg-blue-600">
      Export to PDF
    </button>
        

  </div>
);
};

export default DeceasedList;