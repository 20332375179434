// EditDeceased.js

import React, { useState, useEffect } from 'react';
import { fetchDeceasedById, updateDeceased, fetchNextOfKinList} from './deceasedService'; 

function EditDeceased({ id, onClose, refreshDeceasedList }) {
  const [deceased, setDeceased] = useState(null);
  const [nextOfKin, setNextOfKin] = useState([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfDeath: '',
    causeOfDeath: '',
    morticianComments: '',
    nextOfKinId: ''
  });

  useEffect(() => {
    async function fetchData() {
      const deceasedData = await fetchDeceasedById(id); 
      const nextOfKinData = await fetchNextOfKinList(); // Fetch Next of Kin data
      setDeceased(deceasedData);
      setNextOfKin(nextOfKinData);
      setFormData({
        firstName: deceasedData.firstName,
        lastName: deceasedData.lastName,
        gender: deceasedData.gender,
        dateOfDeath: deceasedData.dateOfDeath || '',
        causeOfDeath: deceasedData.causeOfDeath || '',
        morticianComments: deceasedData.morticianComments || '',
        nextOfKinId: deceasedData.nextOfKinId || ''
      });
    }
    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    await updateDeceased(id, formData);
    refreshDeceasedList(); // This will refresh the DeceasedTable
    onClose();
  };

  if (!deceased) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-3/4 max-w-4xl">
        <h2 className="text-2xl font-bold mb-6">Edit Deceased Details</h2>
        <form>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <label className="block">
              <span className="text-gray-700">First Name:</span>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="mt-1 p-2 w-full border rounded-md"
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Last Name:</span>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="mt-1 p-2 w-full border rounded-md"
              />
            </label>
          </div>
  
          <div className="grid grid-cols-3 gap-4 mb-4">
            <label className="block col-span-1">
              <span className="text-gray-700">Gender:</span>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                className="mt-1 p-2 w-full border rounded-md"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </label>
            <label className="block col-span-2">
              <span className="text-gray-700">Date of Death:</span>
              <input
                type="date"
                name="dateOfDeath"
                value={formData.dateOfDeath}
                onChange={handleInputChange}
                className="mt-1 p-2 w-full border rounded-md"
              />
            </label>
          </div>
  
          <label className="block mb-4">
            <span className="text-gray-700">Cause of Death:</span>
            <input
              type="text"
              name="causeOfDeath"
              value={formData.causeOfDeath}
              onChange={handleInputChange}
              className="mt-1 p-2 w-full border rounded-md"
            />
          </label>
  
          <label className="block mb-4">
            <span className="text-gray-700">Mortician Comments:</span>
            <textarea
              name="morticianComments"
              value={formData.morticianComments}
              onChange={handleInputChange}
              className="mt-1 p-2 w-full border rounded-md"
            />
          </label>
  
          <label className="block mb-6">
            <span className="text-gray-700">Next of Kin:</span>
            <select
              name="nextOfKinId"
              value={formData.nextOfKinId}
              onChange={handleInputChange}
              className="mt-1 p-2 w-full border rounded-md"
            >
              {nextOfKin.map((kin) => (
                <option key={kin.id} value={kin.id}>{kin.nextOfKinName}</option>
              ))}
            </select>
          </label>
  
          <div className="flex justify-end gap-4">
            <button 
              type="button" 
              onClick={onClose}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded transition duration-300 ease-in-out"
            >
              Cancel
            </button>
            <button 
              type="button" 
              onClick={handleSave}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  
}

export default EditDeceased;
