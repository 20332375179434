import React, { Fragment } from 'react'
import {   ShoppingCartIcon, BanknotesIcon, DocumentIcon,InboxIcon} from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom'
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';
import BreadCrumb from '../BreadCrumb';


const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: false },  
    { name: 'Financial Reports', to: '/settings/finance', component: Link, current: true },     
  ]

  const cardsData = [
    { id: 1, title: 'Total Monthly Income', content: 'Monthly Revenue from Services and Storage.', link: '/settings/finance/monthly-revenue', icon: <BanknotesIcon className="h-6 w-6 text-red-500 mb-2 mx-auto" /> },
    { id: 2, title: 'Detailed Monthly Income', content: 'Monthly Revenue from Services and Storage.', link: '/settings/finance/detailed-income', icon: <BanknotesIcon className="h-6 w-6 text-red-500 mb-2 mx-auto" /> },
    { id: 3, title: 'Storage Receivable', content: 'Amounts Due from Bodies in Storage', link: '/settings/finance/storage-receivable', icon: <ShoppingCartIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
    { id: 4, title: 'Income from Services', content: 'Monthly Income from Embalment, Washing and Dressing etc.', link: '/settings/finance/invoice-income', icon: <ShoppingCartIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
    { id: 5, title: 'Income from Storage', content: 'Total Monthly Income from all Storages that were occupied ', link: '/settings/finance/storage-income', icon: <ShoppingCartIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
    { id: 6, title: 'Commission Payouts', content: 'Manage Third Party Services that are offered to customers.', link: '/settings/finance/agentreport', icon: <ShoppingCartIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
    { id: 7, title: 'Expenses Posted', content: 'Display All Expenses that have been posted into the system.', link: '/settings/finance/display-expenses', icon: <ShoppingCartIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
    { id: 8, title: 'Book Keeping', content: 'Post Operational Income and Expenses.', link: '/settings/finance/posting', icon: <BanknotesIcon className="h-6 w-6 text-red-500 mb-2 mx-auto" /> },
    { id: 9, title: 'Transaction History', content: 'View, Search and Export Recent Transactions logged in the system.', link: '/settings/finance/transactions', icon: <DocumentIcon className="h-6 w-6 text-indigo-500 mb-2 mx-auto" /> },
    { id: 10, title: 'Income Report', content: 'Monthly Income and Expenditure Report.', link: '/settings/finance/incomereport', icon: <InboxIcon className="h-6 w-6 text-teal-500 mb-2 mx-auto" /> },
  ];

   

const FinanceContainer = () => {



    const allowedRoles = ["Admin", "Owner"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return < AccessDenied />
  }


  return (
    <>
 <main>
        <BreadCrumb pages={pages} />

        <div className="container mx-auto py-5">
        <div className="flex flex-wrap">
  {cardsData.map((card) => (
    <div key={card.id} className="w-1/4 p-4">
      <Link to={card.link}>
        <div className="bg-white shadow-md rounded p-4">
          {card.icon}
          <h3 className="text-xl font-bold mb-2">{card.title}</h3>
          <p>{card.content}</p>
        </div>
      </Link>
    </div>
  ))}
</div>
    </div>

      
      </main>

    

    </>
  )
}

export default FinanceContainer