import React, { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';

const AgentTable = ({ data }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const columns = useMemo(() => [
        {
            name: 'First Name',
            selector: row => row.firstName,
            sortable: true,
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            sortable: true,
        },
        {
            name: 'Agent Type',
            selector: row => row.agentType,
            sortable: true,
        },
        {
            name: 'Deceased Referrals',
            selector: row => row.deceasedReferrals,
            sortable: true,
        },
        {
            name: 'Total Commission',
            selector: row => row.totalCommission,
            sortable: true,
            format: row => `₦${(row.totalCommission || 0).toFixed(2)}`
        },
        // Other columns...
    ], []);
    
    

    const filteredData = useMemo(() => {
        return data.filter(item => {
            const fullName = `${item.firstName || ''} ${item.lastName || ''}`.trim();
            return fullName.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [data, searchTerm]);
    

    const customStyles = {
        headCells: {
            style: {
                fontSize: '0.875rem', // tailwind text-sm
                fontWeight: '600', // tailwind font-semibold
                color: '#4A5568', // tailwind gray-700
                paddingLeft: '12px', // tailwind px-3
                paddingRight: '12px', // tailwind px-3
            },
        },
        cells: {
            style: {
                fontSize: '0.875rem', // tailwind text-sm
                color: '#2D3748', // tailwind gray-800
                paddingLeft: '12px', // tailwind px-3
                paddingRight: '12px', // tailwind px-3
            },
        },
        rows: {
            style: {
                minHeight: '48px', // tailwind h-12
            },
            highlightOnHoverStyle: {
                backgroundColor: '#F7FAFC', // tailwind gray-100
                borderBottomColor: '#CBD5E0', // tailwind gray-300
                outline: '1px solid transparent', // tailwind border-transparent
            },
        },
        pagination: {
            style: {
                fontSize: '0.875rem', // tailwind text-sm
                color: '#2D3748', // tailwind gray-800
                minHeight: '48px', // tailwind h-12
                borderTopWidth: '1px', // tailwind border-t
                borderTopStyle: 'solid',
                borderTopColor: '#E2E8F0', // tailwind gray-200
            },
            pageButtonsStyle: {
                fill: '#2D3748', // tailwind gray-800
                backgroundColor: 'transparent', // tailwind bg-transparent
                hover: {
                    backgroundColor: '#EDF2F7', // tailwind gray-100
                },
                disabledStyle: {
                    cursor: 'not-allowed', // tailwind cursor-not-allowed
                },
            },
        },
    };
    

    return (
        <div>
            <input
                type="text"
                placeholder="Search by agent name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mb-2 mt-2 p-2 border border-gray-300 rounded"
            />
            <DataTable
                title="Agent Data"
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                customStyles={customStyles}
                // additional props and callbacks
            />
        </div>
    );
};

export default AgentTable;
