import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { formatCurrency,  calculateAccumulatedStorageFees} from './helpers';
import StorageTable from '../Statement/StorageTable';
import WalletTransactions from '../Statement/WalletTransactions';
import  CostSummary from '../Statement/CostSummary';
import InvoicePayments from '../Statement/InvoicePayments';
import CheckOutBalanceButton from '../Statement/CheckOutBalanceButton';
import { collection, doc,  writeBatch } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../../firebase-config';
import { MoonLoader } from 'react-spinners';


const StatementModal = ({ deceased, invoices, onClose, user, nextOfKinEmail }) => {
  const [deceasedData, setDeceasedData] = useState(deceased);
  const [computedWallet, setComputedWallet] = useState(0);
  const [balance, setBalance] = useState(0);
  const [checkOutBalance, setCheckOutBalance] = useState(0);
  const [balanceText, setBalanceText] = useState('');
  const [isCheckoutClicked, setIsCheckoutClicked] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [accumulatedStorageFees, setAccumulatedStorageFees] = useState(0);
  const [totalCostsIncurred, setTotalCostsIncurred] = useState(0);
  const [credits, setCredits] = useState(0);
  const [exitDate, setExitDate] = useState('');
  const [checkoutStatus, setCheckoutStatus] = useState({
    message: '',
    type: '', 
  });
  
 
  const formatDate = (timestamp) => new Intl.DateTimeFormat('en-UK').format(timestamp.toDate());

  const calculateWalletBalance = (wallets) => {
    let balance = 0;
    wallets.forEach(transaction => {
      if (transaction.transactionType === 'credit') {
        balance += transaction.amount;
      } else if (transaction.transactionType === 'debit') {
        balance -= transaction.amount;
      }
    });
    return balance;
  }

  useEffect(() => {
    
    if (deceasedData && deceasedData.walletTransactions) {
      setComputedWallet(calculateWalletBalance(deceasedData.walletTransactions));
    }
  }, [deceasedData]);

  
  useEffect(() => {
    const userExitDate = exitDate ? new Date(exitDate) : deceased.exitDate ? new Date(deceased.exitDate) : new Date();
    const accumulatedFees = calculateAccumulatedStorageFees(deceased.allocations, userExitDate);
   
    const totalInvoiceAmount = invoices.reduce((sum, invoice) => sum + invoice.total, 0);
      
    setAccumulatedStorageFees(accumulatedFees);
    setTotalCostsIncurred(accumulatedFees + totalInvoiceAmount);
  }, [deceased, invoices, exitDate]); 
  

useEffect(() => {
  const totalPaymentsReceived = invoices.reduce((sum, invoice) => sum + invoice.total, 0);
  setCredits(totalPaymentsReceived);
  const totalPayable = totalCostsIncurred;  
   const totalPaid = computedWallet + totalPaymentsReceived;  
  const newBalance = totalPayable - totalPaid;
  setBalance(newBalance);
  setCheckOutBalance(newBalance);  

  const isOverpayment = newBalance < 0;
    const updatedBalanceText = isOverpayment ? 'Excess Payment' : (newBalance > 0 ? 'Outstanding Balance' : 'Balance Clear');
    setBalanceText(updatedBalanceText);
}, [invoices, computedWallet, totalCostsIncurred]);


const handleCheckOut = async () => {
  setIsCheckoutClicked(true);
  setIsCheckoutLoading(true);
  const userProvidedExitDate = exitDate ? new Date(exitDate) : new Date();
  const exitCode = Math.floor(100000 + Math.random() * 900000) + deceased.lastName;
  let deceasedRef = doc(db, "deceased", deceased.id);
  let allocationCollectionRef = collection(db, "storageAllocations");
  let batch = writeBatch(db);
  const functions = getFunctions();
  const sendExitCode = httpsCallable(functions, 'sendExitCode');

  try {

    // Sort allocations by dateAssigned in descending order to get the most recent allocation
    const mostRecentAllocation = deceased.allocations.sort((a, b) => b.dateAssigned.toDate() - a.dateAssigned.toDate())[0];

    // Proceed only if there's at least one allocation
    if (mostRecentAllocation) {
      const spaceRef = doc(db, 'storage', mostRecentAllocation.storageType, 'spaces', mostRecentAllocation.storageNumber);
      batch.update(spaceRef, { occupied: false, deceasedId: '' }); // Clearing deceasedId to unassign
    }


    batch.update(deceasedRef, {
      status: "Checked Out",
      exitDate: userProvidedExitDate, 
      checkedOutBy: user,
      exitCode: exitCode
    });

  

    await batch.commit();
    await sendExitCode({
      nextOfKinEmail,
      deceasedName: deceased.firstName + " " + deceased.lastName,
      exitCode
    });

    setCheckoutStatus({ message: `Check out successful. Exit code is ${exitCode}`, type: 'success' });
  } catch (error) {
    console.error("Error during checkout: ", error);
    setCheckoutStatus({ message: 'Error during checkout. Please try again.', type: 'error' });
  } finally {
    setIsCheckoutLoading(false); // End loading
  }
};


  return (
    <>

{isCheckoutLoading && (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
      <MoonLoader color="#FFFFFF" loading={isCheckoutLoading} size={50} />
    </div>
  )}


    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Generate Statement"
      className="relative flex flex-col items-center justify-center p-6 bg-white rounded-xl w-full max-w-4xl mx-auto mt-4 sm:mt-4"
      >
      <h2 className="text-xl font-semibold text-center text-gray-800 mb-4">
                Statement for {deceased ? `${deceased.firstName} ${deceased.lastName}` : ''} {" "}
                <button
        onClick={onClose}
        className="text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 rounded-md px-4 py-2 ml-2"
      >
        Close
      </button>  

              <button
                  onClick={handleCheckOut}
                  disabled={!isCheckoutClicked && balanceText !== 'Excess Payment' && checkOutBalance !== 0}
                  className={`w-full ml-16 text-sm sm:w-auto px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded" 
                    ${balance > 0 ? 'bg-gray-500' : 'bg-green-500'}"`}
                >
                  Check Out
                </button> 
  
              </h2>
           
              <div className="mt-2">
                <label htmlFor="exitDate" className="block text-sm font-medium text-gray-700">Actual Exit Date: </label>
                <input
                  type="date"
                  id="exitDate"
                  name="exitDate"
                  value={exitDate}
                  onChange={(e) => setExitDate(e.target.value)}
                  className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                />
                <p className='text-xs text-red-400 font-bold mb-1'>For backdated posting only as Accumulated Storage Fee uses current day as exit date</p>

                <CheckOutBalanceButton checkOutBalance={checkOutBalance} />           

              </div>

        <StorageTable 
        allocations={deceased.allocations}
        accumulatedStorageFees={accumulatedStorageFees}
        formatCurrency={formatCurrency}
        deceased={deceased}
         />
        
               <div className="mt-2">
                  <p className="text-lg text-green-600">
                  Wallet Balance: {formatCurrency(computedWallet)}
                  </p>
                </div>
            
                {checkoutStatus.message && (
                <div className={`mt-4 text-center py-2 px-4 rounded-md ${checkoutStatus.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                  {checkoutStatus.message}
                </div>
              )}

            <h4 className="mt-2 text-md leading-6 font-medium text-gray-900">Wallet Transactions</h4>
             <WalletTransactions walletTransactions={deceased.walletTransactions} formatCurrency={formatCurrency} />


            <div className="flex flex-col sm:flex-row w-full mb-6 space-y-4 sm:space-y-0 sm:space-x-4">
                    <CostSummary 
                       expenses={deceased.expenses}
                       formatCurrency={formatCurrency}
                        deceased={deceased}
                        allocations={deceased.allocations}
                         totalCostsIncurred={totalCostsIncurred}
                         invoices={invoices}
                    />
              
                    <InvoicePayments 
                        invoices={invoices}
                        formatCurrency={formatCurrency}
                        credits={credits}
                        formatDate={formatDate}
                        />

              </div>
            
           
    </Modal>

    </>
  );

};

export default StatementModal;
