import { collection, addDoc, doc, getDoc,getDocs,query, updateDoc,deleteDoc } from 'firebase/firestore';
import {db} from '../../firebase-config';

export const addProduct = async (description, costPrice, sellingPrice, quantity, keywords, measurementUnit, thresholdQuantity) => {
  const keywordsArray = keywords.split(',').map(keyword => keyword.trim());
    // Using Number() function to convert string to number
    const numericalCostPrice = Number(costPrice);
    const numericalSellingPrice = Number(sellingPrice);
    const numericalQuantity = Number(quantity);
    const numericalThresholdQuantity = Number(thresholdQuantity);
    const dateOfPosting = new Date().toISOString();
  
  const newProduct = {
    description,
    costPrice: numericalCostPrice, 
    sellingPrice: numericalSellingPrice, 
    quantity: numericalQuantity, 
    keywords: keywordsArray,
    dateOfPosting,
    measurementUnit, 
    thresholdQuantity: numericalThresholdQuantity,
  };

  const productRef = collection(db, 'services');
  const docRef = await addDoc(productRef, newProduct);
  return docRef.id;
};


export const fetchProductList = async () => {
  const productList = [];
  const productQuery = query(collection(db, 'services'));
  const querySnapshot = await getDocs(productQuery);

  querySnapshot.forEach((doc) => {
    productList.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return productList;
};

export const fetchProduct = async (productId) => {
  const productDocRef = doc(db, 'services', productId);
  const productSnapshot = await getDoc(productDocRef);

  if (!productSnapshot.exists()) {
    throw new Error('Product not found');
  }

  return {
    id: productSnapshot.id,
    ...productSnapshot.data(),
  };
};


export const updateProduct = async (productId, updatedProductData) => {
  const productDocRef = doc(db, 'services', productId);
  const updatedData = {
    ...updatedProductData,
    costPrice: Number(updatedProductData.costPrice),
    sellingPrice: Number(updatedProductData.sellingPrice),
    quantity: Number(updatedProductData.quantity),
    thresholdQuantity: Number(updatedProductData.thresholdQuantity),
  };
  await updateDoc(productDocRef, updatedData);
  
};

export const deleteProduct = async (productId) => {
  const productDocRef = doc(db, 'services', productId);
  await deleteDoc(productDocRef);
  
};
