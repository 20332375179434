import React, { useState, useEffect } from 'react';
import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { fetchNextOfKinList } from './nextOfKinService';
import { fetchDeceasedList } from './deceasedService'
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import AccessDenied from '../AccessDenied';
import useRoleRestriction from '../../context/useRoleRestriction';

import NextOfKinForm from './NextOfKinForm';
import NextOfKinTable from './NextOfKinTable';

const pages = [
  { name: 'Deceased', to: '/deceased', current: false, component: Link },
  { name: 'View Next of Kin', to: '/view-kin', current: true, component: Link },
 
]

const NextOfKinPage = () => {
  const [nextOfKinList, setNextOfKinList] = useState([]);
  const [deceasedList, setDeceasedList] = useState([]);

  useEffect(() => {
    const unsubscribeNextOfKin = onSnapshot(collection(db, "nextOfKin"), (snapshot) => {
      let nextOfKinList = [];
      snapshot.forEach((doc) => {
        nextOfKinList.push({...doc.data(), id: doc.id});
      });
      setNextOfKinList(nextOfKinList); 
    });

    const unsubscribeDeceased = onSnapshot(collection(db, "deceased"), (snapshot) => {
      let deceasedList = [];
      snapshot.forEach((doc) => {
        deceasedList.push({...doc.data(), id: doc.id});
      });
      setDeceasedList(deceasedList); 
    });
  
    // Clean up the subscription on unmount
    return () => {
      unsubscribeNextOfKin();
      unsubscribeDeceased();
    };
  }, []);

  const allowedRoles = ["Admin", "Finance", "Mortician"];
  const isAllowed = useRoleRestriction(allowedRoles);
  if (isAllowed === null) return <div>Loading...</div>;
  
  if (!isAllowed) {
    return < AccessDenied />
  }

 return (
    <>
    <nav className="flex gap-6 px-6 py-2 mb-4 sm:px-6" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
            <li>
                <div>
                    <Link to="/" className="text-gray-400 hover:text-gray-500">
                        <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span className="sr-only">Home</span>
                    </Link>
                </div>
            </li>
            {pages.map((page) => (
                <li key={page.name}>
                    <div className="flex items-center">
                        <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <Link to={page.to}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                            aria-current={page.current ? 'page' : undefined}
                        >
                            {page.name}
                        </Link>
                    </div>
                </li>
            ))}
        </ol>
    </nav>

    <div className="mx-auto max-w-7xl px-3 py-4 sm:px-6 lg:px-4">
        <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 sm:grid-cols-2 lg:max-w-none lg:grid-cols-3">
            <div className="lg:col-span-2">
                <div className="p-6 bg-white rounded-lg shadow ring-1 ring-gray-900/5">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">Next of Kin Table</h2>
                    <NextOfKinTable nextOfKinList={nextOfKinList} deceasedList={deceasedList} />
                </div>
            </div>

            <div className="lg:col-start-3">
                <div className="p-6 bg-white rounded-lg shadow ring-1 ring-gray-900/5">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">Create Next of Kin</h2>
                    <NextOfKinForm />
                </div>
            </div>
        </div>
    </div>
    </>
);
}

export default NextOfKinPage;
