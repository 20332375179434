
const banks = [
  "Access Bank Plc",
  "Zenith Bank Plc",
  "Guaranty Trust Bank Plc",
  "United Bank for Africa Plc",
  "First Bank of Nigeria Plc",
  "Stanbic IBTC Bank Plc",
  "Ecobank Transnational Incorporated Plc",
  "Union Bank of Nigeria Plc",
  "FCMB Group Plc",
  "Sterling Bank Plc",
  "Fidelity Bank Plc",
  "Wema Bank Plc",
  "Keystone Bank Limited",
  "Stanbic IBTC Bank Nigeria Limited",
  "Heritage Bank Plc",
  "Unity Bank Plc",
  "First City Monument Bank Plc",
  "Polaris Bank Limited",
  "Jaiz Bank Plc",
  "ALAT by Wema Bank",
  "Standard Chartered Bank Nigeria Limited",
  "Providus Bank Limited",
  "SunTrust Bank Limited",
  "Unity Bank Plc",
  "Standard Chartered Bank Plc",
];

export default banks;
