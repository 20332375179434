import React from 'react';
import { UserGroupIcon, CurrencyDollarIcon, ChartBarIcon, UsersIcon } from '@heroicons/react/20/solid';
import { formatCurrency } from '../../Deceased/helpers';

const Widget = ({ title, value, icon, iconBg }) => (
    <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
        <div className={`p-4 ${iconBg}`}>
            {icon}
        </div>
        <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">{title}</h3>
            <p className="text-3xl">{value}</p>
        </div>
    </div>
);

const Widgets = ({ agentStats, totalCommissionEarned }) => {
    return (
        <div className="grid grid-cols-1 gap-4 px-4 mt-4 sm:grid-cols-4 sm:px-8">
          
            <Widget 
                title="Total Deceased Referred" 
                value={agentStats.totalDeceasedReferred} 
                icon={<UsersIcon className="h-12 w-12 text-white" />} 
                iconBg="bg-blue-400" 
            />

<Widget 
                title="Commission Paid" 
                value= {agentStats.totalCommissionPaid !== 0 ? formatCurrency(agentStats.totalCommissionPaid) : agentStats.totalCommissionPaid } 
                icon={<UserGroupIcon className="h-12 w-12 text-white" />} 
                iconBg="bg-green-400"
            />



                <Widget 
                title="Unpaid Commission" 
                value={agentStats.totalCommissionUnpaid !== 0 ? formatCurrency(agentStats.totalCommissionUnpaid) : agentStats.totalCommissionUnpaid }
                icon={<ChartBarIcon className="h-12 w-12 text-white" />} 
                iconBg="bg-yellow-400" 
            />

<Widget 
                title="All Earned Commission" 
                value={agentStats.totalCommissionEarned !== 0 ? formatCurrency(agentStats.totalCommissionEarned) : agentStats.totalCommissionEarned } 
                icon={<CurrencyDollarIcon className="h-12 w-12 text-white" />} 
                iconBg="bg-red-400" 
            />




        </div>
    );
};

export default Widgets;
