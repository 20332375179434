// NextOfKinTable.js
import React, { useState, useEffect } from 'react';
import { fetchNextOfKinList } from './nextOfKinService';
import { fetchDeceasedList } from './deceasedService';
import { updateNextOfKin } from '../operations/deceasedService';
import 'jspdf-autotable';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'
import { Link} from 'react-router-dom';
import useRoleRestriction from '../../context/useRoleRestriction';
import NextOfKinEditModal from './NextOfKinEditModal';
import AccessDenied from '../AccessDenied';

const pages = [
  { name: 'Deceased', to: '/deceased', current: false, component: Link },
  { name: 'View Next of Kin', to: '/view-kin', current: true, component: Link },
 
]


function NextOfKinTable() {
  const [nextOfKinList, setNextOfKinList] = useState([]);
  const [search, setSearch] = useState('');
  const [deceasedList, setDeceasedList] = useState([]); 
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedNextOfKin, setSelectedNextOfKin] = useState(null);

  const openEditModal = (nextOfKin) => {
    setSelectedNextOfKin(nextOfKin);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedNextOfKin(null);
    setIsEditModalOpen(false);
  };

  const handleSaveChanges = async (updatedNextOfKin) => {
    try {
      // Extract the ID and the rest of the data
      const { id, ...data } = updatedNextOfKin;
  
      // Pass both the ID and data to the update function
      await updateNextOfKin(id, data);
  
      // If no errors are thrown, proceed to update the local state
      setNextOfKinList((prevList) =>
        prevList.map((nok) => (nok.id === id ? updatedNextOfKin : nok))
      );
      closeEditModal();
    } catch (error) {
      // Handle error
      console.error('An error occurred while updating Next of Kin details', error);
    }
  };
  
  
  

  useEffect(() => {
    async function fetchData() {
      const nextOfKinData = await fetchNextOfKinList();
      const deceasedData = await fetchDeceasedList();
      setNextOfKinList(nextOfKinData);
      setDeceasedList(deceasedData);
    }
    fetchData();
  }, []);
  


  const filteredNextOfKin = nextOfKinList.map((nextOfKin) => {
    const associatedDeceased = nextOfKin.deceasedId
      ? deceasedList
          .filter((deceased) => deceased.deceasedId === nextOfKin.deceasedId)
          .map((deceased) => deceased.name)
      : [];
  
    return {
      ...nextOfKin,
      associatedDeceased,
    };
  }).filter((nextOfKin) => nextOfKin.nextOfKinName && nextOfKin.nextOfKinName.toLowerCase().includes(search.toLowerCase()));
  


  const getDeceasedName = (deceasedId) => {
     const deceased = deceasedList.find((item) => item.id === deceasedId);
     return deceased ? deceased.firstName + ' '+ deceased.lastName : '';
  };
  
const allowedRoles = ["Admin", "Finance"];
const isAllowed = useRoleRestriction(allowedRoles);
 if (isAllowed === null) return <div>Loading...</div>;
  
if (!isAllowed) {
  return < AccessDenied />
}


  return (
    <>
    <nav className="flex gap-10 px-15 sm:px-8 mb-5" aria-label="Breadcrumb">
    <ol className="flex items-center space-x-4">
      <li>
        <div>
          <Link to="/" className="text-gray-400 hover:text-gray-500">
            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span className="sr-only">Home</span>
          </Link>
        </div>
      </li>
      {pages.map((page) => (
        <li key={page.name}>
          <div className="flex items-center">
            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <Link to={page.to}
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              aria-current={page.current ? 'page' : undefined}
            >
              {page.name}
            </Link>
          </div>
        </li>
      ))}
    </ol>
  </nav>

    <div className ="container mx-auto sm:px-10">
      <div className="flex flex-wrap ">
      <div className="sm:w-full pr-4 pl-4 md:w-full pr-4 pl-4 lg:w-full pr-4 pl-4">
      <input
        type="text"
        placeholder="Search by Next of Kin Name"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="mb-4 p-2 border border-gray-300 w-1/3 rounded"
      />
      
       
    </div>


   


    <div className=" pr-4 pl-4  pr-4 pl-4 pr-2 pl-4" >
    <table className="min-w-full divide-y divide-gray-200">
    <thead className="bg-gray-50">
      <tr>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone Number</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Associated Deceased</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Relationship</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
        {filteredNextOfKin.map((nextOfKin, index) => (
          
          <tr key={nextOfKin.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
           <td className="px-6 py-4 text-sm text-gray-900">{nextOfKin.nextOfKinName}</td>
      <td className="px-6 py-4 text-sm text-gray-900">{nextOfKin.nextOfKinPhone}</td>
      <td className="px-6 py-4 text-sm text-gray-900">{nextOfKin.nextOfKinEmail}</td>
      <td className="px-6 py-4 text-sm text-gray-900"> {nextOfKin.deceasedId ? getDeceasedName(nextOfKin.deceasedId) : ''}
        </td>

      <td className="px-6 py-4 text-sm text-gray-900">{nextOfKin.nextOfKinRelationship}</td>
      <td className="px-6 py-4 text-sm text-gray-900">{nextOfKin.nextOfKinAddress}</td>
      <td className="px-6 py-4 text-sm text-gray-900">
        <button
          className="text-indigo-600 hover:text-indigo-900"
          onClick={() => openEditModal(nextOfKin)}
        >
          Edit
        </button>
      </td>
          </tr>
))}
      </tbody>
    </table>
              </div>
              {
  isEditModalOpen && (
    <NextOfKinEditModal
      isOpen={isEditModalOpen}
      nextOfKin={selectedNextOfKin}
      onSave={handleSaveChanges}
      onClose={closeEditModal}
    />
  )
}

      </div>
    </div>
    </>
  );
}

export default NextOfKinTable;