// useRoleRestriction.js
import { useUserRole } from "./UserRoleContext";

export default function useRoleRestriction(allowedRoles) {
  const { userRole, loading } = useUserRole();

    // Return null while role is being loaded
    if (loading) return null;


  return allowedRoles.includes(userRole);
}
