import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Link } from 'react-router-dom'
import { formatCurrency } from '../Deceased/helpers';
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';
import BreadCrumb from '../BreadCrumb';


ChartJS.register(ArcElement, Tooltip, Legend);

const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: false },  
    { name: 'Financial Reports', to: '/settings/finance', component: Link, current: false },
    { name: 'Monthly Revenue', to: '/settings/finance/monthly-revenue', component: Link, current: true },     
  ]
  

const MonthlyRevenue = () => {
  const [monthlyIncome, setMonthlyIncome] = useState({});
  const [paymentModesCount, setPaymentModesCount] = useState({});
  const [loading, setLoading] = useState(true);
  const [transactionsData, setTransactionsData] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState('');
  const [weeklyIncome, setWeeklyIncome] = useState({});
   
  useEffect(() => {
    const fetchTransactions = async () => {
        setLoading(true);
        try {
            const transactionsSnapshot = await getDocs(collection(db, 'transactions'));
            const data = transactionsSnapshot.docs.map(doc => ({
                ...doc.data(),
                dateIncurred: doc.data().dateIncurred.toDate() 
            }));
            setTransactionsData(data);

            // Process data right after fetching and setting it
            processMonthlyIncomeAndModesCount(data);
        } catch (error) {
            console.error("Error fetching transactions:", error);
        }
        setLoading(false);
    };

    fetchTransactions();
}, []);


const processMonthlyIncomeAndModesCount = (data) => {
    const incomeByMonth = {};
    const modesCountByMonth = {};

    data.forEach(transaction => {
        const monthYear = `${transaction.dateIncurred.toLocaleString('default', { month: 'short' })} ${transaction.dateIncurred.getFullYear()}`;
        const amount = transaction.amount;
        const mode = transaction.sourceOfIncome;

        if (!incomeByMonth[monthYear]) {
            incomeByMonth[monthYear] = {};
        }
        incomeByMonth[monthYear][mode] = (incomeByMonth[monthYear][mode] || 0) + amount;

        const currentMonthYear = `${new Date().toLocaleString('default', { month: 'short' })} ${new Date().getFullYear()}`;
        if (monthYear === currentMonthYear) {
            modesCountByMonth[mode] = (modesCountByMonth[mode] || 0) + 1;
        }
    });

    setMonthlyIncome(incomeByMonth);
    setPaymentModesCount(modesCountByMonth);
};

  const handleMonthYearChange = (e) => {
    setSelectedMonthYear(e.target.value);
    // Call a function to calculate weekly data based on selectedMonthYear
    calculateWeeklyIncome(e.target.value);
};

const calculateWeeklyIncome = (monthYear) => {
    const [selectedMonth, selectedYear] = monthYear.split('-').map(Number);
    const weeksIncome = { 1: 0, 2: 0, 3: 0, 4: 0 };

    transactionsData.forEach(transaction => {
        const incurredDate = transaction.dateIncurred;
        if (incurredDate.getFullYear() === selectedYear && incurredDate.getMonth() === selectedMonth - 1) {
            // Determine the week of the month for the transaction
            const weekNumber = Math.ceil(incurredDate.getDate() / 7);
            weeksIncome[weekNumber] += transaction.amount;
        }
    });

    setWeeklyIncome(weeksIncome);
};

const renderMonthYearDropdown = () => {
    const years = [...new Set(transactionsData.map(item => item.dateIncurred.getFullYear()))];
    const months = Array.from({ length: 12 }, (_, i) => i + 1); // 1 to 12

    return (
        <select 
            onChange={handleMonthYearChange} 
            value={selectedMonthYear} 
            className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
            <option value="">Select Month-Year</option>
            {years.map(year =>
                months.map(month => 
                    <option key={`${month}-${year}`} value={`${month}-${year}`}>
                        {`${month.toString().padStart(2, '0')}-${year}`}
                    </option>
                )
            )}
        </select>
    );
};

const renderWeeklyIncomeTable = () => {
    return (
        <table className="min-w-full divide-y divide-gray-200 mt-4">
            <thead className="bg-gray-50">
                <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Week
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Income
                    </th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {Object.keys(weeklyIncome).map(week => (
                    <tr key={week}>
                        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                            Week {week}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                            {formatCurrency(weeklyIncome[week])}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const renderTable = () => {
    const paymentModes = ['POS-NOMBA', 'POS', 'POS-MoniePoint', 'Cash', 'Bank transfer'];
    const tableRows = Object.keys(monthlyIncome).map(month => {
      const incomeData = paymentModes.map(mode => monthlyIncome[month][mode] || 0);
      const totalIncome = incomeData.reduce((sum, income) => sum + income, 0);
  
      return (
        <tr key={month}>
          <td className='text-sm text-center py-3'>{month}</td>
          {incomeData.map((income, index) => <td className='text-sm text-center' key={index}>{formatCurrency(income)}</td>)}
          <td className='text-sm font-bold text-center'>{formatCurrency(totalIncome)}</td>
        </tr>
      );
    });
  
    return (
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 py-4">
          <tr>
            <th className='py-3'>Month</th>
            {paymentModes.map(mode => <th className='py-3' key={mode}>{mode}</th>)}
            <th>Total Income</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 py-3">
          {tableRows}
        </tbody>
      </table>
    );
  };

 const renderPieChart = () => {
    const data = {
      labels: Object.keys(paymentModesCount),
      datasets: [{
        data: Object.values(paymentModesCount),
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(175, 213, 192, 0.5)'
        ],
        hoverOffset: 4
      }]
    };
  
    return <Pie data={data} />;
  };



const allowedRoles = ["Admin", "Owner"];
const isAllowed = useRoleRestriction(allowedRoles);

if (!isAllowed) {
  return < AccessDenied />
}

if (loading) {
    return <div>Loading...</div>;
}


return (
    <>
    <BreadCrumb pages={pages} />

    
    <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold text-center mb-4">Monthly Revenue </h2>
        <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-3/5 p-4">
                <div className="mb-4">
                    <h3 className="text-lg font-bold mb-2">Income Breakdown</h3>
                    {renderTable()}
                </div>
                <div>
                 
                   
                </div>
            </div>
            <div className="w-full md:w-2/5 p-4">
            <h3 className="text-lg font-bold mb-2">Weekly Income</h3>
            {renderMonthYearDropdown()}
                     {selectedMonthYear && renderWeeklyIncomeTable()}
           
            </div>
        </div>


        <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-3/5 p-4">
                <div className="mb-4">
                   
                    <h3 className="font-bold mb-2 text-center">Source of Payment for Current Month</h3>
                      <div>
                      {renderPieChart()}
                    </div>
                 
                </div>
                <div>
                                    
                </div>
            </div>
            <div className="w-full md:w-2/5 p-4">
            <h3 className="font-bold mb-2 text-center">Source of Payment for Current Week</h3>
           
            </div>
        </div>


    </div>
    </>
);
};

export default MonthlyRevenue;