import React from 'react'
import { Link } from 'react-router-dom';
import biglogo from "../assets/biglogo.png";
export default function AccessDenied() {
    return (
      <>
       
         
       <div className="flex justify-center">
       
        <div className="grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
          <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
            <a href="/">
              <span className="sr-only">Elite Funeral Home</span>
              <img
                className="h-auto w-auto sm:h-12 mx-auto"
                src={biglogo }
                alt=""
              />
            </a>
          </header>
          <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
            <div className="max-w-lg">
              <p className="text-base font-semibold leading-8 text-indigo-600">403</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Access Denied</h1>
              <p className="mt-6 text-base leading-7 text-gray-600">
                You are not Authorized to view the Page.
              </p>
              <div className="mt-10">
              <Link to="/" className="text-sm font-semibold leading-7 text-indigo-600">
                  <span aria-hidden="true">&larr;</span> Back to home
                </Link>
              </div>
            </div>
          </main>
          <footer className="self-end lg:col-span-2 lg:col-start-1 lg:row-start-3">
            <div className="border-t border-gray-100 bg-gray-50 py-10">
              <nav className="mx-auto flex w-full max-w-7xl items-center gap-x-4 px-6 text-sm leading-7 text-gray-600 lg:px-8">
              <a href="mailto:ofagbenro@gmail.com">Notify Systems Support</a>
                <svg viewBox="0 0 2 2" aria-hidden="true" className="h-0.5 w-0.5 fill-gray-300">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                
                <svg viewBox="0 0 2 2" aria-hidden="true" className="h-0.5 w-0.5 fill-gray-300">
                  <circle cx={1} cy={1} r={1} />
                </svg>
              
              </nav>
            </div>
          </footer>
          <div className="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block">
            <img
              src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80"
              alt="randomImage"
              className="absolute inset-0 h-full w-full object-cover"
            />
          </div>
        </div>
        </div>
      </>
    )
  }
  