import React from 'react';

const WalletTransactions = ({ walletTransactions, formatCurrency }) => {
  return (
    <div className="overflow-auto w-full h-16 mt-2 border-t border-gray-200">
      {walletTransactions && walletTransactions.length > 0 ?
        walletTransactions.map((transaction, index) => (
          <div key={index} className="border-b border-gray-200">
            <p className="text-sm text-gray-500 px-10">
              TX Type: {transaction.transactionType} {" "} {" "} 
              <span className='px-10 pr-10'>Amount: {formatCurrency(transaction.amount)}</span>
              {" "} {" "} 
              <span className='px-10 pr-10'>  Date: {new Date(transaction.date.seconds * 1000).toLocaleDateString()} </span>
            </p>                            
          </div>
        ))
        : <p className="text-xs text-gray-500">No transactions found.</p>
      }
    </div>
  );
};

export default WalletTransactions;
