import React from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase-config';
import AccessDenied from '../AccessDenied';
import useRoleRestriction from '../../context/useRoleRestriction';



const NextOfKinForm = () => {
  const allowedRoles = ["Admin", "Finance", "Mortician"];
  const isAllowed = useRoleRestriction(allowedRoles);
  if (isAllowed === null) return <div>Loading...</div>;
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
      await addDoc(collection(db, 'nextOfKin'), {
        name: formData.get('nextOfKinName'),
        phone: formData.get('nextOfKinPhone'),
        email: formData.get('nextOfKinEmail'),
        address: formData.get('nextOfKinAddress'),
        relationship: formData.get('nextOfKinRelationship'),
        gender: formData.get('nextOfKinGender'),
      });
      console.log('Next of kin added successfully');
    } catch (error) {
      console.error('Error adding next of kin: ', error);
    }
  };

  if (!isAllowed) {
    return < AccessDenied />
  }


  return (

   
            
            <div className='border-solid border-2 border-gray-400 py-4 pl-4 pr-4 items-center bg-white rounded shadow-lg'>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="nextOfKinName" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="nextOfKinName"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
              </div>

              <div>
                <label htmlFor="nextOfKinPhone" className="block text-sm font-medium text-gray-700">
                  Phone
                </label>
                <div className="mt-1">
                  <input
                    type="tel"
                    name="nextOfKinPhone"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="nextOfKinEmail" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="nextOfKinEmail"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="nextOfKinAddress" className="block text-sm font-medium text-gray-700">
                  Address
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="nextOfKinAddress"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="nextOfKinRelationship" className="block text-sm font-medium text-gray-700">
                  Relationship to deceased
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="nextOfKinRelationship"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="nextOfKinGender" className="block text-sm font-medium text-gray-700">
                  Gender
                </label>
                <div className="mt-1">
                  <select
                    name="nextOfKinGender"
                    required
                    className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full bg-indigo-600 text-white font-bold py-2 px-4 rounded mt-4 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Submit
                </button>
              </div>
            </form>
            </div>
        

    

    
  );
};

export default NextOfKinForm;
