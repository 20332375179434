import React from 'react'
import { BuildingStorefrontIcon, HomeIcon,  UserMinusIcon, WalletIcon,
    BanknotesIcon, CalendarDaysIcon, ChevronRightIcon, WrenchIcon, ReceiptRefundIcon,InformationCircleIcon,BuildingOffice2Icon,ShoppingCartIcon,InboxIcon
  } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom'
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';


const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: true },     
  ]

  const cardsData = [
    { id: 1, title: 'Employee Management', content: 'Create, Edit, Change Role and Delete Employee Accounts.', link: '/settings/employee', icon: <BanknotesIcon className="h-6 w-6 text-red-500 mb-2 mx-auto" /> },
    { id: 2, title: 'Financial Reports', content: 'Income, Expenditure, Payables and Receivables.', link: '/settings/finance', icon: <BuildingStorefrontIcon className="h-6 w-6 text-blue-500 mb-2 mx-auto" /> },
    { id: 3, title: 'Approve Commission', content: 'Approve or Reject Payment of Commission to Referral Agents.', link: '/settings/approve-pay', icon: <WalletIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
    { id: 4, title: 'Delete Deceased Record', content: 'View and Delete Deceased in Storage from the System.', link: '/settings/admin-delete', icon: <UserMinusIcon className="h-6 w-6 text-violet-900 mb-2 mx-auto" /> },
    { id: 5, title: 'Occupancy Report', content: 'Body Intake Count and Revenue on a Monthly Basis.', link: '/settings/intake-report', icon: <BuildingOffice2Icon className="h-6 w-6 text-yellow-300 mb-2 mx-auto" /> },
    { id: 6, title: 'Delete Transaction Record ', content: 'Delete Income or Expenditure Erroneously Posted.', link: '/settings/admin-transactions', icon: <ReceiptRefundIcon className="h-6 w-6 text-black mb-2 mx-auto" /> },
    { id: 7, title: 'Accomodation Details ', content: 'Status of all Storage Compartments and the names of the Occupants.', link: '/settings/occupants', icon: <InformationCircleIcon className="h-6 w-6 text-indigo-500 mb-2 mx-auto" /> },
    { id: 8, title: 'Monthly Body Details ', content: 'Details of bodies that arrive for each week of the Month.', link: '/settings/monthlybody', icon: <CalendarDaysIcon className="h-6 w-6 text-amber-800 mb-2 mx-auto" /> },
    { id: 9, title: 'Utility: Orphaned Invoices ', content: 'Cleanup lingering Invoices for Bodies that have been deleted but still have invoices.', link: '/settings/invalidinvoice', icon: <WrenchIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
    { id: 10, title: 'Utility: Part-Payment Invoices ', content: 'Invoices with Part-Payment Status that Require Status Update.', link: '/settings/partinvoice', icon: <WrenchIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
    { id: 11, title: 'Utility: Orpaned Allocations ', content: 'Storage Allocations that have invalid deceased records.', link: '/settings/invalidAllocation', icon: <WrenchIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
    { id: 12, title: 'Value Added Services', content: 'Manage Third Party Services that are offered to customers.', link: '/settings/products', icon: <ShoppingCartIcon className="h-6 w-6 text-green-500 mb-2 mx-auto" /> },
   
  ];

 
    

const AdminContainer = () => {



    const allowedRoles = ["Admin", "Owner"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return < AccessDenied />
  }


  return (
    <>
 <main>
      <nav className="flex gap-10 px-14 sm:px-14" aria-label="Breadcrumb">
      <ol  className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <Link to={page.to}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>

        <div className="container mx-auto py-5">
        <div className="flex flex-wrap">
  {cardsData.map((card) => (
    <div key={card.id} className="w-1/4 p-4">
      <Link to={card.link}>
        <div className="bg-white shadow-md rounded p-4">
          {card.icon}
          <h3 className="text-xl font-bold mb-2">{card.title}</h3>
          <p>{card.content}</p>
        </div>
      </Link>
    </div>
  ))}
</div>
    </div>

      
      </main>

    

    </>
  )
}

export default AdminContainer