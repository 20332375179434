// deceasedService.js
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase-config';

export const fetchDeceasedList = async () => {
  const deceasedQuery = query(collection(db, 'deceased'));
  const deceasedSnapshot = await getDocs(deceasedQuery);
  const deceasedData = deceasedSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return deceasedData;
};
