import { Fragment, useEffect } from 'react'
import { PlusCircleIcon,HomeModernIcon,  ShoppingCartIcon,PhoneIcon } from '@heroicons/react/20/solid'


  import { Link } from 'react-router-dom'
  import useRoleRestriction from "../../context/useRoleRestriction";
  import AccessDenied from '../AccessDenied';
  import BreadCrumb from '../BreadCrumb';





  const pages = [
    { name: 'Deceased', to: '/deceased', component: Link, current: false },
   
  ]


  const actions = [

    {
      title: 'Add New Deceased',
      to: '/add-deceased',
      desc: "Provide details of a fresh body to the System",
      component: Link,
      icon: PlusCircleIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },

    
    {
      title: 'View Next Of Kin',
      to: '/view-kin',
      desc: "View Contact Persons for Existing Bodies",
      icon: PhoneIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },

    {
      title: 'Deceased In Storage ',
      to: '/dtable',
      icon: HomeModernIcon,
      component: Link,
      iconForeground: 'text-yellow-700',
      iconBackground: 'bg-yellow-50',
    },
    

    
  
    {
      title: 'Checked-Out Deceased',
      to: '/exDeceased',
      desc: "Details of Bodies that have left the Morgue",
      icon: ShoppingCartIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },
   
   
   
   
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  

  
  export default function Deceased() {

    const allowedRoles = ["Admin", "Finance", "User", "Auditor", "Mortician", "Front-Desk"];
  const isAllowed = useRoleRestriction(allowedRoles);
  if (isAllowed === null) return <div>Loading...</div>;

  if (!isAllowed) {
    return < AccessDenied />
  }

    return (
      <>
    
    <BreadCrumb pages={pages} />

    
    <div className='flex justify-center items-center pt-6'>
    <div className="divide-y divide-gray-50 grid grid-cols-2 gap-4 overflow-hidden rounded-lg bg-gray-200 shadow">
        {actions.map((action, actionIdx) => (
            <div
                key={action.title}
                className={classNames(
                    'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 transform transition duration-500 ease-in-out hover:scale-105'
                )}
            >
                <div className="flex items-center justify-center">
                    <span
                        className={classNames(
                            action.iconBackground,
                            action.iconForeground,
                            'inline-flex rounded-lg p-3 ring-4 ring-white'
                        )}
                    >
                        <action.icon className="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div className="mt-8">
                    <h3 className="text-base font-semibold text-center leading-6 text-gray-900">
                        <Link to={action.to} className="focus:outline-none">
                            {/* Extend touch target to entire panel */}
                            <span className="absolute inset-0" aria-hidden="true" />
                            {action.title}
                        </Link>
                    </h3>
                    <p className="mt-2 text-sm text-center text-gray-500">
                        {action.desc} 
                    </p>
                </div>
                <span
                    className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                    aria-hidden="true"
                >
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
        ))}
    </div>
</div>

     





      </>
    )
  }
  