import { useState} from 'react'
import { Link } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import AddUser from './AddUser';
import UserList from './UserList';
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';


const pages = [
  { name: 'Home', to: '/', component: Link, current: false },
  { name: 'Owners Center', to: '/settings', component: Link, current: false },
  { name: 'Employee Management', to: '/settings/employee', component: Link, current: true },
  ]


const tabs = [
  { name: 'Employee Creation', component: <AddUser />, current: true },
  { name: 'Employee Modification', component: <UserList />, current: true },
  

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Employee() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)
  const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] = useState(false)

  const [activeTab, setActiveTab] = useState(0)
  const changeTab = (index) => {
    setActiveTab(index)
  }

  
  const allowedRoles = ["Admin"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (isAllowed === null) return <div>Loading...</div>; 

  if (!isAllowed) {
    return < AccessDenied />
  }



  return (
    <>

<nav className="flex gap-10 px-14 sm:px-14" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <Link to={page.to}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
      
      <div>
                     
       
              

      <div className="bg-gray shadow overflow-hidden sm:rounded-md">
                      <h3 className="text-2xl font-bold tracking-tight text-gray-900 ml-20">Employee Management</h3>
                    </div>
                    <div className="flex justify-center w-auto bg-gray-50">
                      <div className="py-2">
                        {/* Tabs */}
                        <div className="lg:hidden">
                          <label htmlFor="selected-tab" className="sr-only">
                            Select a tab
                          </label>
                          <select
                            id="selected-tab"
                            name="selected-tab"
                            className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:text-sm sm:leading-6"
                            defaultValue={tabs.find((tab) => tab.current).name}
                          >
                            {tabs.map((tab) => (
                              <option key={tab.name}>{tab.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden lg:block">
                          <div className="border-b border-gray-200">
                            <nav className="-mb-px flex space-x-8">
                              {tabs.map((tab, index) => (
                                <a
                                  key={tab.name}
                                  onClick={() => setActiveTab(index)}
                                  className={classNames(
                                    activeTab === index ?
                                       'border-purple-500 text-purple-600'
                                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                  )}
                                >
                                  {tab.name}
                                </a>
                              ))}
                            </nav>
                          </div>
                        </div>

                        {/* Description list with inline editing */}
                        {tabs.map((tab, index) => (
                      <div key={tab.name} className={classNames(activeTab === index ? 'block' : 'hidden')}>
                        {tab.component}
                      </div>
                    ))}

                        
                      </div>
                    </div>
               
            
          
        
      </div>
    </>
  )
}
