import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from "../../firebase-config";

export async function checkStorageAvailability(storageType) {
    const spacesRef = collection(db, 'storage', storageType, 'spaces');
    const unoccupiedSpacesQuery = query(spacesRef, where('occupied', '==', false));
    const unoccupiedSpacesSnapshot = await getDocs(unoccupiedSpacesQuery);
  
    const numberOfAvailableSpaces = unoccupiedSpacesSnapshot.docs.length;
    console.log(`Number of available spaces for ${storageType}:`, numberOfAvailableSpaces);
  
    return numberOfAvailableSpaces; // Returns the count of unoccupied spaces
}
