import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const MonthlyExpensesIncomeChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="monthYear" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalExpenses" fill="#8884d8" /> // Use updated key for expenses
        <Bar dataKey="totalIncome" fill="#82ca9d" /> // Use updated key for income
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonthlyExpensesIncomeChart;
