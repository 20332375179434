// nextOfKinService.js
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase-config';

export const fetchNextOfKinList = async () => {
  const nextOfKinQuery = query(collection(db, 'nextOfKin'));
  const nextOfKinSnapshot = await getDocs(nextOfKinQuery);
  const nextOfKinData = nextOfKinSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return nextOfKinData;
};



