// src/components/PendingCommissions.js
import React, { useEffect, useState } from 'react';
import { doc, getDoc} from 'firebase/firestore';
import { formatCurrency } from '../Deceased/helpers';
import { db } from '../../firebase-config';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import { Link, useNavigate } from 'react-router-dom';
import { getPendingCommissions, updateCommissionStatus, updateRejectionReason } from './pendingCommissionsService';
import { sendApprovalEmail } from './emailService';


const pages = [
  { name: 'Finance', to: '/finance', component: Link, current: false },
  { name: 'Agents', to: '/finance/agent', component: Link, current: false },
  { name: 'Manage Agents', to: '/finance/agent/approval', component: Link, current: true },
  ]


  const PendingCommissions = () => {
    const [commissions, setCommissions] = useState([]);
    const [pendingCommissions, setPendingCommissions] = useState([]);
    const [showModal, setShowModal] = useState(false);
     const [rejectionReason, setRejectionReason] = useState("");
     let navigate = useNavigate();


     const fetchPendingCommissions = async () => {
      const commissionsSnapshot = await getPendingCommissions();
    
      const commissions = await Promise.all(
        commissionsSnapshot.docs.map(async (commissionDoc) => {
          const commissionData = commissionDoc.data();
    
          const deceasedRef = doc(db, "deceased", commissionData.deceasedId);
          const referralAgentRef = doc(db, "referralAgents", commissionData.referralAgentId);
    
          const deceasedSnapshot = await getDoc(deceasedRef);
          const referralAgentSnapshot = await getDoc(referralAgentRef);
    
          if (deceasedSnapshot.exists()) {
            commissionData.deceasedName = deceasedSnapshot.data().firstName + ' ' +  deceasedSnapshot.data().lastName;
          } else {
            commissionData.deceasedName = 'Unknown';
          }
    
          if (referralAgentSnapshot.exists()) {
            commissionData.referralAgentName = `${referralAgentSnapshot.data().firstName} ${referralAgentSnapshot.data().lastName}`;
            commissionData.referralAgentEmail = referralAgentSnapshot.data().email;
          } else {
            commissionData.referralAgentName = 'Unknown';
            commissionData.referralAgentEmail = '';
          }
    
          return { id: commissionDoc.id, ...commissionData };
        })
      );
    
      setPendingCommissions(commissions);
    };
              
    
    useEffect(() => {
      fetchPendingCommissions();
    }, []);
      
   
    // Pass entire commission to the function instead of just commissionId
const handleStatusChange = async (commission, status) => {
  const { id: commissionId } = commission;

  await updateCommissionStatus(commissionId, status);
  if (status === 'approved') {
    // Fetch the deceased's name
    const deceasedRef = doc(db, "deceased", commission.deceasedId);
    const deceasedSnapshot = await getDoc(deceasedRef);
    if (deceasedSnapshot.exists()) {
      commission.deceasedName = deceasedSnapshot.data().firstName + ' ' +  deceasedSnapshot.data().lastName;
    } else {
      commission.deceasedName = 'Unknown';
    }
    sendApprovalEmail(commission); // Pass entire commission object including deceasedName
  } else {
    console.error(`Commission with id ${commissionId} not found`);
  }
  setPendingCommissions(pendingCommissions.filter(c => c.id !== commissionId)); 
  navigate ("/finance/agent/all");
};

    


    const rejectCommission = async (commissionId, rejectionReason) => {
      try {
        await updateRejectionReason(commissionId, rejectionReason);
        setShowModal(false);
        fetchPendingCommissions(); // Refresh the table data after updating the rejection reason
        navigate ("/finance/agent/all");
      } catch (error) {
        console.error("Error updating rejection reason:", error);
      }
    };



  const allowedRoles = ["Admin", "Finance"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return < AccessDenied />
  }




  return (
    <>
      <nav className="flex gap-10 px-14 sm:px-14" aria-label="Breadcrumb">
      <ol  className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <Link to={page.to}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>



    <div className="container mx-auto px-4 py-2">
      <h3 className="text-2xl font-semibold mb-4 pt-2">Pending Commissions</h3>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="border px-4 py-2">Referral Agent</th>
            <th className="border px-4 py-2">Deceased Name</th>
            <th className="border px-4 py-2">Commission Amount</th>
            <th className="border px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {pendingCommissions.map(commission => (
            <tr key={commission.id}>
              <td className="border px-4 py-2">{commission.referralAgentName}</td>
              <td className="border px-4 py-2">{commission.deceasedName}</td>
              <td className="border px-4 py-2">{formatCurrency(commission.amount)}</td>
              <td className="border px-4 py-2">
              <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                  onClick={() => handleStatusChange(commission, 'approved')}
                >
                  Approve
                </button>

                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => handleStatusChange(commission.id, 'rejected')}
                >
                  Reject
                </button>
              </td>
            </tr>
          ))}
          
        </tbody>
      </table>
    </div>

    
    {showModal && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    Reject Commission
                  </h3>
                  <div className="mt-2">
                    <textarea
                      placeholder="Enter reason for rejection"
                      value={rejectionReason}
                      onChange={(e) => setRejectionReason(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={rejectCommission}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                  Reject
                  </button>
                  <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                  Cancel
                  </button>
                  </div>
                  </div>
                  </div>
                  </div>
                  )}
                  

              

    </>
  );
};

export default PendingCommissions;
