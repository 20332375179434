import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Link} from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { fetchDeceasedList } from './operations/deceasedService';
import { calculateDeceasedTotalCost } from './Deceased/calculateDaysInStorage';
import  StorageManager from './StorageManagement/StorageSummary'
import BreadCrumb from './BreadCrumb';
  
const pages = [
  { name: 'Home', to: '/', current: true },

]





  export default function Home() {
      
        const [allDeceasedData, setAllDeceasedData] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        const [inStorageDeceasedData, setInStorageDeceasedData] = useState([]);
        const [maleInStorageCount, setMaleInStorageCount] = useState(0);
        const [femaleInStorageCount, setFemaleInStorageCount] = useState(0);
        const [deceasedInStorageCount, setDeceasedInStorageCount] = useState(0);         
         const [totalIncome, setTotalIncome] = useState(null);
         const [agentCount, setAgentCount] = useState("NIL");
         const [totalCostInStorage, setTotalCostInStorage] = useState(0);



          
        // Income TD
      useEffect(() => {
        const fetchTotalIncome = async () => {
          const transactionsCol = collection(db, 'transactions');
          const incomeQuery = query(transactionsCol, where('transactionType', '==', 'income'));
          const incomeSnapshot = await getDocs(incomeQuery);
    
          if (incomeSnapshot.empty) {
            setTotalIncome('NIL');
          } else {
            let total = 0;
            incomeSnapshot.forEach((doc) => {
              total += doc.data().amount;
            });
            setTotalIncome(total);
          }
        };
    
        fetchTotalIncome();
      }, []);


      // Count Deceased
      useEffect(() => {
        const fetchDeceasedData = async () => {
          try {
            const { allDeceased, inStorageDeceased, inStorageCount } = await fetchDeceasedList();
           
            setAllDeceasedData(allDeceased); // Set state for all deceased records
            setInStorageDeceasedData(inStorageDeceased); // Set state for deceased 'in storage'
            setDeceasedInStorageCount(inStorageCount); // 
          } catch (error) {
            console.error("Failed to fetch deceased data:", error);
            // Handle any errors here, such as setting state to display an error message
          }
        };
    
        fetchDeceasedData();
      }, []);


      // Count Agents
      useEffect(() => {
        const fetchAgentCount = async () => {
          const agentCol = collection(db, 'referralAgents');
          const agentSnapshot = await getDocs(agentCol);
    
          if (agentSnapshot.size > 0) {
            setAgentCount(agentSnapshot.size);
          }
        };
    
        fetchAgentCount();
      }, []);
    

      // Storage Receivable
      useEffect(() => {
        async function fetchData() {
          setIsLoading(true);
          try {
          
            const deceasedData = await fetchDeceasedList();
      
          
            let totalCost = 0;
            if (deceasedData.allDeceased && deceasedData.allDeceased.length > 0) {
              for (let dec of deceasedData.allDeceased) {
                if (dec.status.toLowerCase() === 'in storage') {
                  totalCost += calculateDeceasedTotalCost(dec);
                }
              }
            }
            setTotalCostInStorage(totalCost);
          } catch (error) {
            console.error("Error during data fetch:", error);
          } finally {
            setIsLoading(false); // Ensure loading is set to false when the fetch completes or fails
          }
        };
    
        fetchData();
      }, []);
      
      
      useEffect(() => {
        const fetchCounts = async () => {
          // Create a reference to the 'deceased' collection
          const deceasedCol = collection(db, 'deceased');
      
          // Create queries for males and females in storage
          const malesQuery = query(deceasedCol, where('gender', '==', 'Male'), where('status', '==', 'in storage'));
          const femalesQuery = query(deceasedCol, where('gender', '==', 'Female'), where('status', '==', 'in storage'));
      
          // Fetch the documents for each query
          const malesSnapshot = await getDocs(malesQuery);
          const femalesSnapshot = await getDocs(femalesQuery);
      
          // Count the documents that match the criteria
          let maleCount = 0;
          let femaleCount = 0;
      
          malesSnapshot.forEach(doc => {
            if (doc.exists()) {
              maleCount++;
            }
          });
      
          femalesSnapshot.forEach(doc => {
            if (doc.exists()) {
              femaleCount++;
            }
          });
      
          // Update state with the count of documents
          setMaleInStorageCount(maleCount);
          setFemaleInStorageCount(femaleCount);
        };
      
        fetchCounts();
      }, []);
      

    


    return (
      <>
      <BreadCrumb pages ={pages} />


      {isLoading ? (
      <div className="flex justify-center items-center h-screen">
        <MoonLoader color="#000" />
      </div>
    ) : (
     
      <div>
    
      <div className="grid grid-cols-1 gap-4 px-4 mt-4 sm:grid-cols-3 sm:px-8">
    
    <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
        <div className="p-4 bg-blue-400"><svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2">
                </path>
            </svg></div>
        <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Male Bodies in Storage</h3>
            <p className="text-3xl">{maleInStorageCount}</p>
        </div>
    </div>
    <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
        <div className="p-4 bg-indigo-400"><svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z">
                </path>
            </svg></div>
        <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Female Bodies in Storage</h3>
            <p className="text-3xl">{femaleInStorageCount}</p>
        </div>
    </div>
    <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
        <div className="p-4 bg-red-400"><svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4">
                </path>
            </svg></div>
        <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Deceased In Storage</h3>
            <Link to="/dtable"  className="text-3xl hover:text-blue-600"> {deceasedInStorageCount}</Link>
            
        </div>
    </div>
</div>




<div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-7 lg:px-8">

   <div className="mx-auto mt-6 max-w-2xl sm:mt-20 lg:mt-6 lg:max-w-4xl">
           
           <StorageManager />
          </div>


          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Work Smarter</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Everything you need to manage your morgue
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              A platform for managing deceased, the next-of-kin, income, expenses, products and service catalog and agency.
            </p>
          </div>

         

 </div>



 </div>

)}

</>

      
      )
    }