import { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { db } from  '../../firebase-config';
import { Link } from 'react-router-dom';
import { CreditCardIcon, CurrencyDollarIcon, CheckCircleIcon, ChevronLeftIcon,ChevronRightIcon } from '@heroicons/react/20/solid';
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import { formatCurrency, capitalizeFirstLetter} from '../Deceased/helpers';
import moment from 'moment';
import BreadCrumb from '../BreadCrumb';
import Papa from 'papaparse';



const pages = [
  { name: 'Finance', to: '/finance', component: Link, current: false },
  { name: 'Track Invoices', to: '/finance/trackInvoice', component: Link, current: true },
];

const InvoiceTracker = () => {
  const [invoices, setInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [unpaidInvoices, setUnpaidInvoices] = useState({ count: 0, totalValue: 0 });
  const [partPaymentInvoices, setPartPaymentInvoices] = useState({ count: 0, totalValue: 0 });
  const [fullyPaidInvoices, setFullyPaidInvoices] = useState({ count: 0, totalValue: 0 });


  const [currentPage, setCurrentPage] = useState(1);
  const invoicesPerPage = 10;

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "invoices"), (querySnapshot) => {
      const dataPromises = querySnapshot.docs.map(async (docSnapshot) => {
        const invoice = docSnapshot.data();
        if (!invoice.deceasedId) { 
          console.log(`Invoice ${docSnapshot.id} has no deceasedId.`);
          return { ...invoice, id: docSnapshot.id };
        }
        try {
          const deceasedSnap = await getDoc(doc(db, "deceased", invoice.deceasedId));
          if (!deceasedSnap.exists) {
            console.log(`No deceased document found with id ${invoice.deceasedId}`);
            return {
              ...invoice,
              id: docSnapshot.id,
            };
          }
          const deceasedData = deceasedSnap.data();
          if (!deceasedData?.firstName || !deceasedData?.lastName) {
            console.log(`Deceased document with id ${invoice.deceasedId} does not have firstName and/or lastName`);
          }
          return {
            ...invoice,
            id: docSnapshot.id,
            deceasedFirstName: deceasedData.firstName,
            deceasedLastName: deceasedData.lastName,
          };
        } catch (error) {
          console.error('Error fetching deceased document:', error);
          return {
            ...invoice,
            id: docSnapshot.id,
          };
        }
      });

      Promise.all(dataPromises)
        .then(setInvoices)
        .catch((error) => console.error('Error setting invoice data:', error));
    });

    return unsubscribe; // This will unsubscribe from Firestore on unmount.
  }, []);



  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "invoices"), (snapshot) => {
        let unpaidCount = 0, unpaidTotal = 0, partPaymentCount = 0, partPaymentTotal = 0, fullyPaidCount = 0, fullyPaidTotal = 0;;

        snapshot.forEach(doc => {
          const data = doc.data();
          if (data.status === 'unpaid') {
              unpaidCount++;
              unpaidTotal += data.total;
          } else if (data.status === 'part-payment' || data.status === 'deposit') {
              partPaymentCount++;
              partPaymentTotal += data.total;
          } else if (data.status === 'fully-paid' || data.status === 'over-payment' || data.status === 'fully paid' ) {
              fullyPaidCount++;
              fullyPaidTotal += data.total;
          }
      });

        setUnpaidInvoices({ count: unpaidCount, totalValue: unpaidTotal });
        setPartPaymentInvoices({ count: partPaymentCount, totalValue: partPaymentTotal });
        setFullyPaidInvoices({ count: fullyPaidCount, totalValue: fullyPaidTotal });
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
}, []);


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleInvoiceClick = (invoice) => {
    setSelectedInvoice(invoice);
    setIsModalVisible(true);
  };

  const filteredInvoices = invoices.filter((invoice) => {
    // Safely handle potential null or undefined invoice.status
    const invoiceStatus = invoice.status || "";
    
    // Check if the filter matches one of the specific status values or if there's no filter applied
    const matchesStatus = filter.length === 0 || invoiceStatus.toLowerCase().includes(filter.toLowerCase());
    
    // Perform additional filtering based on searchTerm if needed
    const matchesSearchTerm = JSON.stringify(invoice).toLowerCase().includes(searchTerm.toLowerCase());
    
    return matchesStatus && matchesSearchTerm;
  });
  

  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

  const allowedRoles = ["Admin", "Finance", "Front-Desk"];
  const isAllowed = useRoleRestriction(allowedRoles);
  if (isAllowed === null) return <div>Loading...</div>;
  if (!isAllowed) {
    return <AccessDenied />
  }

  const exportToCSV = (invoices) => {
    const csv = Papa.unparse({
      fields: ["Deceased Name", "Invoice Value", "Invoice Date", "Invoice Status"],
      data: invoices.map(invoice => [
        invoice.deceasedFirstName || invoice.deceasedLastName
          ? `${capitalizeFirstLetter(invoice.deceasedFirstName)} ${capitalizeFirstLetter(invoice.deceasedLastName)}`
          : capitalizeFirstLetter(invoice.customerName), // 
        invoice.total,
        moment(invoice.invoiceDate.toDate()).format('DD MMM, YYYY'),
        capitalizeFirstLetter(invoice.status)
      ])
    });
  
    // Create a blob and download the file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'invoices.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
 

  return (

    <>  
        <BreadCrumb pages={pages} />


        <div className="flex justify-around p-2">
            <div className="card bg-white-200 hover:bg-red-400 shadow-lg rounded-lg p-4 max-w-sm transition duration-300 ease-in-out flex flex-col items-center">
                <CurrencyDollarIcon className="h-8 w-8 text-red-800 mb-2" />
                <h3 className="text-lg font-semibold mb-2 text-red-800">Unpaid Invoices</h3>
                <p className="text-md text-sm text-black-700">Count: {unpaidInvoices.count}</p>
                <p className="text-md text-sm text-red-700">Total Value: {formatCurrency(unpaidInvoices.totalValue)}</p>
            </div>

            <div className="card bg-white-400 hover:bg-green-400 shadow-lg rounded-lg p-4 max-w-sm transition duration-300 ease-in-out flex flex-col items-center mb-4">
                <CheckCircleIcon className="h-8 w-8 text-green-800 mb-2" />
                <h3 className="text-lg font-semibold mb-2 text-green-800">Fully Paid Invoices</h3>
                <p className="text-md text-sm text-black-700">Count: {fullyPaidInvoices.count}</p>
                <p className="text-md text-sm text-green-700">Total Value: {formatCurrency(fullyPaidInvoices.totalValue)}</p>
            </div>


            <div className="card bg-white-500 hover:bg-orange-400 shadow-lg rounded-lg p-4 max-w-sm transition duration-300 ease-in-out flex flex-col items-center">
                <CreditCardIcon className="h-8 w-8 text-orange-800 mb-2" />
                <h3 className="text-lg font-semibold mb-2 text-orange-800">Partial Invoices</h3>
                <p className="text-md text-sm text-black-700">Count: {partPaymentInvoices.count}</p>
                <p className="text-md text-sm text-orange-700">Total Value: {formatCurrency(partPaymentInvoices.totalValue)}</p>
            </div>
        </div>


        <div className="flex flex-col items-start space-y-1 p-2 bg-white shadow rounded-lg">
              <div className="w-full flex items-center space-x-4">
                <input 
                  type="text" 
                  value={searchTerm} 
                  onChange={handleSearchChange} 
                  className="flex-1 px-4 py-1 border text-sm border-gray-300 rounded" 
                  placeholder="Search" 
                />

                <select 
                  value={filter} 
                  onChange={handleFilterChange} 
                  className="px-4 py-1 border text-sm border-gray-300 rounded"
                  >
                  <option value="">All</option>
                   <option value="part-payment">Part payment</option>
                   <option value="over-payment">Over-payment</option>
                  <option value="unpaid">Unpaid</option>
                </select>
              </div>

            <div className="w-full space-y-4">

                        <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Deceased Name
                                  </th>
                                  <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Invoice Value
                                  </th>
                                  <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Invoice Date
                                  </th>
                                  <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Invoice Status
                                  </th>
                                  <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <button
                                  onClick={() => exportToCSV(filteredInvoices)}
                                  className="my-2 bg-blue-500 hover:bg-green-400 text-white font-bold py-1 text-sm px-4 rounded"
                                >
                                  Export to CSV
                                </button>
                                  </th>
                                 
                                </tr>
                              </thead>

                              <tbody className="bg-white divide-y divide-gray-200">
                              {currentInvoices.map((invoice) => (
                                <tr key={invoice.id} onClick={() => handleInvoiceClick(invoice)} className="hover:bg-gray-50 cursor-pointer">
                                  <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-500">
                                  {invoice.deceasedFirstName || invoice.deceasedLastName
                                          ? `${capitalizeFirstLetter(invoice.deceasedFirstName)} ${capitalizeFirstLetter(invoice.deceasedLastName)}`
                                          : capitalizeFirstLetter(invoice.customerName) // Use customerName as a backup
                                        }
                                  </td>
                                  <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                                    {formatCurrency(invoice.total)}
                                  </td>
                                  <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                                    {moment(invoice.invoiceDate.toDate()).format('DD-MMM-YYYY')}
                                  </td>
                                  <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                                    {capitalizeFirstLetter(invoice.status)}
                                  </td>
                                 
                                </tr>
                              ))}
                            </tbody>
                </table>

             </div>

             <div className="py-3 flex items-center justify-between border-t border-gray-200">
  <div className="flex-1 flex justify-between sm:hidden">
    <button
      onClick={() => setCurrentPage(currentPage - 1)}
      disabled={currentPage === 1}
      className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
    >
      Previous
    </button>
    <button
      onClick={() => setCurrentPage(currentPage + 1)}
      disabled={currentPage === Math.ceil(filteredInvoices.length / invoicesPerPage)}
      className="ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
    >
      Next
    </button>
  </div>
  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
    <div>
      <p className="text-sm text-gray-700 mr-2">
        Showing <span className="font-medium">{indexOfFirstInvoice + 1}</span> to <span className="font-medium">{indexOfLastInvoice}</span> of <span className="font-medium">{filteredInvoices.length}</span> results
      </p>
    </div>
    <div>
      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredInvoices.length / invoicesPerPage)}
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </nav>
    </div>
  </div>
            </div>


{isModalVisible && (
  <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 z-10">
    <div className="w-full max-w-2xl p-4 bg-white border border-gray-200 rounded space-y-2">
      <button className="mt-2 bg-indigo-500 text-white text-sm hover:bg-indigo-600 px-5 py-3 rounded font-medium shadow-sm" 
                onClick={() => setIsModalVisible(false)}>Close</button>
    <h2 className="font-bold text-xl text-gray-700">Invoice Details</h2>
    <p className="text-gray-600"><span className="font-semibold">Invoice Date: {" "}</span>
     {moment(selectedInvoice.invoiceDate.toDate()).format('DD MMM, YYYY')}</p>
    <p className="text-gray-600"><span className="font-semibold">Deceased Name:</span> {capitalizeFirstLetter(selectedInvoice.deceasedFirstName)} {capitalizeFirstLetter(selectedInvoice.deceasedLastName)}</p>
    <p className="text-gray-600"><span className="font-semibold">Status:</span> {selectedInvoice.status.toUpperCase()}</p>
    <p className="text-gray-600"><span className="font-semibold">Next of Kin:</span> {selectedInvoice.customerName} </p>
    <p className="text-gray-600"><span className="font-semibold">Invoice Value:</span> {formatCurrency(selectedInvoice.total)}</p>
    <p className="text-gray-600"><span className="font-semibold">Amount Received:</span> {formatCurrency(selectedInvoice.amountPaid)}</p>
    <div>
      <h3 className="text-gray-900 font-semibold pb-1">Invoice Items:</h3>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="border px-4 py-2">Description</th>
            <th className="border px-4 py-2">Quantity</th>
            <th className="border px-4 py-2">Unit Price</th>
            <th className="border px-4 py-2">Total Price</th>
          </tr>
        </thead>
        <tbody>
          {selectedInvoice.invoiceItems.map((item, index) => (
            <tr key={index}>
              <td className="border px-4 py-2 text-sm text-center">{item.description}</td>
              <td className="border px-4 py-2 text-sm text-center">{item.quantity}</td>
              <td className="border px-4 py-2 text-sm text-center">{formatCurrency(item.price)}</td>
              <td className="border px-4 py-2 text-sm text-center">{formatCurrency(item.price * item.quantity)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  </div>
  )}

</div>
</>
   
);

};
  
  
export default InvoiceTracker;