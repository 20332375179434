import React, { useState } from 'react';


const PaymentModal = ({ 
  isModalOpen, 
  setModalOpen, 
  handleSubmit, 
  onSubmit, 
  register, 
  errors, 
  isLoading,
  onClose,
  isFormSubmitSuccessful,
  resetForm,
  nextOfKin 
}) => {
  const [dateIncurred, setDateIncurred] = useState(new Date().toISOString().slice(0, 10)); // Defaults to current date
 


  if (!isModalOpen) {
    return null;
  }
  
  const handleDateChange = (e) => {
    console.log(e.target.value);
    setDateIncurred(e.target.value);
  };
  


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg w-11/12 md:max-w-md mx-auto p-5 space-y-6 shadow-2xl overflow-auto">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-4">Add Funds to Wallet</h2>
  
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>

          
        <div className="space-y-2">
                    <label htmlFor="amountReceived" className="block text-sm font-medium leading-5 text-gray-700">Amount Received</label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">₦</span>
                </div>
                    <input
                    type="number"
                    min="0"
                    step="0.01"
                    id="amountReceived"
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                    {...register('amountReceived', { required: true })}              
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                    NGN
                </span>
              
                </div>
             
                </div>
                {errors.amountReceived && <p className='text-xs mt-1 text-red-700'>Amount Paid is Required</p>}
                </div>

                <div className="space-y-2">
                  <label htmlFor="dateIncurred" className="text-sm font-medium text-gray-700">Payment Date</label>
                  <input 
                    type="date" 
                    id="dateIncurred" 
                    className="w-full p-2 border border-gray-300 rounded shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                   
                    onChange={handleDateChange}
                    {...register('dateIncurred', { required: true })}
                  />
                  {errors.paymentDate && <p className="text-xs text-red-600">This field is required</p>}
                </div>


  
          <div className="space-y-2">
            <label htmlFor="paymentSource" className="text-sm font-medium text-gray-700">Payment Source</label>
            <select  
              id="paymentSource"
              className="w-full p-2 border border-gray-300 rounded shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              {...register('paymentSource', { required: true })}
            >
              <option value="">Select Payment Source</option>
              <option value="Bank transfer">Bank Transfer</option>
              <option value="Bank transfer-Zenith">Bank Transfer-Zenith</option>
                <option value="Cash">Cash</option>
                <option value="POS-MoniePoint">MoniePoint POS</option>
                <option value="POS-NOMBA">NOMBA POS</option>
            </select>
            {errors.paymentSource && <p className="text-xs text-red-600">This field is required</p>}
          </div>


  
          <div className="mt-4">
          <button
              type="submit"
              className={`w-full py-2 px-4 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 
              ${isLoading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
              disabled={isLoading} // disable the button while submitting
            >
              Submit Payment
            </button>
            <p className='text-xs text-gray-900 text-center'> A receipt will be sent to next-of-kin: {nextOfKin.nextOfKinName} via {nextOfKin.nextOfKinEmail}</p>
          </div>
        </form>
  
        {isFormSubmitSuccessful && (
          <div className="flex items-center justify-center text-green-500">
            <svg className="w-6 h-6 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
            <p className="text-sm font-medium ml-2">Payment Successful!</p>
          </div>
        )}
  
        <button
              onClick={() => {setModalOpen(false);
                resetForm(); 
                onClose();                
              }}
          
          className="w-full py-2 px-4 text-white font-semibold bg-gray-700 rounded-lg shadow-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-75"
        >
          Close 
        </button>
      </div>
    </div>
  );
  
  
};

export default PaymentModal;
