import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchProduct, updateProduct } from './productService';

const UpdateProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [description, setDescription] = useState('');
  const [costPrice, setCostPrice] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [measurementUnit, setMeasurementUnit] = useState('');
  const [thresholdQuantity, setThresholdQuantity] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const productData = await fetchProduct(id);
      setProduct(productData);
      setDescription(productData.description);
      setCostPrice(productData.costPrice);
      setSellingPrice(productData.sellingPrice);
      setQuantity(productData.quantity);
      setMeasurementUnit(productData.measurementUnit || '');
      setThresholdQuantity(productData.thresholdQuantity || 0);
    }

    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProduct(id, {
        description,
        costPrice,
        sellingPrice,
        quantity,
        measurementUnit, // Include measurementUnit
        thresholdQuantity, // Include thresholdQuantity
      });
      navigate('/finance/products');
    } catch (error) {
      console.error('Error updating product:', error.message);
    }
  };

  if (!product) return <div>Loading...</div>;

  return (
    <>
         <div className="flex justify-center">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Update Product</h1>
          </div>

    <div className="flex justify-center py-10">
      <form onSubmit={handleSubmit} className="space-y-4">
         <div className="flex justify-center">

          <div>
          <label htmlFor="description" className="block text-sm font-medium">Description:</label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div>
          <label htmlFor="costPrice" className="block text-sm font-medium">Cost Price:</label>
          <input
            type="number"
            id="costPrice"
            value={costPrice}
            onChange={(e) => setCostPrice(e.target.value)}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label htmlFor="sellingPrice" className="block text-sm font-medium">Selling Price:</label>
          <input
            type="number"
            id="sellingPrice"
            value={sellingPrice}
            onChange={(e) => setSellingPrice(e.target.value)}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label htmlFor="quantity" className="block text-sm font-medium">Quantity:</label>
          <input
            type="number"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo
            500"
            />
            </div>

            <div>
            <label htmlFor="measurementUnit" className="block text-sm font-medium">Measurement Unit:</label>
            <input
              type="text"
              id="measurementUnit"
              value={measurementUnit}
              onChange={(e) => setMeasurementUnit(e.target.value)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              </div>
              <div>
              <label htmlFor="thresholdQuantity" className="block text-sm font-medium">Threshold Quantity:</label>
              <input
              type="number"
              id="thresholdQuantity"
              value={thresholdQuantity}
              onChange={(e) => setThresholdQuantity(e.target.value)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              </div>
              
            
            <div className='flex flex-auto'>
            <button
                     type="submit"
                     className="bg-amber-600 text-white px-4 py-2 rounded hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                   >
            Update
            </button>
            </div>
          
        </div>
      </form>
    </div>
  </>
   );
 };

            
           
 export default UpdateProductPage;


