import { Doughnut, Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const backgroundColors = [
    '#FF6384', // Red
    '#36A2EB', // Blue
    '#FFCE56', // Yellow
    '#4BC0C0', // Teal
    '#9966FF', // Purple
    '#FF9F40', // Orange
    '#4D5360', // Dark Grey
    '#EA6B66', // Coral
    '#7ACBEE', // Sky Blue
    '#C9CB74', // Olive Green
    // Add more colors if you have more data points
];



const AgentChart = ({ data }) => {

    const getChartColors = (count) => backgroundColors.slice(0, count);

    const doughnutChartData = {
        labels: data.map(item => `${item.firstName} ${item.lastName}`),
        datasets: [{
            label: 'Number of Deceased Referrals',
            data: data.map(item => item.deceasedReferrals),
            backgroundColor: getChartColors(data.length),
            borderWidth: 1,
        }]
    };

    const barChartData = {
        labels: data.map(item => `${item.firstName} ${item.lastName}`),
        datasets: [{
            label: 'Total Commission (₦)',
            data: data.map(item => item.totalCommission),
            backgroundColor: getChartColors(data.length),
            borderWidth: 1,
        }]
    };

    const barChartOptions = {
        indexAxis: 'y', // Making the bar chart horizontal
        scales: {
            xAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        plugins: {
            legend: {
                display: true,
            }
        }
    };

    const doughnutChartOptions = {
        maintainAspectRatio: false, // Adjust aspect ratio
        responsive: true, // Make the chart responsive
        cutout: '70%', // Adjust the cutout size
        animation: {
            animateScale: true,
            animateRotate: true
        },
        plugins: {
            legend: {
                display: true,
                position: 'right', // Adjust legend position
                labels: {
                    boxWidth: 40, // Adjust legend box width
                    padding: 20 // Adjust padding
                }
            },
            tooltip: {
                backgroundColor: 'rgba(255, 255, 255, 0.8)', // Customize tooltip background
                titleColor: '#333', // Customize tooltip title color
                bodyColor: '#666', // Customize tooltip body color
                borderColor: 'lightgrey', // Customize tooltip border color
                borderWidth: 1, // Customize tooltip border width
                cornerRadius: 5, // Round corners of tooltip
                displayColors: false, // Set to false to hide color boxes in tooltip
                usePointStyle: true // Use point style for legend items
            }
        }
    };
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
        <div className="p-4 bg-white rounded shadow" style={{ height: '400px' }}>
            <h2 className="mb-2 text-lg font-semibold">Deceased Referrals Distribution</h2>
            <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
        </div>

        <div className="p-4 bg-white rounded shadow">
            <h2 className="mb-2 text-lg font-semibold">Total Commission per Agent</h2>
            <Bar data={barChartData} options={barChartOptions} />
        </div>
    </div>
    );
};

export default AgentChart;
