import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {db} from '../../firebase-config';



const StatementOfAccount = ({ deceasedId }) => {
  const [deceased, setDeceased] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchDeceasedData = async () => {
      try {
        const deceasedDocRef = collection(db, 'deceased');
        const deceasedQuery = query(deceasedDocRef, where('id', '==', deceasedId));
        const deceasedSnapshot = await getDocs(deceasedQuery);
        const deceasedData = deceasedSnapshot.docs[0].data();
        setDeceased(deceasedData);
      } catch (error) {
        console.error('Error fetching deceased data: ', error);
      }
    };

    const fetchInvoices = async () => {
      try {
        const invoicesDocRef = collection(db, 'invoices');
        const invoicesQuery = query(invoicesDocRef, where('deceasedId', '==', deceasedId));
        const invoicesSnapshot = await getDocs(invoicesQuery);
        const invoicesData = invoicesSnapshot.docs.map((doc) => doc.data());
        setInvoices(invoicesData);
      } catch (error) {
        console.error('Error fetching invoices: ', error);
      }
    };

    const fetchTransactions = async () => {
      try {
        const transactionsDocRef = collection(db, 'transactions');
        const transactionsQuery = query(transactionsDocRef, where('deceasedId', '==', deceasedId));
        const transactionsSnapshot = await getDocs(transactionsQuery);
        const transactionsData = transactionsSnapshot.docs.map((doc) => doc.data());
        setTransactions(transactionsData);
      } catch (error) {
        console.error('Error fetching transactions: ', error);
      }
    };

    fetchDeceasedData();
    fetchInvoices();
    fetchTransactions();
  }, [db, deceasedId]);

  const expenses = deceased?.expenses?.map((expense) => (
    <tr key={expense.description}>
      <td>{expense.description}</td>
      <td>{expense.quantity}</td>
      <td>{expense.unitCost}</td>
    </tr>
  ));

  const payments = transactions?.filter((transaction) =>
    transaction.text.startsWith('Payment for Invoice')
  );

  const totalExpenses = expenses?.reduce((acc, expense) => acc + expense.quantity * expense.unitCost, 0);

  const totalPayments = payments?.reduce((acc, payment) => acc + Number(payment.amount), 0);

  const balance = totalExpenses - totalPayments;


  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Statement of Account</h2>

      <div>
        <h3 className="text-lg font-bold mb-2">Expenses</h3>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-gray-700">Description</th>
              <th className="px-4 py-2 text-gray-700">Quantity</th>
              <th className="px-4 py-2 text-gray-700">Unit Cost</th>
            </tr>
          </thead>
          <tbody>{expenses}</tbody>
        </table>
      </div>

      <div className="mt-4">
        <h3 className="text-lg font-bold mb-2">Payments</h3>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-gray-700">Amount</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => (
              <tr key={index}>
                <td className="px-4 py-2">{payment.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4">
        <h3 className="text-lg font-bold mb-2">Balance</h3>
        <p className="text-red-500">Balance: ${balance}</p>
      </div>
    </div>
  );
};

export default StatementOfAccount;
