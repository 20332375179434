import React from 'react';
import { formatCurrency } from '../Deceased/helpers'; // Import the currency formatting function

const PaymentModal = ({ isOpen, onClose, selectedCommission, onSubmit }) => {
    if (!isOpen || !selectedCommission) return null;

    const handleSubmit = (e) => {
        e.preventDefault();
        const paymentData = {
            amount: selectedCommission.commissionAmount,
            dateOfPayment: new Date(e.target.dateOfPayment.value).toISOString(),
            modeOfPayment: e.target.modeOfPayment.value,
            transactionType: "expense",
            text: `Payment of Commission for ${selectedCommission.agentFirstName} ${selectedCommission.agentLastName}`,
            dateOfPosting: new Date().toISOString(),
           
        };
        onSubmit(paymentData);
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Payment Details for {selectedCommission.agentFirstName} {" "} {selectedCommission.agentLastName} </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="mt-2">
                            <p>Commission Payable: {formatCurrency(selectedCommission.commissionAmount)}</p>
                        </div>
                        <div className="mt-2">
                            <input type="date" name="dateOfPayment" required className="p-2 border rounded"/>
                        </div>
                        <div className="mt-2">
                            <label>
                                <input type="radio" name="modeOfPayment" value="Cash" required/> Cash
                            </label>
                            <label className="ml-4">
                                <input type="radio" name="modeOfPayment" value="Bank Transfer" required/> Bank Transfer
                            </label>
                        </div>
                        <div className="items-center px-4 py-3">
                            <button type="submit" className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300">
                                Submit Payment
                            </button>
                            <button onClick={onClose} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PaymentModal;
