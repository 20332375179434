import React, { useState, useEffect } from 'react';
import { createPayment } from './createPayment';
import { onAuthStateChanged } from 'firebase/auth';
import app from '../../firebase-config';




const PaymentForm = ({ deceasedId }) => {
  const [nextOfKinId, setNextOfKinId] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [status, setStatus] = useState('pending');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(app.auth(), (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const paymentDate = new Date().toISOString();
    await createPayment(nextOfKinId, deceasedId, amount, paymentDate, paymentType, status);
    // Reset the form fields or navigate to another page after the payment is created
  };

  return  user ? (

    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="nextOfKinId" className="block text-sm font-medium text-gray-700">
          Next of Kin ID
        </label>
        <input
          type="text"
          id="nextOfKinId"
          value={nextOfKinId}
          onChange={(e) => setNextOfKinId(e.target.value)}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base"
        />
      </div>
      <div>
        <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
          Amount
        </label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base"
        />
      </div>
      <div>
        <label htmlFor="paymentType" className="block text-sm font-medium text-gray-700">
          Payment Type
        </label>
        <select
          id="paymentType"
          value={paymentType}
          onChange={(e) => setPaymentType(e.target.value)}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base"
        >
          <option value="">Select a payment type</option>
          <option value="morgue_fee">Morgue Fee</option>
          <option value="service_fee">Service Fee</option>
        </select>
      </div>
      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Submit Payment
      </button>
    </form>
  
  ) : (
    <div className="text-center">
      <p className="text-xl">Please Log in to access the payment form.</p>
    </div>
  );


};

export default PaymentForm;
