import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { UserRoleProvider } from "./context/UserRoleContext";

// Page Components
import Home from "./components/Home";
import Login from "./components/Login";
import ForgotPassword from "./auth/ForgotPassword";
import About from "./components/About";
import Audit from "./components/Audit";
import Finance from "./components/Finance";
import DefaultLayout from "./layout/DefaultLayout";
import AccessDenied from "./components/AccessDenied";
import NextOfKinForm from "./components/NextOfKin/NextOfKinForm";
import NextOfKinTable from "./components/NextOfKin/NextOfKinTable";
import NextOfKinPage from "./components/NextOfKin/NextOfKinPage";
import DeceasedList from "./components/Deceased/DeceasedList";
import DeceasedFormContainer from "./components/Deceased/DeceasedFormContainer";
import Deceased from "./components/Deceased/Deceased";
import ExitedDeceased from "./components/Deceased/ExitedDeceased";
import PaymentList from "./components/Finance/PaymentList";
import PaymentForm from "./components/Finance/PaymentForm";
import IncomeExpenditureList from "./components/Finance/IncomeExpenditureList";
import ProductPage from './components/Inventory/ProductPage';
import ProductList from './components/Inventory/ProductList';
import AddProductForm from "./components/Inventory/AddProductForm";
import UpdateProductPage from './components/Inventory/UpdateProductPage';
import DeleteProductPage from './components/Inventory/DeleteProductPage';
import DeceasedReports from "./components/Reports/DeceasedReports";
import InvoiceTracker from "./components/Finance/InvoiceTracker";
// import StorageRates from "./components/Storage/StorageRates";  // Body Storage Rates
import StatementOfAccount from "./components/operations/StatementOfAccount";
import ExitCodeLookup from "./components/ExitCodeLookup";
// import StorageManager from "./components/StorageManagement/StorageManager";

// Finance Posting
import FinancePost from './components/Transactions/FinancePost';
import FinanceInvoice from "./components/Finance/FinanceInvoice"; // Invoice Modal
import PaymentsReceived from "./components/Finance/PaymentsReceived";
import DeceasedStorageOverview from "./components/Settings/DeceasedStorageOverview";

// Referral Agents
import AgentContainer from "./components/ReferralAgents/AgentContainer";
import ReferralAgents from "./components/ReferralAgents/ReferralAgents";
import PendingCommissions from './components/ReferralAgents/PendingCommissions';
import AllCommissions from './components/ReferralAgents/AllCommissions';
import PartnerAgentsCommissionList from "./components/ReferralAgents/PartnerAgentsCommissionList";
import AgentReport from "./components/Settings/AgentReport";

//Finance Report
import IncomeExpenseReport from './components/Reports/IncomeExpenseReport';
import TransactionTable from "./components/Transactions/TransactionTable";

//Experiment
import DeceasedTable from "./components/operations/DeceasedTable";
import DeceasedDetails from './components/operations/DeceasedDetails';
import BodyIntakeReport from "./components/Settings/BodyIntakeReport";
import MonthlyRevenue from "./components/Settings/MonthlyRevenue";

//Owner
import AdminContainer from "./components/Settings/AdminContainer";
import FinanceContainer from "./components/Settings/FinanceContainer";
import AddUser from "./components/Settings/AddUser";
import Settings from "./components/Settings/Employee";
import UserList from "./components/Settings/UserList";
import PurgeDeceased from "./components/Settings/PurgeDeceased";
import AdminTransactions from "./components/Settings/AdminTransactions";
import DeceasedRecordList from "./components/Settings/DeceasedRecordList";
import OccupancyTable from "./components/StorageManagement/OccupancyTable";
import MonthlyBodyDetails from "./components/Settings/MonthlyBodyDetails";
import InvoiceIncomeReport from "./components/Settings/InvoiceIncomeReport";
import DisplayExpenses from "./components/Settings/DisplayExpenses";
import DetailedMonthlyIncome from "./components/Settings/DetailedMonthlyIncome";

// Utlilty roots
import OrphanedInvoices from "./components/Settings/Utilities/OrphanedInvoices";
import PartPaymentInvoices from "./components/Settings/Utilities/PartPaymentInvoices";
import OrphanedStorageAllocations from "./components/Settings/Utilities/OrphanedStorageAllocations";
import StorageIncomeReport from "./components/Settings/StorageIncomeReport";


function App() {
  return (
    <UserRoleProvider>
      <UserAuthContextProvider>
      
         
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login/passwordreset" element={<ForgotPassword />} />
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
        <Routes>
          <Route path="/" element={<ProtectedRoute Component={Home} />} />
          <Route path="/about" element={<ProtectedRoute Component={About} />} />
          {/* <Route path="/reports" element={<ProtectedRoute Component={Reports} />} /> */}
          <Route path="/security" element={<ProtectedRoute Component={ExitCodeLookup} />} />
          <Route path="/audit" element={<ProtectedRoute Component={Audit} />} />
          <Route path="/accessdenied" element={<ProtectedRoute Component={AccessDenied} />} />

          {/* Finance */}
          <Route path="/finance" element={<ProtectedRoute Component={Finance} />} />
          <Route path="/finance/position" element={<ProtectedRoute Component={IncomeExpenditureList} />} />
          <Route path="/finance/post" element={<ProtectedRoute Component={PaymentForm} />} />      
          <Route path="/finance/invoice" element={<ProtectedRoute Component={FinanceInvoice} />  } />
          <Route path="/finance/receipt" element={<ProtectedRoute Component={PaymentsReceived} />  } />
          <Route path="/finance/trackInvoice" element={<ProtectedRoute Component={InvoiceTracker} />  } />


         

             {/* Referal CRUD */}
           <Route path="/finance/agent" element={<ProtectedRoute Component={AgentContainer} />} /> 
           <Route path="/finance/agent/manage" element={<ProtectedRoute Component={ReferralAgents} />} /> 
           <Route path="/finance/agent/approval" element={<ProtectedRoute Component={PendingCommissions} />} /> 
           <Route path="/finance/agent/all" element={<ProtectedRoute Component={AllCommissions} />} /> 
           <Route path="/finance/agent/pay-storage" element={<ProtectedRoute Component={PartnerAgentsCommissionList} />} /> 
           

         
                            

          {/* Deceased Reports */}
          {/* <Route path="/reports" element={<ProtectedRoute Component={Reports} />} /> */}
          <Route path="/reports" element={<ProtectedRoute Component={DeceasedReports} />} />
          
        

           {/* Deceased CRUD */}
          <Route path="/deceased" element={<ProtectedRoute Component={Deceased} />} />
          <Route path="/add-deceased" element={<ProtectedRoute Component={DeceasedFormContainer} />} />
          <Route path="/show-deceased" element={<ProtectedRoute Component={DeceasedList} />} />
          <Route path="/add-kin" element={<ProtectedRoute Component={NextOfKinForm} />} />
          <Route path="/view-kin" element={<ProtectedRoute Component={NextOfKinTable} />} />
          <Route path="/nok" element={<ProtectedRoute Component={NextOfKinPage} />} />

          


          {/* Experiment */}
          <Route path="/dtable" element={<ProtectedRoute Component={DeceasedTable} />} />
          <Route path="/deceased/:id" element={<ProtectedRoute Component={DeceasedDetails} />} />
          <Route path="/deceased/statement/:id" element={<ProtectedRoute Component={StatementOfAccount} />} />
          <Route path="/exDeceased" element={<ProtectedRoute Component={ExitedDeceased} />} />
         


       
          

          <Route path="/payments/:deceasedId" component={PaymentList} />

          <Route path="/settings/employee" element={<ProtectedRoute Component={Settings} />} />
          <Route path="/settings/add-user" element={<ProtectedRoute Component={AddUser} />} />
          <Route path="/settings/edit-user" element={<ProtectedRoute Component={UserList} />} />
          <Route path="/settings/admin-delete" element={<ProtectedRoute Component={PurgeDeceased} />} />
          <Route path="/settings" element={<ProtectedRoute Component={AdminContainer} />} />
          <Route path="/settings/finance" element={<ProtectedRoute Component={FinanceContainer} />} />
          <Route path="/settings/finance/monthly-revenue" element={<ProtectedRoute Component={MonthlyRevenue} />} />
          <Route path="/settings/intake-report" element={<ProtectedRoute Component={BodyIntakeReport} />} />
          <Route path="/settings/admin-transactions" element={<ProtectedRoute Component={AdminTransactions} />} />
          <Route path="/settings/approve-pay" element={<ProtectedRoute Component={DeceasedRecordList} />} />
          <Route path="/settings/finance/agentreport" element={<ProtectedRoute Component={AgentReport} />} /> 
          <Route path="/settings/finance/posting" element={<ProtectedRoute Component={FinancePost} />} />
          <Route path="/settings/occupants" element={<ProtectedRoute Component={OccupancyTable} />} /> 
          <Route path="/settings/monthlybody" element={<ProtectedRoute Component={MonthlyBodyDetails} />} /> 
          <Route path="/settings/finance/invoice-income" element={<ProtectedRoute Component={InvoiceIncomeReport} />} /> 
          <Route path="/settings/finance/detailed-income" element={<ProtectedRoute Component={DetailedMonthlyIncome} />} /> 
          <Route path="/settings/finance/storage-income" element={<ProtectedRoute Component={StorageIncomeReport} />} /> 
          <Route path="/settings/finance/display-expenses" element={<ProtectedRoute Component={DisplayExpenses} />} /> 
          <Route path="/settings/finance/storage-receivable" element={<ProtectedRoute Component={DeceasedStorageOverview} />} /> 
          <Route path="/settings/finance/incomereport" element={<ProtectedRoute Component={IncomeExpenseReport} />} />
          <Route path="/settings/finance/transactions" element={<ProtectedRoute Component={TransactionTable} />} />

          <Route path="/settings/products" element={<ProtectedRoute Component={ProductPage} />} />
          <Route path="/settings/products/add" element={<ProtectedRoute Component={AddProductForm} />} />
          <Route path="/settings/products/list" element={<ProtectedRoute Component={ProductList} />} />        
          <Route path="/settings/products/update/:id" element={<ProtectedRoute Component={UpdateProductPage} />  } />
          <Route exact path="/settings/products/delete/:id" component={DeleteProductPage} />

          {/* <Route path="/settings/storage-rates" element={<ProtectedRoute Component={StorageRates} />} /> */}
          


          {/* Maintenance routes */}
          <Route path="/settings/invalidinvoice" element={<ProtectedRoute Component={OrphanedInvoices} />} /> 
          <Route path="/settings/partinvoice" element={<ProtectedRoute Component={PartPaymentInvoices} />} />
          <Route path="/settings/invalidAllocation" element={<ProtectedRoute Component={OrphanedStorageAllocations} />} /> 
          
          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Routes>
        
      </UserAuthContextProvider>
    </UserRoleProvider>
  );
}

export default App;
