import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchExitedDeceasedList } from '../operations/deceasedService';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import { capitalizeFirstLetter } from './helpers';
import BreadCrumb from '../BreadCrumb';
import { MoonLoader } from 'react-spinners';


const pages = [
  { name: 'Deceased', to: '/deceased', current: false, component: Link },
  { name: 'Exited Deceased', to: '/exDeceased', current: true, component: Link },
]
function ExitedDeceased() {
  const [deceasedList, setDeceasedList] = useState([]);
  const [search, setSearch] = useState('');
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const allowedRoles = ["Admin", "Finance", "Mortician", "Front-Desk"];
  const isAllowed = useRoleRestriction(allowedRoles);
  const [arrivalDateFilter, setArrivalDateFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [isLoading, setIsLoading] = useState(false);

 


  useEffect(() => {
    setIsLoading(true); 
    fetchExitedDeceasedList()
      .then(data => {
        setDeceasedList(data);
        setIsLoading(false); // Stop loading once data is fetched
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setDeceasedList([]);
        setIsLoading(false); 
      });
  }, []);
  


  const filteredDeceasedList = deceasedList.filter(deceased => {
    const lastName = deceased.lastName ? deceased.lastName.toLowerCase() : '';
    const firstName = deceased.firstName ? deceased.firstName.toLowerCase() : '';
    const arrivalDateMatch = arrivalDateFilter ? deceased.dateDeposited === arrivalDateFilter : true;
    
    return (
      (firstName.includes(search.toLowerCase()) || lastName.includes(search.toLowerCase()))  &&
      arrivalDateMatch
    );
  });

  const sortedDeceasedList = filteredDeceasedList.sort((a, b) => {
    const fieldA = a[sortField];
    const fieldB = b[sortField];
  
    if (fieldA < fieldB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (fieldA > fieldB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const totalPages = Math.ceil(sortedDeceasedList.length / itemsPerPage);
  const currentItems = sortedDeceasedList.slice(indexOfFirstItem, indexOfLastItem);



  function handlePreviousPage() {
    setCurrentPage(currentPage => Math.max(1, currentPage - 1));
  }
  
  function handleNextPage() {
    setCurrentPage(currentPage => Math.min(totalPages, currentPage + 1));
  }
  
  

  function handleSort(field) {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  }



  
 

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A'; // Handle undefined, null, or empty timestamps
  
    let date;
    try {
      if (typeof timestamp === 'string' || timestamp instanceof String) {
        // If it's a string, parse it into a Date object
        // Adjust the parsing as per your date format
        date = new Date(Date.parse(timestamp.replace(' at ', ' ')));
      } else if (timestamp.seconds) {
        // If it's a Firestore Timestamp object
        date = new Date(timestamp.seconds * 1000);
      } else {
        // If it's already a JavaScript Date object
        date = timestamp;
      }
    } catch (error) {
      console.error('Error parsing date:', timestamp, error);
      return 'Invalid Date';
    }
  
    if (isNaN(date)) return 'Invalid Date'; // Check if the date is invalid
  
    return date.toLocaleDateString(); // Format the date as a string
  };


  
  if (!isAllowed) {
    return < AccessDenied />
  }


  return (
    <>
     <BreadCrumb pages={pages} />

    <div className='flex flex-col justify-center items-center p-8'>

    {isLoading ? (
        <div className="flex justify-center items-center">
          <MoonLoader color="#000000" />
        </div>
      ) : (
        <>


<div className="bg-gray-50 p-4 sm:p-6 md:p-8 lg:px-10 lg:py-4 xl:px-12 xl:py-4 rounded-md shadow-sm ">
  <h3 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 mb-4">Deceased Exited from Morgue</h3>
  
  <div className="flex flex-col md:flex-row md:items-end gap-4">
    <input
      type="text"
      placeholder="Name Search"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      className="flex-1 border border-gray-300 p-2 rounded-lg text-sm md:text-base placeholder-gray-500"
    />
    <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
      <label htmlFor="arrivalDateFilter" className="text-sm md:text-base font-medium text-gray-700">Filter by Arrival Date:</label>
      <input 
        id="arrivalDateFilter"
        type="date" 
        value={arrivalDateFilter} 
        onChange={(e) => setArrivalDateFilter(e.target.value)}
        className="border border-gray-300 p-2 rounded-lg text-xs md:text-base uppercase "
      />
    </div>
  </div>
  
  <p className='text-xs md:text-sm text-gray-600 pt-2 md:pt-4'>You can also sort Table Data by Clicking on the each header</p>
</div>


             
     <table className="table-auto border-collapse border border-gray-200">
        
        <thead className="bg-gray-50">
          <tr>         
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={() => handleSort('firstName')}>
              First Name
            </th>
            
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={() => handleSort('lastName')}>
              Last Name
            </th>

            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              onClick={() => handleSort('dateDeposited')}>
              Arrival
            </th>        

            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={() => handleSort('exitDate')}>
                Exit Date
            </th>         
                      
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            onClick={() => handleSort('checkedOutBy')}>
              CheckOut By
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
             Action
            </th>

          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 ">
          {currentItems.map((deceased, index) => {
                       
            return (
              <tr key={deceased.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 text-sm text-gray-900">{capitalizeFirstLetter(deceased.firstName)}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{capitalizeFirstLetter(deceased.lastName)}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{formatDate(deceased.dateDeposited)}</td>
                <td className="px-6 py-4 text-sm text-gray-900"> {formatDate(deceased.exitDate)}</td>
                <td className="px-6 py-4 text-sm text-gray-900">  {deceased.checkedOutBy}   </td>
                <td className="px-6 py-4 text-sm text-gray-900">
                    
                        <Link
                        to={`/deceased/${deceased.id}`}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                      >
                        Details
                      </Link>                               
                      
                    </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      </>
      )}

    </div>

    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
    <div>
      <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to <span className="font-medium">{indexOfLastItem > sortedDeceasedList.length ? sortedDeceasedList.length : indexOfLastItem}</span> of{' '}
        <span className="font-medium">{sortedDeceasedList.length}</span> results
      </p>
    </div>
    <div>
      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <button
          onClick={handlePreviousPage}
          className="relative inline-flex items-center rounded-l-md px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
       
        {[...Array(totalPages).keys()].map(num => (
          <button
            key={num}
            onClick={() => setCurrentPage(num + 1)}
            className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${currentPage === num + 1 ? 'bg-indigo-600 text-white' : 'text-gray-700 hover:bg-gray-50'}`}
          >
            {num + 1}
          </button>
        ))}
        <button
          onClick={handleNextPage}
          className="relative inline-flex items-center rounded-r-md px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </nav>
    </div>
  </div>
</div>

    </>
  );
}

export default ExitedDeceased;

