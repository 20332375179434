import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const ApprovalModal = ({ isOpen, onClose, deceasedRecord, onSubmit }) => {
    const [status, setStatus] = useState('approve'); // 'approve' or 'reject'
    const [rejectionReason, setRejectionReason] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);

    const handleSubmit = () => {
        if (status === 'reject' && !rejectionReason) {
            alert("Please provide a reason for rejection.");
            return;
        }

        const actionData = {
            actionTaken: status,
            actionTimestamp: new Date(),
            rejectionReason: status === 'reject' ? rejectionReason : '',
        };

        onSubmit(deceasedRecord.id, actionData);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-lg mx-auto mt-10 bg-white rounded-md p-6">

            {submitStatus === 'success' && <p className="text-green-500 mt-2">Action successful!</p>}
            {submitStatus === 'error' && <p className="text-red-500 mt-2">Action failed. Please try again.</p>}
            
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Approval Action</h2>
                <button onClick={onClose} className="p-2 rounded hover:bg-gray-200">X</button>
            </div>
            <div>
                <select value={status} onChange={e => setStatus(e.target.value)} className="w-full mb-4 p-2 border border-gray-300 rounded">
                    <option value="approve">Approve</option>
                    <option value="reject">Reject</option>
                </select>
                {status === 'reject' && (
                    <textarea
                        className="w-full mb-4 p-2 border border-gray-300 rounded"
                        value={rejectionReason}
                        onChange={e => setRejectionReason(e.target.value)}
                        placeholder="Rejection Reason"
                    />
                )}
                <div className="flex justify-end">
                    <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2">Submit</button>
                    <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">Cancel</button>
                </div>
                {submitStatus === 'success' && <p className="text-green-500 mt-2">Action successful!</p>}
                {submitStatus === 'error' && <p className="text-red-500 mt-2">Action failed. Please try again.</p>}
            </div>
        </Modal>
    );
};

export default ApprovalModal;
