import React, { useState, useEffect } from 'react';

export default function NextOfKinEditModal({ isOpen, nextOfKin, onSave, onClose }) {
    const [formData, setFormData] = useState({ ...nextOfKin });
  
    useEffect(() => {
      setFormData({ ...nextOfKin }); // Reset form data when nextOfKin prop changes
    }, [nextOfKin]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onSave(formData);
    };
  
    if (!isOpen) return null;
  
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Edit Next of Kin</h3>
              <div className="mt-2 px-7 py-3">
                <form onSubmit={handleSubmit}>
                  {/* Form fields */}
                  <div className="mb-4">
                    <label htmlFor="nextOfKinName" className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                      type="text"
                      name="nextOfKinName"
                      value={formData.nextOfKinName}
                      onChange={handleChange}
                      className="mt-1 p-2 border border-gray-300 w-full rounded"
                    />
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="nextOfKinEmail" className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                      type="email"
                      name="nextOfKinEmail"
                      value={formData.nextOfKinEmail}
                      onChange={handleChange}
                      className="mt-1 p-2 border border-gray-300 w-full rounded"
                    />
                  </div>
  
                  <div className="mb-4">
                    <label htmlFor="nextOfKinPhone" className="block text-sm font-medium text-gray-700">Phone Number</label>
                    <input
                      type="tel"
                      name="nextOfKinPhone"
                      value={formData.nextOfKinPhone}
                      onChange={handleChange}
                      className="mt-1 p-2 border border-gray-300 w-full rounded"
                    />
                  </div>
  
                  <div className="mb-4">
                    <label htmlFor="nextOfKinGender" className="block text-sm font-medium text-gray-700">Gender</label>
                    <select
                      name="nextOfKinGender"
                      value={formData.nextOfKinGender}
                      onChange={handleChange}
                      className="mt-1 p-2 border border-gray-300 w-full rounded"
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
  
                  <div className="mb-4">
                    <label htmlFor="nextOfKinAddress" className="block text-sm font-medium text-gray-700">Address</label>
                    <input
                      type="text"
                      name="nextOfKinAddress"
                      value={formData.nextOfKinAddress}
                      onChange={handleChange}
                      className="mt-1 p-2 border border-gray-300 w-full rounded"
                    />
                  </div>
  
                  <div className="flex items-center justify-between px-4 py-3">
                    <button
                      className="px-4 py-2 bg-indigo-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-300"
                      type="submit"
                    >
                      Save
                    </button>
                    <button
                      className="px-4 py-2 bg-gray-200 text-black text-base font-medium rounded-md shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
                      type="button"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
  }
  