import React from 'react';
import { formatCurrency } from '../Deceased/helpers';

const CheckOutBalanceButton = ({ checkOutBalance }) => {
  const isOverpayment = checkOutBalance < 0;
  const buttonStyle = isOverpayment
    ? "font-bold text-lg text-green-700 mb-4 bg-green-100 p-2 rounded-md hover:bg-green-200"
    : "font-bold text-lg text-red-700 mb-4 bg-red-100 p-2 rounded-md hover:bg-red-200";
  
  const balanceText = isOverpayment
    ? `Excess Payment: ${formatCurrency(-checkOutBalance)}`
    : `Outstanding Balance: ${formatCurrency(checkOutBalance)}`;

  return (
    <button className={buttonStyle}>
      {balanceText}
    </button>
  );
};

export default CheckOutBalanceButton;
