// deceasedService.js
import { collection, getDocs, getDoc,setDoc,doc, where,query} from 'firebase/firestore';
import { db } from '../../firebase-config';

async function fetchData(collectionName) {
  const coll = collection(db, collectionName);
  const snapshot = await getDocs(coll);
  if (!snapshot.empty) {
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } else {
    // Return an empty array if there are no documents
    return [];
  }
}
 
export async function fetchDeceasedList() {
  const deceasedData = await fetchData('deceased');

  // Here, ensure deceasedData is indeed an array before trying to filter it
  if (Array.isArray(deceasedData)) {
    const inStorageDeceased = deceasedData.filter(deceased => deceased.status === 'in storage');
    
    return {
      allDeceased: deceasedData,
      inStorageDeceased: inStorageDeceased,
      inStorageCount: inStorageDeceased.length, // You can also return the count here
    };
  } else {
    // Handle the case where deceasedData is not what you expect
    console.error('Unexpected data format received:', deceasedData);
    return {
      allDeceased: [],
      inStorageDeceased: [],
      inStorageCount: 0,
    };
  }
}


export async function fetchExitedDeceasedList() {
  const queryConstraint = where("status", "==", "Checked Out");
  const querySnapshot = await getDocs(query(collection(db, "deceased"), queryConstraint));
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}



export async function fetchNextOfKinList() {
  return await fetchData('nextOfKin');
}

export async function fetchReferralAgentsList() {
  return await fetchData('referralAgents');
}

export async function fetchServicesList() {
  return await fetchData('services');
}

export async function fetchDeceasedById(id) {
  const deceasedRef = doc(db, 'deceased', id);
  const deceasedSnapshot = await getDoc(deceasedRef);

  if (!deceasedSnapshot.exists()) {
    // Throwing a specific error when the document doesn't exist
    throw new Error(`No deceased found with ID: ${id}`);
  }

  return {
    id: deceasedSnapshot.id,
    ...deceasedSnapshot.data()
  };
}


export async function updateDeceased(id, data) {
  const deceasedRef = doc(db, 'deceased', id);
  await setDoc(deceasedRef, data, { merge: true }); // Using merge: true to only update provided fields
}


export async function updateNextOfKin(id, data) {
  const nextOfKinRef = doc(db, 'nextOfKin', id);
  await setDoc(nextOfKinRef, data, { merge: true }); // Using merge: true to only update provided fields
}

export const checkForDuplicateRecord = async (firstName, lastName, dateOfDeath) => {
  const deceasedRef = collection(db, 'deceased');
  const q = query(deceasedRef, 
                  where('firstName', '==', firstName.toLowerCase()), 
                  where('lastName', '==', lastName.toLowerCase()), 
                  where('dateOfDeath', '==', dateOfDeath));
  const snapshot = await getDocs(q);
  return !snapshot.empty; // Returns true if duplicate record exists
};