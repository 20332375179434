import { jsPDF } from "jspdf";
import "jspdf-autotable";

export const generatePDFReceipt = (paymentData) => {
  if (!paymentData) {
    alert("No payment data available.");
    return;
  }

  const doc = new jsPDF();

  // Load the local logo image
  const logo = new Image();
  logo.src = '/eliteweb.png';

  let originalWidth = 5812;
  let originalHeight = 1791;
  let newWidth = 150;
  let newHeight = (originalHeight * newWidth) / originalWidth;

  doc.addImage(logo, 'PNG', 10, 2, newWidth, newHeight);

  // Company details
  doc.setFontSize(10);
  doc.text("Toun Memorial Specialist Hospital, KM 1/2, New Ife Road, Ibadan, Oyo State", 70, 40);
  doc.text(" Tel: +234 810 385 1516 | Email: enquiries@eliteufuneralhomes.com", 70, 45);

  // Receipt header
  doc.setFontSize(30);
  doc.text("RECEIPT", 80, 70);
  
  // Horizontal line separators
  doc.setLineWidth(0.5);
  doc.line(10, 60, 200, 60);
  doc.line(10, 80, 200, 80);
  
  // Receipt details
  doc.setFontSize(12);
  doc.text(`Receipt ID: ${paymentData.invoiceId}`, 20, 105);
  doc.text(`Payment Date: ${paymentData.dateReceived}`, 20, 120);
  doc.text(`Customer: ${paymentData.customerName}`, 20, 135);
  doc.text(`Amount Received: NGN${paymentData.paymentAmount.toFixed(2)}`, 20, 150);
  doc.text(`Mode of Payment Source: ${paymentData.paymentSource}`, 20, 165);
  doc.text(`Part/Full Payment: ${paymentData.paymentStatus}`, 20, 180);
  
  // Deceased Name (if exists)
  if (paymentData.deceasedName) {
    doc.text(`Deceased Name: ${paymentData.deceasedName}`, 20, 195);
  }

 

  // Thank you note
  doc.setFontSize(16);
  doc.text("Thank You!", 70, 280);
  doc.setFontSize(10);
  doc.text("We greatly appreciate your prompt payment.", 70, 290);

  // Save the PDF
  doc.save(`receipt-${paymentData.invoiceId}.pdf`);
};
