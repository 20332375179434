import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, onSnapshot, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';

const UserList = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'users'), (querySnapshot) => {
      const fetchedUsers = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
     
      setUsers(fetchedUsers);
    });

    
    return () => {
      unsubscribe();
    };
  }, []);

  const deleteUser = async (userId) => {
    try {
      await deleteDoc(doc(db, 'users', userId));
      toast.success('User deleted successfully');
    } catch (error) {
      toast.error('Error deleting user');
    }
  };
  

  const disableUser = async (userId, disabled) => {
    try {
      await updateDoc(doc(db, 'users', userId), { disabled: !disabled });
      toast.success('User disabled status updated successfully');
    } catch (error) {
      console.error('Error updating user disabled status:', error);
      toast.error('Error updating user disabled status');
    }
  };

  const updateUserRole = async (userId, role) => {
    try {
      await updateDoc(doc(db, 'users', userId), { role });
      toast.success('User role updated successfully');
    } catch (error) {
      console.error('Error updating user role:', error);
      toast.error('Error updating user role');
    }
  };

  return (
    <>
     <ToastContainer position="top-right" autoClose={5000} />

    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <h2 className="text-xl font-semibold text-gray-800 p-4">User List</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
           
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              First Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Last Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Role
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user, index) => (
            <tr key={user.id} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.firstName}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.lastName}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.role}</td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                <button
                  onClick={() => updateUserRole(user.id, 'Admin')}
                  className="text-white bg-blue-500 hover:bg-blue-700 py-1 px-3 rounded"
                >
                  Make Admin
                </button>
                <button
                  onClick={() => updateUserRole(user.id, 'User')}
                  className="text-white bg-green-500 hover:bg-green-700 py-1 px-3 rounded"
                >
                  Make User
                </button>
                <button
                  onClick={() => updateUserRole(user.id, 'Finance')}
                  className="text-white bg-yellow-500 hover:bg-yellow-700 py-1 px-3 rounded"
                >
                  Make Finance
                </button>
               
               
                <button
                  onClick={() => disableUser(user.id, user.disabled)}
                  className={`${
                    user.disabled ? 'bg-red-500' : 'bg-gray-500'
                  } hover:bg-red-700 text-white py-1 px-3 rounded`}
                >
                  {user.disabled ? 'Enable' : 'Disable'}
                </button>
                <button
                  onClick={() => deleteUser(user.id)}
                  className="text-white bg-red-500 hover:bg-red-700 py-1 px-3 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default UserList;

