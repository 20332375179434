import React from 'react';
import { convertStringToDate, convertTimestampToString, FormatStorage, calculateAccumulatedStorageFees } from '../Deceased/helpers';

const StorageTable = ({ allocations, formatCurrency, deceased }) => {
  
  const calculateDaysInStorage = (startDate, endDate) => {
    // Ensure endDate is at least the same as startDate to avoid negative days
    const adjustedEndDate = endDate >= startDate ? endDate : startDate;
    const durationInMs = adjustedEndDate.getTime() - startDate.getTime();
    const durationInDays = Math.ceil(durationInMs / (1000 * 60 * 60 * 24));
    // Adjust to not count the end date as a full day
    return durationInDays >= 1 ? durationInDays - 1 : 0;
  };

  const getCurrentDate = () => new Date();

  
  const accumulatedStorageFees = calculateAccumulatedStorageFees(deceased.allocations, deceased.exitDate);

  return (
    <div className="overflow-x-auto w-full mb-2">
      <table className="w-full text-sm lg:text-base whitespace-nowrap" cellSpacing="0">
        <thead className="bg-gray-50">
          <tr className="h-12 uppercase">
            <th className="hidden md:table-cell text-xs font-medium text-gray-500 ">Storage</th>
            <th className="text-xs text-center font-medium text-gray-500 ">Date Assigned</th>
            <th className="text-xs font-medium text-gray-500 ">Date UnAssigned</th>
            <th className="text-xs font-medium text-gray-500 ">Daily Rate</th>
            <th className="text-xs text-center font-medium text-gray-500 ">Days in Storage</th>
            <th className="text-xs font-medium text-gray-500 ">Cost</th>
          </tr>
        </thead>
        <tbody>
          {allocations.map((allocation, index) => {
            const startDate = convertStringToDate(allocation.dateAssigned);
            const endDate = allocation.dateUnassigned ? convertStringToDate(allocation.dateUnassigned) : getCurrentDate();
            const daysInStorage = calculateDaysInStorage(startDate, endDate);
            const cost = daysInStorage * allocation.storageFees;
            return (
              <tr key={index}>
                <td className="hidden md:table-cell text-sm text-gray-900">{FormatStorage(allocation.storageType)} {allocation.storageNumber}</td>
                <td className='text-sm text-center text-gray-900'>{convertTimestampToString(allocation.dateAssigned)}</td>
                <td className='text-sm text-center text-gray-900'>{allocation.dateUnassigned ? convertTimestampToString(allocation.dateUnassigned) : "N/A"}</td>
                <td className='text-sm text-gray-900'>{formatCurrency(allocation.storageFees)}</td>
                <td className='text-sm text-center text-gray-900'>{daysInStorage}</td>
                <td className='text-sm text-gray-900'>{formatCurrency(cost)}</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="5" className="text-right">Accumulated Storage Fees:</td>
            <td className='ml-2 pl-2 text-red-700 text-sm'>{formatCurrency(accumulatedStorageFees)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default StorageTable;
