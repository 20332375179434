import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchProduct, deleteProduct } from './productService';

const DeleteProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const productData = await fetchProduct(id);
      setProduct(productData);
    }

    fetchData();
  }, [id]);

  const handleDelete = async () => {
    try {
      await deleteProduct(id);
      navigate('/settings/products');
    } catch (error) {
      console.error('Error deleting product:', error.message);
    }
  };

  if (!product) return <div>Loading...</div>;

  return (
    <div>
      <h1>Delete Product</h1>
      <p>Are you sure you want to delete the following product?</p>
      {/* Render the product details */}
      {/* Add a delete button and an event handler */}
      <button onClick={handleDelete}>Delete</button>
    </div>
  );
};

export default DeleteProductPage;
