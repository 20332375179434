import { db } from '../../../firebase-config';

import { collection, query, where, getDocs,  doc, runTransaction } from 'firebase/firestore';

export async function deleteDeceasedRecords(deceasedId) {
  try {
    await runTransaction(db, async (transaction) => {
      // Delete deceased record and get allocations
      const deceasedDocRef = doc(db, 'deceased', deceasedId);
      const deceasedSnapshot = await transaction.get(deceasedDocRef);
      const deceasedData = deceasedSnapshot.data();

      if (!deceasedData) {
        throw new Error("Deceased record not found");
      }

      const allocations = deceasedData.allocations || [];

      // Process each allocation
      allocations.forEach(allocation => {
        const storageType = allocation.storageType; // e.g., 'vipStorage'
        const storageNumber = allocation.storageNumber; // e.g., 'V14'

        // Update the corresponding space to be unoccupied
        const storageSpaceRef = doc(db, 'storage', storageType, 'spaces', storageNumber);
        transaction.update(storageSpaceRef, { occupied: false, deceasedId: '' });
      });

      // Delete the deceased record
      transaction.delete(deceasedDocRef);

      // Delete invoices
      const invoicesQuery = query(collection(db, 'invoices'), where('deceasedId', '==', deceasedId));
      const invoicesSnapshot = await getDocs(invoicesQuery);
      invoicesSnapshot.forEach((invoiceDoc) => {
        transaction.delete(invoiceDoc.ref);
      });

      // Delete transactions
      const transactionsQuery = query(collection(db, 'transactions'), where('deceasedId', '==', deceasedId));
      const transactionsSnapshot = await getDocs(transactionsQuery);
      transactionsSnapshot.forEach((transactionDoc) => {
        transaction.delete(transactionDoc.ref);
      });
    });

    console.log('Deceased records deleted successfully');
  } catch (error) {
    console.error('Failed to delete deceased records:', error);
  }
}

