import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, deleteDoc} from 'firebase/firestore';
import { db } from '../../../firebase-config';

const OrphanedInvoices = () => {
  const [orphanedInvoices, setOrphanedInvoices] = useState([]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    try {
      const invoicesSnapshot = await getDocs(collection(db, 'invoices'));
      const invoices = invoicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      const orphaned = [];
      for (const invoice of invoices) {
        if (!invoice.deceasedId) {
          // Handle missing deceasedId
          console.error('Missing deceasedId for invoice:', invoice.id);
          continue; // Skip this iteration
        }
  
        console.log('Fetching deceased record for:', invoice.deceasedId); // Log the deceasedId
        const deceasedRef = doc(db, 'deceased', invoice.deceasedId);
        const deceasedSnap = await getDoc(deceasedRef);
  
        if (!deceasedSnap.exists()) {
          orphaned.push(invoice);
        }
      }
      setOrphanedInvoices(orphaned);
    } catch (error) {
      console.error("Error fetching invoices: ", error);
    }
  };
  

  const handleDeleteInvoice = async (invoiceId) => {
    try {
      await deleteDoc(doc(db, 'invoices', invoiceId));
      setOrphanedInvoices(prev => prev.filter(invoice => invoice.id !== invoiceId));
    } catch (error) {
      console.error('Error deleting invoice:', error);
    }
  };

  const formatDate = (date) => {
    return date ? date.toDate().toLocaleString() : 'N/A';
  };

  return (
    <div>
      <h2>Orphaned Invoices</h2>
      <table>
        <thead>
          <tr>
            <th className='px-2 py-1.5 text-sm'>Invoice ID</th>
            <th className='px-2 py-1.5 text-sm'>Deceased ID</th>
            <th className='px-2 py-1.5 text-sm'>Amount Paid</th>
            <th className='px-2 py-1.5 text-sm'>Invoice Date</th>
          </tr>
        </thead>
        <tbody>
          {orphanedInvoices.map((invoice, index) => (
            <tr key={index}>
              <td className='px-2 py-1.5 text-sm'>{invoice.id}</td>
              <td className='px-2 py-1.5 text-sm'>{invoice.deceasedId}</td>
              <td className='px-2 py-1.5 text-sm'>{invoice.amountPaid}</td>
              <td className='px-2 py-1.5 text-sm'>{formatDate(invoice.invoiceDate)}</td>
              <td>
                <button onClick={() => handleDeleteInvoice(invoice.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrphanedInvoices;