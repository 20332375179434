import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import '../Transactions/Spinner.css';
import { Link } from 'react-router-dom'
import BreadCrumb from '../BreadCrumb';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';
import { capitalizeFirstLetter } from '../Deceased/helpers';


const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: false },  
    { name: 'Occupany Details', to: '/settings/occupants', component: Link, current: true },     
  ]

const OccupancyTable = () => {
    const [storages, setStorages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [storageTypeFilter, setStorageTypeFilter] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [currentEvict, setCurrentEvict] = useState(null);
    const [message, setMessage] = useState('');
    const itemsPerPage = 10;

   

    


    const fetchStorageData = async () => {
        setIsLoading(true);
        try {
            const storageSnapshot = await getDocs(collection(db, 'storage'));
            const storageData = await Promise.all(storageSnapshot.docs.map(async (storageDoc) => {
                const spacesSnapshot = await getDocs(collection(storageDoc.ref, 'spaces'));
                const spacesData = await Promise.all(spacesSnapshot.docs.map(async (spaceDoc) => {
                    const spaceData = spaceDoc.data();
                    let deceasedName = 'Unoccupied';

                    if (spaceData.deceasedId) {
                        const deceasedDoc = await getDoc(doc(db, 'deceased', spaceData.deceasedId));
                        if (deceasedDoc.exists()) {
                            const deceasedData = deceasedDoc.data();

                            const fname = capitalizeFirstLetter(deceasedData.firstName);
                            const lname = capitalizeFirstLetter(deceasedData.lastName);

                            deceasedName =  `${fname} ${lname}`;
                        }
                    }

                    return { spaceId: spaceDoc.id, deceasedName };
                }));

                return { storageType: storageDoc.data().description, spaces: spacesData };
            }));

            setStorages(storageData);
        } catch (error) {
            console.error("Error fetching storage data:", error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchStorageData();
    }, []);


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

   

    const filteredStorages = storages
    .filter(storage => storageTypeFilter === 'All' || storage.storageType === storageTypeFilter);


    const currentItems = filteredStorages
        .slice(indexOfFirstItem, indexOfLastItem);
    const totalItems = filteredStorages.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleNext = () => {
        setCurrentPage(current => current < totalPages ? current + 1 : current);
    };

    const handlePrevious = () => {
        setCurrentPage(current => current > 1 ? current - 1 : current);
    };

    
   const handleEvict = async () => {
        if (currentEvict) {
            try {
                const { storageType, spaceId } = currentEvict;
            const spaceRef = doc(db, 'storage', storageType, 'spaces', spaceId);
            
            // Continue with eviction process
            await updateDoc(spaceRef, {
                deceasedId: '',
                occupied: false
            });
    
                setMessage(`Eviction successful for space ${spaceId} in ${storageType}`);
                setCurrentEvict(null);
                setShowModal(false);
                fetchStorageData();
            } catch (error) {
                console.error("Error during eviction:", error);
                setMessage(`Error during eviction: ${error.message}`);
            }
        }
    };
    

    const confirmEviction = (storageDescription, spaceId) => {
        let storageDocId = '';
    
        switch (storageDescription) {
            case 'General Storage':
                storageDocId = 'generalStorage';
                break;
            case 'Premium Storage':
                storageDocId = 'premiumStorage';
                break;
            case 'VIP Storage':
                storageDocId = 'vipStorage';
                break;
            default:
                console.error('Invalid storage description');
                return;
        }
    
        setCurrentEvict({ storageType: storageDocId, spaceId });
        setShowModal(true);
    };
    


    const allowedRoles = ["Admin"];
    const isAllowed = useRoleRestriction(allowedRoles);
  
    if (!isAllowed) {
      return < AccessDenied />
    }
   
    if (isLoading) {
        return (
            <div className="spinner-container">
                <h1 className='text-xl text-green-800 font-extrabold'>Retrieving Storage Details</h1>
                <div className="spinner"></div>
            </div>
        );
    }


    return (
        <>
          <BreadCrumb pages ={pages} />
        
          <div className="bg-gray-200">
                <div className="mx-auto max-w-7xl">
                    <div className="bg-white py-2">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-xl font-semibold text-black">Storage Occupancy</h1>
                                    <div className="mt-2">
                                        <label className="text-sm text-black mr-2">Filter by Storage Type: </label>
                                        <select value={storageTypeFilter} onChange={(e) => setStorageTypeFilter(e.target.value)} className="rounded-md bg-white p-2 text-black">
                                            <option value="All">All</option>
                                            {storages.map((storage, index) => (
                                                <option key={index} value={storage.storageType}>{storage.storageType}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 flow-root">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <table className="min-w-full divide-y divide-gray-700">
                                            <thead>
                                                <tr>
                                                    <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-black sm:pl-0">Storage Type</th>
                                                    <th className="px-3 py-2 text-left text-sm font-semibold text-black">Space ID</th>
                                                    <th className="px-3 py-2 text-left text-sm font-semibold text-black">Occupied By</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-800">
                                                {currentItems.map((storage) => (
                                                    storage.spaces.map((space, index) => (
                                                        <tr key={index}>
                                                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-black sm:pl-0">{storage.storageType}</td>
                                                            <td className="whitespace-nowrap px-3 py-2 text-sm text-black">{space.spaceId}</td>
                                                            <td className="whitespace-nowrap px-3 py-2 text-sm text-black">{space.deceasedName}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-black">
                                                            <button 
                                                               onClick={() => confirmEviction(storage.storageType, space.spaceId)}
                                                                className="rounded bg-red-500 px-2 py-1 text-xs font-semibold shadow-sm text-white hover:bg-red-400"
                                                            >
                                                                Evict
                                                            </button>
                                                        </td>
                                                        </tr>
                                                    ))
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                                <div className="flex justify-between flex-1 sm:hidden">
                                    <button onClick={handlePrevious} className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                        Previous
                                    </button>
                                    <button onClick={handleNext} className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                                        Next
                                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                                    <p className="text-sm text-gray-700">
                                        Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to <span className="font-medium">{indexOfLastItem}</span> of <span className="font-medium">{totalItems}</span> results
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {showModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                    <div className="bg-white p-4 rounded">
                        <h2 className="text-lg font-bold">Confirm Eviction</h2>
                        <p>Are you sure you want to evict this occupant?</p>
                        <div className="flex justify-end space-x-4 mt-4">
                            <button onClick={() => setShowModal(false)} className="bg-gray-500 px-4 py-2 rounded text-white">Cancel</button>
                            <button onClick={handleEvict} className="bg-red-500 px-4 py-2 rounded text-white">Evict</button>
                        </div>
                    </div>
                </div>
            )}
            {message && <div className="text-center my-4 text-sm font-semibold">{message}</div>}



        </>
    );
};

export default OccupancyTable;
