import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, updateDoc, getDoc, addDoc , query, where} from 'firebase/firestore';
import { db, functions } from '../../firebase-config';
import ApprovalModal from './ApprovalModal';
import { useUserAuth } from '../../context/UserAuthContext';
import { formatCurrency } from '../Deceased/helpers';
import { Link } from 'react-router-dom';
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import BreadCrumb from '../BreadCrumb';
import { httpsCallable } from 'firebase/functions';


const pages = [
    
    { name: 'Owners Center', to: '/settings', component: Link, current: false },
    { name: 'Commission Approval', to: '/settings/approve-pay', component: Link, current: true },
    ]

const DeceasedRecordList = () => {
    const [deceasedRecords, setDeceasedRecords] = useState([]);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [selectedDeceased, setSelectedDeceased] = useState(null);
    const [notification, setNotification] = useState("");    
    const { user } = useUserAuth();
    const currentUser = user.firstName + " " + user.lastName ;
          

    useEffect(() => {
        const q = query(collection(db, 'deceased'), where('commissionApproved', '==', false));
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const records = [];
            for (const document of querySnapshot.docs) {
                const data = document.data();
                if (data.referralAgentId) {
                    const agentRef = doc(db, 'referralAgents', data.referralAgentId);
                    const agentSnapshot = await getDoc(agentRef);

                    let agentData = { firstName: 'Unknown', lastName: '', agentType: 'Unknown' };
                    if (agentSnapshot.exists()) {
                        agentData = agentSnapshot.data();
                    }

                    const dateDeposited = data.dateDeposited || 'Unknown';
                    const exitDate = data.exitDate ? convertTimestampToDate(data.exitDate) : 'Unknown';

                    records.push({
                        ...data,
                        id: document.id,
                        referralAgentName: `${agentData.firstName} ${agentData.lastName}`.trim(),
                        agentType: agentData.agentType,
                        dateDeposited,
                        exitDate,
                        commissionAmount: data.commissionAmount || 0,
                    });
                }
            }
            setDeceasedRecords(records);
        });

        return () => unsubscribe();
    }, []);

    const convertTimestampToDate = (timestamp) => {
        if (timestamp?.toDate) {
            return timestamp.toDate().toLocaleDateString("en-US");
        } else {
            return 'Unknown';
        }
    };

       const handleOpenApprovalModal = (deceased) => {
        setSelectedDeceased(deceased);
        setShowApprovalModal(true);
    };

  
    const sendApprovalEmail = async (referralAgentEmail, deceasedName) => {
        const sendEmail = httpsCallable(functions, 'sendApprovalEmail');
        try {
            await sendEmail({ referralAgentEmail, deceasedName });
            console.log('Commission approval email sent');
            setNotification("Approval Confirmation Email Sent to Agent");
        } catch (error) {
            console.error('Error sending commission approval email:', error);
        }
    };

    const handleApprovalUpdate = async (deceasedId, actionData) => {
        const deceasedRef = doc(db, 'deceased', deceasedId);

        // Update the main approval status
        await updateDoc(deceasedRef, {
            commissionApproved: actionData.actionTaken === 'approve'
        });

        // Add a new entry to the approvalHistory subcollection
        const approvalHistoryRef = collection(db, `deceased/${deceasedId}/approvalHistory`);
        await addDoc(approvalHistoryRef, {
            ...actionData,
            actionBy: currentUser 
        });

        if (actionData.actionTaken === 'approve') {
            // Retrieve referral agent email and deceased name
            const deceasedRef = doc(db, 'deceased', deceasedId);
            const deceasedSnap = await getDoc(deceasedRef);
            if (!deceasedSnap.exists()) return;

            const deceasedData = deceasedSnap.data();
            const agentRef = doc(db, 'referralAgents', deceasedData.referralAgentId);
            const agentSnap = await getDoc(agentRef);
            if (!agentSnap.exists()) return;

            const referralAgentData = agentSnap.data();
            const referralAgentEmail = referralAgentData.email;
            const deceasedName = `${deceasedData.firstName} ${deceasedData.lastName}`;

            await sendApprovalEmail(referralAgentEmail, deceasedName);
        }

        // Update the local state to reflect changes
        setDeceasedRecords(deceasedRecords.map(record =>
            record.id === deceasedId
                ? { ...record, commissionApproved: actionData.actionTaken === 'approve' }
                : record
        ));
    };

    const clearNotification = () => {
        setNotification("");
    };


    const allowedRoles = ["Admin", "Finance"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return < AccessDenied />
  }

    return (
        <>

<BreadCrumb pages={pages} />

{notification && (
                <div className="my-4 p-4 bg-blue-100 text-blue-700 border border-blue-200 rounded">
                    {notification}
                    <button onClick={clearNotification} className="ml-2 underline">Dismiss</button>
                </div>
            )}

<ApprovalModal
                 isOpen={showApprovalModal}
                 onClose={() => setShowApprovalModal(false)}
                 deceasedRecord={selectedDeceased}
                 onSubmit={handleApprovalUpdate}
            />



        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Referral Agent Approval Center</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                        <th scope="col" className="px-6 py-3">Deceased Name</th>
                            <th scope="col" className="px-6 py-3">Date Deposited</th>
                            <th scope="col" className="px-6 py-3">Exit Date</th>
                            <th scope="col" className="px-6 py-3">Referral Agent</th>
                            <th scope="col" className="px-6 py-3">Agent Type</th>
                            <th scope="col" className="px-6 py-3">Commission Amount</th>
                            <th scope="col" className="px-6 py-3">Approval</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deceasedRecords.map((record) => (
                            <tr key={record.id} className="bg-white border-b">
                                <td className="px-6 py-4"><span>{`${record.firstName} ${record.lastName}`}</span></td>
                                <td className="px-6 py-4">{record.dateDeposited}</td>
                                <td className="px-6 py-4">{record.exitDate}</td>
                                <td className="px-6 py-4">{record.referralAgentName}</td>
                                <td className="px-6 py-4">{record.agentType}</td>
                                <td className="px-6 py-4">{formatCurrency(record.commissionAmount)}</td>
                                <td className="px-6 py-4">
                                    {record.commissionApproved ? (
                                        <span className="px-4 py-2 text-sm font-medium bg-green-500 text-white rounded">Approved</span>
                                    ) : (
                                        <button
                                            onClick={() => handleOpenApprovalModal(record)}
                                            className="px-4 py-2 text-sm font-medium bg-blue-500 text-white rounded hover:bg-blue-600"
                                        >
                                            Awaiting Approval
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        </>
    );
};

export default DeceasedRecordList;
