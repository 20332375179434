import jsPDF from "jspdf";
import "jspdf-autotable";

export const generatePDF = (invoiceId, date, customerName, customerAddress, customerEmail, invoiceItems, selectedDeceased) => {
  const doc = new jsPDF();

  

  // Logo and Invoice title
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("Elite Funeral Homes", 14, 20);
  doc.setFontSize(26);
  doc.text("INVOICE", 150, 20);

  // Invoice Details
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(`Invoice Number: ${invoiceId}`, 14, 40);
  doc.text(`Date: ${date}`, 14, 50);
 

  // Customer Details
  doc.text("Billed To:", 14, 80);
  doc.setFont("helvetica", "bold");
  doc.text(customerName, 14, 90);
  doc.setFont("helvetica", "normal");
  doc.text(customerAddress, 14, 100);
  doc.setFont("helvetica", "normal");
  doc.text(customerEmail, 14, 110);

  // Deceased Name (optional)
  if (selectedDeceased) {
    doc.setFont("helvetica", "bold");
    doc.text(`Deceased Name: ${selectedDeceased.firstName} ${selectedDeceased.lastName}`, 14, 120);
  }

  // Invoice Items Table
  doc.autoTable({
    startY: selectedDeceased ? 130 : 120,
    head: [["Description", "Quantity", "Unit Price", "Total"]],
    body: invoiceItems.map((item) => [
      item.description,
      item.quantity,
      item.price.toFixed(2),
      (item.price * item.quantity).toFixed(2),
    ]),
    styles: {
      fontSize: 12,
      fontStyle: "normal",
      lineColor: [0, 0, 0],
      lineWidth: 0.5,
    },
    headStyles: {
      fillColor: [128, 128, 128],
    },
    alternateRowStyles: {
      fillColor: [235, 235, 235],
    },
  });

  // Calculate grand total
  const grandTotal = invoiceItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Add grand total below the table
  const finalY = doc.previousAutoTable.finalY;
  doc.setFontSize(12);
  doc.setFont("arial", "bold");
  doc.text("Grand Total:", 130, finalY + 20);
  doc.text(`NGN ${grandTotal.toFixed(2)}`, 160, finalY + 20);

  doc.save(`invoice-${invoiceId}.pdf`);
};