import React, { useState, useEffect } from "react";
import { collection, doc, query, where, onSnapshot, getDoc, orderBy, limit, getDocs } from 'firebase/firestore';
import { Link } from "react-router-dom";
import { db } from "../../firebase-config";
import { ChevronRightIcon,ChevronLeftIcon, HomeIcon } from '@heroicons/react/20/solid';
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import { formatCurrency } from "../Deceased/helpers";

const pages = [
  { name: 'Finance', to: '/finance', component: Link, current: false },
  { name: 'Agents', to: '/finance/agent', component: Link, current: false },
  { name: 'All Commissions', to: '/finance/agent/all', component: Link, current: true },
  ]


  const AllCommissions = () => {
    const [commissions, setCommissions] = useState([]);
    const [filterStatus, setFilterStatus] = useState('all'); // 'all', 'approved', 'rejected'
    const [currentPage, setCurrentPage] = useState(1);
    const commissionsPerPage = 10; // Adjust as needed
  
    useEffect(() => {
      let q = query(collection(db, 'deceased'), where('referralAgentId', '!=', ''));
      if (filterStatus !== 'all') {
          q = query(collection(db, 'deceased'), where('referralAgentId', '!=', ''), where('commissionApproved', '==', filterStatus === 'Approved'));
      }

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const records = [];
          for (const document of querySnapshot.docs) {
              const data = document.data();
              if (data.referralAgentId) {
                  const agentRef = doc(db, 'referralAgents', data.referralAgentId);
                  const agentSnapshot = await getDoc(agentRef);
                  let agentName = 'Unknown Agent';
                  if (agentSnapshot.exists()) {
                      const agentData = agentSnapshot.data();
                      agentName = `${agentData.firstName} ${agentData.lastName}`;
                  }
                   // Fetch the most recent approval action from the approvalHistory subcollection
                const approvalHistoryRef = collection(db, `deceased/${document.id}/approvalHistory`);
                const approvalHistorySnapshot = await getDocs(query(approvalHistoryRef, orderBy('actionTimestamp', 'desc'), limit(1)));
                let latestApproval = { actionBy: 'Unknown', actionTimestamp: null, actionTaken: 'pending', rejectionReason: '' };
                if (!approvalHistorySnapshot.empty) {
                    latestApproval = approvalHistorySnapshot.docs[0].data();
                    latestApproval.actionTimestamp = latestApproval.actionTimestamp ? latestApproval.actionTimestamp.toDate().toLocaleString() : 'Pending';
                }


                records.push({ 
                  id: document.id, 
                  ...data, 
                  referralAgentName: agentName,
                  ...latestApproval, // Spread the latest approval action details
              });
          }
      }
      setCommissions(records);
  });

  return () => unsubscribe();
}, [filterStatus]);
  

   // Pagination Logic
    const indexOfLastCommission = currentPage * commissionsPerPage;
    const indexOfFirstCommission = indexOfLastCommission - commissionsPerPage;
    const currentCommissions = commissions.slice(indexOfFirstCommission, indexOfLastCommission);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const totalPages = Math.ceil(commissions.length / commissionsPerPage);

    const handlePageChange = (newPage) => {
      if (newPage >= 1 && newPage <= totalPages) {
          setCurrentPage(newPage);
      }
  };


  const allowedRoles = ["Admin", "Finance"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return < AccessDenied />
  }

  return (
    <>
   <nav className="flex gap-10 px-14 sm:px-14" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <Link to={page.to}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>

                        <div className="container mx-auto p-4">
                        <h2 className="text-2xl font-bold mb-4">All Commissions</h2>
                        <div className="mb-4">
                            <select
                                value={filterStatus}
                                onChange={e => setFilterStatus(e.target.value)}
                                className="p-2 border border-gray-300 rounded w-full sm:w-auto"
                            >
                                <option value="all">All</option>
                                <option value="Approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                              </div>
                             <div className="overflow-x-auto">
                             <table className="min-w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3">Referral Agent</th>
                                <th scope="col" className="px-6 py-3">Date Deposited</th>
                                <th scope="col" className="px-6 py-3">Commission Amount</th>
                                <th scope="col" className="px-6 py-3">Approved By</th>
                              <th scope="col" className="px-6 py-3">Date Approved</th>
                              <th scope="col" className="px-6 py-3">Action Taken</th>
                                {/* Additional headers if needed */}
                            </tr>
                        </thead>
                        <tbody>
                          {currentCommissions.map(commission => (
                            <tr key={commission.id} className="bg-white border-b">
                                  <td className="px-6 py-4">{commission.referralAgentName}</td>
                                  <td className="px-6 py-4">{commission.dateDeposited}</td>
                                  <td className="px-6 py-4">{formatCurrency(commission.commissionAmount)}</td>
                                  <td className="px-6 py-4">{commission.actionBy}</td>
                      <td className="px-6 py-4">{commission.actionTimestamp}</td>
                      <td className="px-6 py-4">{commission.actionTaken === 'approve' ? 'Approved' : 'Pending'}</td>
                                  {/* Additional data cells if needed */}
                        </tr>
                    ))}
                </tbody>
            </table>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{indexOfFirstCommission + 1}</span> to <span className="font-medium">{indexOfLastCommission}</span> of <span className="font-medium">{commissions.length}</span> results
                        </p>
                    </div>
                    <div>
                        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            {/* You can implement specific page number buttons here */}
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
};

export default AllCommissions;
