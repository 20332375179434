// DeceasedTable.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchDeceasedList, fetchNextOfKinList, fetchReferralAgentsList } from './deceasedDeletion/deceasedService';
import { computeStorageDays, computeDeceasedTotalCost } from '.././operations/computeStorageDays';
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import {formatDate} from '../../functions/formatDate';
import EditDeceased from '.././operations/EditDeceased'; 
import {capitalizeFirstLetter} from '../Deceased/helpers';
import {deleteDeceasedRecords} from './deceasedDeletion/deleteDeceasedRecords';
import { formatCurrency } from '../Deceased/helpers';
import BreadCrumb from '../BreadCrumb';


const pages = [
    { name: 'Home', to: '/', component: Link, current: false },
    { name: 'Owners Center', to: '/settings', component: Link, current: false },
    { name: 'Delete Deceased', to: '/settings/admin-delete', component: Link, current: true },
    ]

function PurgeDeceased() {
  const [deceasedList, setDeceasedList] = useState([]);
  const [nextOfKinList, setNextOfKinList] = useState([]);
  const [referralAgentsList, setReferralAgentsList] = useState([]);
  const [search, setSearch] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDeceasedId, setSelectedDeceasedId] = useState(null);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const allowedRoles = ["Admin"];
  const isAllowed = useRoleRestriction(allowedRoles);
  const [arrivalDateFilter, setArrivalDateFilter] = useState('');
  const [totalDaysFilter, setTotalDaysFilter] = useState('');
// States to Manage Deletion
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deceasedToDelete, setDeceasedToDelete] = useState(null);



  useEffect(() => {
    async function fetchData() {
      try {
       
        const { allDeceased, inStorageDeceased, inStorageCount } = await fetchDeceasedList();       
        setDeceasedList(allDeceased || []); 
      } catch (error) {
        console.error("Error fetching deceased data:", error);
        setDeceasedList([]); 
      }

      // Fetch next of kin and referral agents data
      const [nextOfKin, referralAgents] = await Promise.all([
        fetchNextOfKinList(),
        fetchReferralAgentsList(),
      ]);

      setNextOfKinList(nextOfKin || []); // Fallback to an empty array if undefined
      setReferralAgentsList(referralAgents || []); // Fallback to an empty array if undefined
    }
    
    fetchData();
  }, []);

  const filteredDeceasedList = deceasedList.filter((deceased) => {
    const lastName = deceased.lastName ? deceased.lastName.toLowerCase() : '';
    const firstName = deceased.firstName ? deceased.firstName.toLowerCase() : '';
    const status = deceased.status ? deceased.status.toLowerCase() : '';
    const arrivalDateMatch = arrivalDateFilter ? deceased.dateDeposited === arrivalDateFilter : true;
    const totalDaysInStorage = computeStorageDays(deceased.dateDeposited);
    const totalDaysMatch = totalDaysFilter ? totalDaysInStorage >= totalDaysFilter : true;
  
  return (
    (firstName.includes(search.toLowerCase()) || lastName.includes(search.toLowerCase())) && 
    (status === 'in storage') &&
    arrivalDateMatch && 
    totalDaysMatch
  );
});

  function handleSort(field) {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  }

  const sortedDeceasedList = filteredDeceasedList.sort((a, b) => {
    const fieldA = a[sortField];
    const fieldB = b[sortField];
  
    if (fieldA < fieldB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (fieldA > fieldB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });



  const refreshDeceasedList = async () => {
    try {
        const { allDeceased } = await fetchDeceasedList();
        setDeceasedList(allDeceased || []);
    } catch (error) {
        console.error("Error fetching deceased data:", error);
        setDeceasedList([]);
    }
};

  
  if (!isAllowed) {
    return < AccessDenied />
  }


 

  // Handle delete button click
  const handleDeleteClick = (deceasedId) => {
    setDeceasedToDelete(deceasedId);
    setShowDeleteConfirmation(true);
  };

  // Confirm deletion
  const confirmDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteDeceasedRecords(deceasedToDelete);
      await refreshDeceasedList(); // Refresh the list
      setIsDeleting(false);
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error('Error deleting deceased record:', error);
      setIsDeleting(false);
    }
  };

  const DeleteConfirmationModal = () => {
    if (!showDeleteConfirmation) return null;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-xl">
          <p className="text-lg">Are you sure you want to delete this record?</p>
          <div className="mt-4 flex justify-end space-x-2">
            <button 
              onClick={() => setShowDeleteConfirmation(false)} 
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button 
              onClick={confirmDelete} 
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        </div>
      </div>
    );
  };


  

  return (
    <>
   <BreadCrumb pages={pages} />

    <div className='flex flex-col justify-center items-center p-4'>
    <h4 className="text-2xl font-bold mb-2">Delete Deceased in Storage</h4>
      <input
        type="text"
        placeholder="Search using Name of Deceased"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="border border-gray-300 p-2 rounded-lg mb-2 w-64 text-sm text-center "
        />
        <p className='text-xs pb-2'>You can also sort the table by Name or Status</p>
     
         
      <div>
        <label>Filter by Arrival Date:</label>
        <input 
          type="date" 
          value={arrivalDateFilter} 
          onChange={(e) => setArrivalDateFilter(e.target.value)}
          className="border border-gray-300 p-2 rounded-lg mb-2 w-64 text-sm text-center"
        />
      </div>

    
      <DeleteConfirmationModal />

     <table className="table-auto border-collapse border border-gray-200">
        
        <thead className="bg-gray-50">
          <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Reg No
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={() => handleSort('firstName')}>
              First Name
            </th>
            
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={() => handleSort('lastName')}>
              Last Name
            </th>

            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Arrival
            </th>

            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Total Days in Storage
            </th>
                     
            
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Storage Cost Incurred
            </th>

          
               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
             Action
            </th>

          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 ">
          {filteredDeceasedList.map((deceased, index) => {
            const nextOfKin = nextOfKinList.find(
              (nextOfKin) => nextOfKin.deceasedId === deceased.id
            );
            const referralAgent = referralAgentsList.find(
              (referralAgent) => referralAgent.id === deceased.referralAgentId
            );
            const totalCost = computeDeceasedTotalCost(deceased); 
            
            return (
              <tr key={deceased.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 text-sm text-gray-900">{deceased.bodyNumber}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{capitalizeFirstLetter(deceased.firstName)}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{capitalizeFirstLetter(deceased.lastName)}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{formatDate(deceased.dateDeposited)}</td>

                <td className="px-14 py-4 text-sm text-gray-900">
                {computeStorageDays(deceased.dateDeposited)}
              </td>


                <td className="px-6 py-4 text-sm text-gray-900">{formatCurrency(totalCost)}</td> 
               
                           
                <td className="px-6 py-4 text-xs text-gray-900">
                  <div className="flex justify-between space-x-2">
                    <Link
                      to={`/deceased/${deceased.id}`}
                      className="bg-blue-500 hover:bg-blue-600 text-white text-xs px-2 py-1 rounded transition duration-300 ease-in-out flex-grow"
                    >
                      Details
                    </Link>

                  
                    <button
                  onClick={() => handleDeleteClick(deceased.id)}
                  className="bg-red-500 hover:bg-red-600 text-white text-xs px-2 py-1 rounded transition duration-300 ease-in-out"
                >
                  Delete
                </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>

    {isEditModalOpen && (
  <EditDeceased
    id={selectedDeceasedId} 
    onClose={() => setIsEditModalOpen(false)} 
    refreshDeceasedList={() => {
      // You can re-fetch the list here or add your refresh logic
      async function fetchData() {
        const deceased = await fetchDeceasedList();
        setDeceasedList(deceased);
      }
      fetchData();
    }}
  />
)}


    </>
  );
}

export default PurgeDeceased;

