import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchProductList, deleteProduct } from './productService';
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';
import { toast, ToastContainer } from 'react-toastify';

const ProductList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const fetchedProducts = await fetchProductList();
      setProducts(fetchedProducts);      
    };
    fetchProducts();    
  }, []);


  const handleDelete = async (productId) => {
    try {
      await deleteProduct(productId);
      setProducts(products.filter((product) => product.id !== productId));
      toast.success('Product deleted successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Error deleting product:', error.message);
      toast.error('Failed to delete product. Please try again!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };


  const allowedRoles = ["Admin", "Finance"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return < AccessDenied />
  }

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-semibold mb-4">Products</h2>
      <ToastContainer />
      <ul className="space-y-4">
        {products.map((product) => (
          <li key={product.id} className="border p-4 rounded-md">
            <h3 className="text-xl font-semibold">{product.description}</h3>
            <p>Cost Price: ${product.costPrice}</p>
            <p>Selling Price: ${product.sellingPrice}</p>
            <p>Quantity: {product.quantity}</p>
            <div className="mt-2">
              <Link
                to={`/products/update/${product.id}`}
                className="inline-block mr-2 py-1 px-3 bg-indigo-600 text-white text-sm rounded-md hover:bg-indigo-700"
              >
                Update
              </Link>
              <button
                onClick={() => handleDelete(product.id)}
                className="inline-block py-1 px-3 bg-red-600 text-white text-sm rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductList;
