import React from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { db } from "../../firebase-config";
import { addDoc, collection } from 'firebase/firestore';

Modal.setAppElement('#root'); // Set app element for accessibility purposes

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80%',
    overflowY: 'auto',
  },
};

const NextOfKinModal = ({ isOpen, onClose, onNewNextOfKin }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      // Add the next of kin data to the Firestore
      const docRef = await addDoc(collection(db, "nextOfKin"), data);

      // Add the new next of kin to the list in the parent component
      onNewNextOfKin({ id: docRef.id, ...data });

      // Close the modal
      onClose();
    } catch (error) {
      console.error("Error adding next of kin: ", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Next of Kin Modal"
    >
      <h2 className="text-lg font-semibold mb-4">Add New Next of Kin</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="sm:col-span-3">
        <label htmlFor="nextOfKinName" className="block text-sm font-medium">
          Name
        </label>
        <div className="mt-2">
        <input
          type="text"
          name="nextOfKinName"
          id='nextOfKinName'
          {...register('nextOfKinName', { required: true })} 
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
      </div>
      </div>

      <div>
        <label htmlFor="nextOfKinPhone" className="block text-sm font-medium">
          Phone
        </label>
        <input
          type="tel"
          name="nextOfKinPhone"
          id='nextOfKinPhone'
          {...register('nextOfKinPhone', { required: true })} 
           className="block w-full mt-1 p-2 border border-gray-300 rounded"
        />
      </div>

      <div>
        <label htmlFor="nextOfKinEmail" className="block text-sm font-medium">
          Email
        </label>
        <input
          type="email"
          name="nextOfKinEmail"
          required
          id='nextOfKinEmail'
          {...register('nextOfKinEmail', { required: true })} 
          className="block w-full mt-1 p-2 border border-gray-300 rounded"
        />
      </div>

      <div>
        <label htmlFor="nextOfKinAddress" className="block text-sm font-medium">
          Address
        </label>
        <input
          type="text"
          name="nextOfKinAddress"
          id='nextOfKinAddress'
          {...register('nextOfKinAddress', { required: true })} 
          className="block w-full mt-1 p-2 border border-gray-300 rounded"
        />
      </div>

      <div>
        <label htmlFor="nextOfKinRelationship" className="block text-sm font-medium">
          Relationship to deceased
        </label>
        <select
          name="nextOfKinRelationship"
          id='nextOfKinRelationship'
          {...register('nextOfKinRelationship', { required: true })}
          className="block w-full mt-1 p-2 border border-gray-300 rounded"
        >
          <option value="">Select Relationship</option>
          <option value="Spouse">Spouse</option>
          <option value="Father">Father</option>
          <option value="Mother">Mother</option>
          <option value="Cousin">Cousin</option>
          <option value="Uncle">Uncle</option>
          <option value="Aunt">Aunt</option>
          <option value="Nephew">Nephew</option>
          <option value="Niece">Niece</option>
          <option value="Son">Son</option>
          <option value="Daughter">Daughter</option>
          <option value="Brother">Brother</option>
          <option value="Sister">Sister</option>
          <option value="Third Party">Other</option>
          <option value="Govt Institution">Govt</option>
        </select>
      </div>

      <div>
        <label htmlFor="nextOfKinGender" className="block text-sm font-medium">
          Gender
        </label>
        <select
          name="nextOfKinGender"
          id='nextOfKinGender'
          {...register('nextOfKinGender', { required: true })}
          className="block w-full mt-1 p-2 border border-gray-300 rounded"
        >
          <option value="">Select gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </div>

        <button
          type="submit"
          className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-800"
        >
          Add Next of Kin
        </button>
        <button
          type="button"
          className="ml-4 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
          onClick={onClose}
        >
          Cancel
        </button>
      </form>
    </Modal>
  );
};

export default NextOfKinModal;
