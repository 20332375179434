import React from 'react'
import useRoleRestriction from "../context/useRoleRestriction";


const Audit = () => {
    const allowedRoles = ["Auditor"];
    const isAllowed = useRoleRestriction(allowedRoles);
  
    if (!isAllowed) {
      return <h1>You don't have the required permissions to view this page.</h1>;
    }
  
    return (
      <div>
       <h1> Welcome to Audit Page </h1>
      </div>
    );
  };
  
  export default Audit;