import React, { useState, useEffect } from 'react';
import { collection, getDocs,doc,getDoc, updateDoc,addDoc,arrayUnion } from 'firebase/firestore';
import {db} from '../../firebase-config';
import { v4 as uuidv4 } from 'uuid';
import AccessDenied from '../AccessDenied';
import useRoleRestriction from '../../context/useRoleRestriction';
import { Link } from 'react-router-dom';
import { generatePDF } from './generatePDF';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { formatCurrency } from '../Deceased/helpers';
import BreadCrumb from '../BreadCrumb';
import { toast, ToastContainer } from 'react-toastify';


const pages = [
{ name: 'Finance', to: '/finance', component: Link, current: false },
{ name: 'Generate Invoice', to: '/finance/invoice', component: Link, current: true },
]


const FinanceInvoice = () => {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerAddress, setCustomerAddress] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [paymentForDeceased, setPaymentForDeceased] = useState(false);
  const [selectedDeceased, setSelectedDeceased] = useState(null);
  const [deceasedList, setDeceasedList] = useState([]);
  const currentDate = new Date().toISOString().slice(0, 10);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [deceasedData, setDeceasedData] = useState([]);
  const [showModal, setShowModal] = useState(false);




  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };



  useEffect(() => {
    const fetchDeceased = async () => {
      const deceasedCol = collection(db, 'deceased');
      const deceasedSnapshot = await getDocs(deceasedCol);
      const deceasedData = deceasedSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setDeceasedList(deceasedData);
      setDeceasedData(deceasedData);
    };

    fetchDeceased();
  }, []);


  // Invoice fetching and matching
  const fetchInvoiceDetails = async (invoiceId) => {
    if (invoiceId) {
      const invoiceDocRef = doc(db, "invoices", invoiceId);
      const invoiceSnapshot = await getDoc(invoiceDocRef);

      if (invoiceSnapshot.exists()) {
         setInvoiceDetails({ ...invoiceSnapshot.data(), id: invoiceSnapshot.id });
      } else {
        setInvoiceDetails(null);
      }
    } else {
      setInvoiceDetails(null);
    }
  };

  // Invoke when Deceased is selected
  useEffect(() => {
    if (selectedDeceased && selectedDeceased.invoiceId) {
      fetchInvoiceDetails(selectedDeceased.invoiceId);
    } else {
      setInvoiceDetails(null);
    }
  }, [selectedDeceased]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    let invoiceTotal = 0;
    if (Array.isArray(invoiceItems) && invoiceItems.length) {
      invoiceTotal = invoiceItems.reduce(
        (total, item) => total + item.quantity * item.price,
        0
      );
    }
    const formattedInvoiceDate = formatDate(invoiceDate);
    const invoiceId = uuidv4();

    const invoiceData = {
      id: invoiceId,
      customerName,
      customerAddress,
      customerEmail,
      invoiceDate,
      invoiceItems,
      paymentForDeceased,
      deceasedId: selectedDeceased ? selectedDeceased.id : null,
      total: invoiceTotal,
      amountPaid: 0,
      status: 'unpaid',
    };



    try {
      const invoiceDocRef = await addDoc(collection(db, "invoices"), invoiceData);
      generatePDF(invoiceId, formattedInvoiceDate, customerName, customerAddress,customerEmail, invoiceItems, selectedDeceased);
      setShowModal(true)


      if (selectedDeceased) {
        const deceasedDocRef = doc(db, "deceased", selectedDeceased.id);
        await updateDoc(deceasedDocRef, {
          invoices: arrayUnion({
            invoiceId: invoiceDocRef.id,
            invoiceDate: new Date(invoiceDate).toISOString() // Storing the date as ISO string for consistency
          })
        });
        handleEmailInvoice(invoiceData);
      }
    } catch (error) {
      console.error("Error adding invoice: ", error);
      toast.error('Failed to create invoice!');
    }
};

  const handleEmailInvoice = async (invoiceData) => {
    if (!invoiceData) {
      console.error('No invoice details available');
      return;
    }

    const functions = getFunctions();
    const sendInvoiceEmail = httpsCallable(functions, 'sendInvoiceEmail');

    try {
      await sendInvoiceEmail({
        id : invoiceData.id,
        customerEmail: invoiceData.customerEmail,
        invoiceItems: invoiceData.invoiceItems,
        total: invoiceData.total,
        invoiceDate:  invoiceData.invoiceDate.toLocaleString(),
        paymentForDeceased: invoiceData.paymentForDeceased,
        customerName: invoiceData.customerName,
        customerAddress: invoiceData.customerAddress,
        deceasedId: invoiceData.deceasedId,
      });

      console.log("Invoice email sent successfully");

      // Optionally, show a success message to the user
      toast.success('Invoice email sent successfully!');
    } catch (error) {
      // Handle or display the error
      console.error('Failed to send invoice email:', error);
      toast.error('Failed to send invoice email:', error);
    }
  };


            const addItem = () => {
                setInvoiceItems([
                ...invoiceItems,
                {
                    id: uuidv4(),
                    description: '',
                    quantity: 1,
                    price: 0,
                },
                ]);
            };

            const updateItem = (id, field, value) => {
                setInvoiceItems(
                invoiceItems.map((item) =>
                    item.id === id ? { ...item, [field]: value } : item
                )
                );
            };

            const removeItem = (id) => {
                setInvoiceItems(invoiceItems.filter((item) => item.id !== id));
            };

            const calculateTotal = () => {
              return invoiceItems.reduce(
                (total, item) => total + item.quantity * item.price,
                0
              );
            };

            const handleDeceasedChange = (e) => {
              const deceasedId = e.target.value;
              const selectedDeceased = deceasedId ? deceasedData.find((d) => d.id === deceasedId) : null;
              setSelectedDeceased(selectedDeceased);

              if (selectedDeceased && selectedDeceased.invoiceId) {
                fetchInvoiceDetails(selectedDeceased.invoiceId);
              } else {
                setInvoiceDetails(null);
              }
            };


            const resetForm = () => {
              setCustomerName('');
              setCustomerAddress('');
              setCustomerEmail('');
              setInvoiceDate(new Date());
              setInvoiceItems([]);
              setSelectedDeceased(null);
            };


            useEffect(() => {
              if (showModal) {
                const timer = setTimeout(() => {
                  setShowModal(false);
                }, 5000);

                return () => {
                  clearTimeout(timer);
                };
              }
            }, [showModal]);


            const allowedRoles = ["Admin", "Finance", "Front-Desk"];
            const isAllowed = useRoleRestriction(allowedRoles);
            if (isAllowed === null) return <div>Loading...</div>;

            if (!isAllowed) {
              return < AccessDenied />
            }



  return (
      <>

    <BreadCrumb pages={pages} />

    <div className="bg-white p-6 rounded-lg shadow-lg w-full">

    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

    {
  showModal && (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Invoice generated successfully
                </h3>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

    <div className="flex">

                    <div className="w-1/2 pr-2 pl-4">
            <form onSubmit={handleSubmit} className="space-y-4">
            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                <label htmlFor="customerName" className="block text-sm font-medium text-gray-700">
                        Customer Name
                        </label>
                        <div className="mt-2">
                        <input
                        id="customerName"
                        name="customerName"
                        type="text"
                        autoComplete="off"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                            />
                        </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="customerEmail" className="block text-sm font-medium leading-6 text-gray-900">
                            Customer Email
                          </label>
                          <div className="mt-2">
                          <input
                            type="email"
                            name="customerEmail"
                            id="customerEmail"
                            value={customerEmail}
                            onChange={(e) => setCustomerEmail(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />    </div>

                        </div>
                        </div>

                        <div className="col-span-6">
                        <label htmlFor="customerAddress" className="block text-sm font-medium text-gray-700">
                            Customer Address
                          </label>
                          <input
                            type="text"
                            name="customerAddress"
                            id="customerAddress"
                            value={customerAddress}
                            onChange={(e) => setCustomerAddress(e.target.value)}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                       </div>



                    <div>
                         <label htmlFor="invoiceDate" className="block text-sm font-medium text-gray-700">
                            Invoice Date
                            </label>
                            <input
                            id="invoiceDate"
                            name="invoiceDate"
                            type="date"
                            max={currentDate}
                            required
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={invoiceDate.toISOString().substr(0,10)}
                            onChange={(e) => setInvoiceDate(new Date(e.target.value))} />
                     </div>

                     <div className="col-span-6">
                      <label htmlFor="paymentForDeceased" className="block text-sm font-medium text-gray-700">
                        Bill for Deceased?
                      </label>
                      <input
                        type="checkbox"
                        name="paymentForDeceased"
                        id="paymentForDeceased"
                        checked={paymentForDeceased}
                        onChange={(e) => setPaymentForDeceased(e.target.checked)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    {paymentForDeceased && (
                      <div className="col-span-6">
                        <label htmlFor="deceased" className="block text-sm font-medium text-gray-700">
                          Deceased
                        </label>
                        <select
                          id="deceased"
                          name="deceased"
                          value={selectedDeceased ? selectedDeceased.id : ""}
                          onChange={handleDeceasedChange}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                        {deceasedList && deceasedList.map((deceased) => (
                            deceased.status !== 'Checked Out' && (
                                <option key={deceased.id} value={deceased.id}>
                                    {deceased.firstName} {deceased.lastName}
                                </option>
                            )
                        ))}

                        </select>
                      </div>
                    )}


                    <div>
                    <label htmlFor="invoiceItems" className="block text-sm font-medium text-gray-700">
                    Invoice Items
                    </label>
                    {invoiceItems.map((item, index) => (
                    <div key={item.id} className="space-y-2">
                    <div className="flex items-center">
                    <select
                          id={`itemDescription-${index}`}
                          name={`itemDescription-${index}`}
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={item.description}
                          onChange={(e) =>
                            updateItem(item.id, 'description', e.target.value)
                          }
                          required
                        >
                          <option value="">Select Service</option>
                          <option value="Embalment">Embalment</option>
                          <option value="White Cloth">White Cloth</option>
                          <option value="Washing and Dressing">Washing and Dressing</option>
                          <option value="Tag">Tag</option>
                          <option value="Ambulance">Ambulance</option>
                          <option value="Storage Registration">Storage Registration</option>
                          <option value="Wound Dressing">Wound Dressing</option>
                          <option value="Autopsy">Autopsy</option>
                          <option value="Barbing and Shaving">Barbing and Shaving</option>
                          <option value="Make Up">Make Up</option>
                          <option value="Correction">Correction</option>
                          <option value="Space Rental">Space Rental</option>
                        </select>


                            <input
                            type="number"
                            name={`itemQuantity-${index}`}
                            className="mt-1 block w-20 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={item.quantity}
                            onChange={(e) =>
                            updateItem(item.id, 'quantity', Number(e.target.value))
                            }
                            placeholder="Qty"
                            required
                            />

                            <input
                            type="number"
                            name={`itemPrice-${index}`}
                            className="mt-1 block w-32 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={item.price}
                            onChange={(e) =>
                            updateItem(item.id, 'price', Number(e.target.value))
                            }
                            placeholder="Price"
                            required
                            />

                        <button
                        type="button"
                        className="ml-2 bg-red-500 text-white px-2 py-1 rounded"
                        onClick={() => removeItem(item.id)}
                        >
                        X
                        </button>
                        </div>
                        </div>
                        ))}
                        <button
                    type="button"
                    className="mt-2 bg-green-500 text-white px-2 py-1 rounded"
                    onClick={addItem}
                        >
                        Add Item
                        </button>
                        </div>


                  <div className="flex justify-end">
                        <button
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white-700 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={resetForm}
                                    >
                        Reset Form
                        </button>

                        <button
                        type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Generate Invoice
                        </button>




                        </div>

                        </form>
                        <p className="text-lg font-semibold mt-4">
                        Total Cost: ₦{calculateTotal().toFixed(2)}
                      </p>

                     </div>

                  <div className="w-1/2 pl-10 pr-2">
                      {paymentForDeceased && (
                      <div className="w-1/2 pl-4 pr-4">
                       <h2 className="text-base font-semibold leading-7 text-gray-900">Deceased Information</h2>

                   <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                   <div className="sm:col-span-3">
                    <label htmlFor="deceasedDateDeposited" className="block text-sm font-medium text-gray-700">Date Deposited</label>
                    <div className="mt-2">
                    <input type="text" id="deceasedDateDeposited" name="deceasedDateDeposited" value={formatDate(selectedDeceased?.dateDeposited) } readOnly className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                  </div>
                  </div>

                  {selectedDeceased && (
                  <div className="sm:col-span-3">
                  <label htmlFor="deceasedStatus" className="block text-sm font-medium text-gray-700">Status</label>
                  <div className="mt-2">
                  <input
                    type="text"
                    id="deceasedStatus"
                    name="deceasedStatus"
                    value={selectedDeceased.status}
                    readOnly
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                </div>
                )}
                </div>
                {selectedDeceased && (
                  <div className="mb-4">
                    <label htmlFor="morticianComments" className="block text-sm font-medium text-gray-700">Mortician Comments</label>
                    <textarea id="morticianComments" name="morticianComments" value={ selectedDeceased.morticianComments } readOnly rows="3" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"></textarea>
                  </div>

                  )}



                  <div className="mb-4">
                    <h4 className="block text-sm font-medium text-gray-700">Previous Invoices and Transactions</h4>
                    {invoiceDetails && (
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>

                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                      {invoiceDetails && invoiceDetails.invoiceItems && invoiceDetails.invoiceItems.map((item, index) => (
                          <tr key={index}>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">{ item.description }</td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">{ item.quantity }</td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">{formatCurrency(item.price) }</td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                              {
                                invoiceDetails.invoiceDate.toDate().toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric'
                                })
                              }
                            </td>

                          </tr>
                        ))}

                      </tbody>
                    </table>
                                )}

                </div>
                </div>
                      )}
                   </div>



                </div>
                </div>
                  </>

                );
            };

export default FinanceInvoice;
