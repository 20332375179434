import React from 'react';
import moment from 'moment';
import { formatCurrency } from '../Deceased/helpers';



const InvoiceHistory = ({deceased, invoices}) => {

  return (


    <div>
    <h2 className="text-md leading-6 py-3 font-medium text-gray-900">
            Invoice History
            </h2>
    <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                 </tr>
            </thead>

          
                                  
            <tbody className="bg-white divide-y divide-gray-200">
              {invoices.map((invoice, invoiceIndex) => (
                <tr key={invoice.id}>

                <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {invoice.invoiceDate 
                        ? moment(invoice.invoiceDate.toDate()).format('MMMM DD, YYYY')
                        : 'No date'}
                    </td>

                  <td className="px-4 py-4  text-sm whitespace-nowrap">{invoice.customerName}</td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">{formatCurrency(invoice.total)}</td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">{formatCurrency(invoice.amountPaid)}</td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">{invoice.status}</td>
                  
                </tr>
              ))}
            </tbody>
   </table>
    </div>
  )
}

export default InvoiceHistory