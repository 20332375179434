import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, query, where ,updateDoc} from 'firebase/firestore';
import { db } from '../../../firebase-config';

const PartPaymentInvoices = () => {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        // Query for invoices with 'part-payment' status
        const invoicesSnapshot = await getDocs(query(collection(db, 'invoices'), where('status', '==', 'part-payment')));
        const invoicesData = invoicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        for (const invoice of invoicesData) {
          const deceasedRef = doc(db, 'deceased', invoice.deceasedId);
          const deceasedSnap = await getDoc(deceasedRef);

          if (deceasedSnap.exists() && deceasedSnap.data().status === 'Checked Out') {
            // Add to invoices if deceased status is 'Checked Out'
            setInvoices(prev => [...prev, { ...invoice, deceasedData: deceasedSnap.data() }]);
          }
        }
      } catch (error) {
        console.error("Error fetching invoices: ", error);
      }
    };

    fetchInvoices();
  }, []);


  const updateInvoiceStatus = async (invoiceId) => {
    try {
      const invoiceRef = doc(db, 'invoices', invoiceId);
      await updateDoc(invoiceRef, { status: 'fully paid' });
  
      // Update local state to reflect the change
      setInvoices(prevInvoices => prevInvoices.map(inv => {
        if (inv.id === invoiceId) {
          return { ...inv, status: 'fully paid' };
        }
        return inv;
      }));
    } catch (error) {
      console.error('Error updating invoice status:', error);
    }
  };

  return (
    <div>
      <h2>Part-Payment Invoices for Checked Out Deceased</h2>
      <table>
        <thead>
          <tr className='px-2 py-1.5 text-sm uppercase'>
            <th className='px-2 py-2 text-sm items-center'>Invoice ID</th>
            <th className='px-2 py-2 text-sm items-center'>Deceased ID</th>
            <th className='px-2 py-2 text-sm items-center'>Customer</th>
            <th className='px-2 py-2 text-sm items-center'>Total</th>
            <th className='px-2 py-2 text-sm items-center'>Amount Paid</th>
            <th className='px-2 py-2 text-sm items-center'>Invoice Date</th>
          </tr>
        </thead>
        <tbody className='px-2 py-2 text-sm items-center'>
          {invoices.map((invoice, index) => (
            <tr key={index}>
              <td className='px-2 py-2 text-sm items-center'>{invoice.id}</td>
              <td className='px-2 py-2 text-sm items-center'>{invoice.deceasedId}</td>
              <td className='px-2 py-2 text-sm items-center'>{invoice.customerName}</td>
               <td className='px-2 py-2 text-sm items-center'>{invoice.total}</td>
              <td className='px-2 py-2 text-sm items-center'>{invoice.amountPaid}</td>
              <td className='px-2 py-2 text-sm items-center'>{invoice.invoiceDate.toDate().toLocaleString()}</td>
              <td>
                <button 
                  onClick={() => updateInvoiceStatus(invoice.id)} 
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Mark as Fully Paid
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartPaymentInvoices;
