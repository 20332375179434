import React, { useState } from 'react';
import Modal from 'react-modal';


const RefundModal = ({ isOpen, onRequestClose, onRefund, deceased, nextOfKin, errorMessage, onClose }) => {
  const [amountToRefund, setAmountToRefund] = useState('');
  const [refundReason, setRefundReason] = useState('');
  
  if (!nextOfKin) {
    return null;  
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    onRefund({ amountToRefund, refundReason });
    onRequestClose();
  };

  const handleClose = () => {
    if(onRequestClose) onRequestClose();
    if(onClose) onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Refund Modal"
      className="relative flex flex-col items-center justify-center p-4 bg-white rounded-xl w-full max-w-3xl mx-auto mt-4 sm:mt-4"
    >
      <h2 className="text-lg font-semibold text-center text-gray-800 mb-1">
        Refund Excess Charges for the Late {deceased?.firstName}{" "}{deceased?.lastName.toUpperCase()} <br/>
         to {nextOfKin.nextOfKinName}  
      </h2>
        <p className='text-xs text-red-600 pb-4 '> Email notification will be sent to {nextOfKin.nextOfKinEmail}</p>

      <div className="min-w-0 flex-1">
      <form className="space-y-6 relative" onSubmit={handleSubmit}>

                <div className="space-y-2">
                 <label htmlFor="amountReceived" className="block text-sm font-medium leading-5 text-gray-700">Amount to Refund</label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                     <span className="text-gray-500 sm:text-sm">₦</span>
                     </div>
                    <input
                    type="number"
                    min="0"
                    step="0.01"
                    name="amountToRefund"
                    id="amountToRefund"
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                    value={amountToRefund}
                    onChange={(e) => setAmountToRefund(e.target.value)}   
                    required          
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span className="text-gray-500 sm:text-sm" id="price-currency">
                    NGN
                </span>
                  </div>             
                </div>
                </div>
               
                
        
          <label htmlFor="refundReason" className="block text-sm font-medium text-gray-700">
            Reason for Refund
          </label>
          <textarea
           rows={4}
            name="refundReason"
            id="refundReason"
            required
            value={refundReason}
            onChange={(e) => setRefundReason(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
            placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                      
                      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}


        <button
          type="submit"
          className="w-full text-sm sm:w-auto px-4 py-2 text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 rounded"
        >
          Refund
        </button>

        <button
        onClick={handleClose}
        className="w-full ml-8 text-sm sm:w-auto px-4 py-2 text-white bg-teal-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded"
        >
        Close
      </button>   



      </form>
      </div>
    </Modal>
  );
};

export default RefundModal;
