// reportingUtils.js

export const groupByWeek = (data) => {
    const weekGroupedData = {};
    data.forEach(item => {
        const weekYear = getWeekYearString(item.assignedAt);
        if (!weekGroupedData[weekYear]) {
            weekGroupedData[weekYear] = [];
        }
        weekGroupedData[weekYear].push(item);
    });
    return weekGroupedData;
};


export const groupByMonth = (data) => {
    const monthGroupedData = {};
    data.forEach(item => {
        // Format the date as 'YYYY-MM'
        const monthYear = item.assignedAt.getFullYear() + "-" + (item.assignedAt.getMonth() + 1).toString().padStart(2, '0');
        if (!monthGroupedData[monthYear]) {
            monthGroupedData[monthYear] = [];
        }
        monthGroupedData[monthYear].push(item);
    });
    return monthGroupedData;
};





const getWeekYearString = (date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - startOfYear) / 86400000;
    return `${date.getFullYear()}-W${Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7)}`;
};

export const getMonthYearString = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
};


export const generateColor = (storageType) => {
    const colors = {
        'VIP Storage': 'rgba(255, 159, 64, 0.7)',      // Orange
        'General Storage': 'rgba(75, 192, 192, 0.7)',  // Teal
        'Premium Storage': 'rgba(153, 102, 255, 0.7)'  // Purple
    };
    return colors[storageType] || 'rgba(201, 203, 207, 0.7)'; // Default color
};



export const formatMonthLabel = (monthYear) => {
    const [year, month] = monthYear.split('-');
    const date = new Date(year, month - 1);
    const monthName = date.toLocaleString('default', { month: 'long' });
    return `${monthName} ${year}`;
};






export const getWeekOfMonth = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDayOfWeek = firstDayOfMonth.getDay();
    const offsetDate = date.getDate() + firstDayOfWeek - 1;
    return Math.floor(offsetDate / 7) + 1;
};