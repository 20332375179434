import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth, db } from "../../firebase-config";
import { doc, setDoc} from "firebase/firestore";
import useRoleRestriction from "../../context/useRoleRestriction";

const FormField = ({ id, label, type, value, onChange, options }) => {
  return (
    <div className="sm:col-span-6 md:col-span-3">
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        {type === 'select' ? (
          <select
            id={id}
            name={id}
            value={value}
            onChange={onChange}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value="" disabled> Select {label} </option>
            {options.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            id={id}
            name={id}
            value={value}
            onChange={onChange}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        )}
      </div>
    </div>
  );
};

export default function AddUser () {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [role, setRole] = useState("");
  const [submitStatus, setSubmitStatus] = useState(null);
  const allowedRoles = ["Admin"];
  const isAllowed = useRoleRestriction(allowedRoles);

  if (!isAllowed) {
    return <h1>You don't have the required permissions to view this page.</h1>;
  }

  
  async function setUserRole(uid, role) {
    console.log(`Setting user role for ${uid} to ${role}`);
    const userRef = doc(db, "users", uid);
    await setDoc(userRef, { role }, { merge: true });
  }
  
 
  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;
  
      // Create a new document with user data in Firestore
      const userRef = doc(db, "users", uid);
      await setDoc(userRef, {
        firstName: firstName,
        lastName: lastName,
      });
  
      // Call setUserRole to set the user role in Firestore
      await setUserRole(uid, role);
  
      toast.success("User created successfully!");
      setSubmitStatus("success");
    } catch (error) {
      console.log("Error creating user: ", error);
      toast.error("Error Creating User");
      setSubmitStatus("error");
      setError(error.message);
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      <div className="bg-white shadow overflow-hidden sm:rounded-md w-auto p-2">
        <form onSubmit={handleSignup}>
          <div className="space-y-8">
            <div className="border-b border-gray-900/10 pb-8 py-5">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Employee Provisioning</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">Use permanent address where the user can receive mail.</p>

              <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                <FormField id="firstName" label="First Name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <FormField id="lastName" label="Last Name" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                <FormField id="email" label="Email Address" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <FormField id="password" label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <FormField id="role" label="Role" type="select" value={role} onChange={(e) => setRole(e.target.value)} options={['Admin', 'Finance', 'User', 'Auditor', 'Mortician', 'Front-Desk']} />
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button type="reset" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
            <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Create User</button>
          </div>
        </form>

              {submitStatus === "success" && (
              <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-auto max-w-md mx-auto my-6">
                  <div className="bg-white rounded-lg shadow-lg">
                    <div className="px-6 py-4">
                      <div className="text-lg font-medium text-green-600">Success!</div>
                      <div className="mt-4 text-gray-600">User was created successfully.</div>
                    </div>
                    <div className="flex justify-end px-6 py-4">
                      <button
                        className="text-sm font-medium text-gray-600 hover:text-gray-900 focus:outline-none"
                        onClick={() => setSubmitStatus(null)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {submitStatus === "error" && (
              <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-auto max-w-md mx-auto my-6">
                  <div className="bg-white rounded-lg shadow-lg">
                    <div className="px-6 py-4">
                      <div className="text-lg font-medium text-red-600">Error!</div>
                      <div className="mt-4 text-gray-600">There was an error submitting your data. Please try again later.</div>
                    </div>
                    <div className="flex justify-end px-6 py-4">
                      <button
                        className="text-sm font-medium text-gray-600 hover:text-gray-900 focus:outline-none"
                        onClick={() => setSubmitStatus(null)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
       

      </div>
    </>
  );
};














