import React from 'react';
import { calculateDaysInStorage,convertTimestampToString, calculateAccumulatedStorageFees, formatCurrency, convertStringToDate, getCurrentDate, FormatStorage} from '../Deceased/helpers';

const RenderStorageAllocation = ({ deceased}) => {

    if (!deceased.allocations || deceased.allocations.length === 0) {
        return <p>No storage allocations found.</p>;
    }
    const grandTotal = calculateAccumulatedStorageFees(deceased.allocations, deceased.exitDate);



    return (
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-1 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Storage Type
              </th>
              <th className="px-1 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date Assigned
              </th>
              <th className="px-1 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date UnAssigned
              </th>
              
              <th className="px-1 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Daily Rate
              </th>
              <th className="px-1 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Days in Storage
              </th>
              <th className="px-1 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Cost
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          {deceased.allocations.map((allocation, index) => {
          const startDate = new Date(allocation.dateAssigned.seconds * 1000);
          const endDate = allocation.dateUnassigned ? new Date(allocation.dateUnassigned.seconds * 1000) : new Date(); // Use deceased.exitDate or current date if unassigned
          const daysInStorage = calculateDaysInStorage(startDate, endDate);
          const cost = daysInStorage * allocation.storageFees;
          
       
       
              return (
                <tr key={index}>
                  <td className="px-1 py-4 whitespace-nowrap">
                    <div className="text-sm text-center text-gray-900">
                    {FormatStorage(allocation.storageType)  || 'N/A'}  {allocation.storageNumber}
                      </div>
                  </td>
                  <td className="px-1 py-4 whitespace-nowrap">
                    <div className="text-xs text-center text-gray-900">{convertTimestampToString(allocation.dateAssigned)}</div>
                  </td>

                  <td className="px-1 py-4 whitespace-nowrap">
                    <div className="text-xs text-center text-gray-900">{convertTimestampToString(allocation.dateUnassigned)}</div>
                  </td>

                  
                  <td className="px-1 py-4 whitespace-nowrap">
                    <div className="text-xs text-center text-gray-900">{formatCurrency(allocation.storageFees)}</div>
                  </td>
                  <td className="px-1 py-4 whitespace-nowrap">
                    <div className="text-xs text-center text-gray-900">{daysInStorage}</div>
                  </td>
                  <td className="px-1 py-4 whitespace-nowrap">
                    <div className="text-xs text-center text-gray-900">{formatCurrency(cost)}</div>
                  </td>
                </tr>
              );
            })}
            <tr className="font-bold">
              <td className="px-6 py-4 whitespace-nowrap">Storage Rent Due</td>
              <td className="px-6 py-4 whitespace-nowrap"></td>
              <td className="px-6 py-4 whitespace-nowrap"></td>
              <td className="px-6 py-4 whitespace-nowrap"></td>
              <td className="px-6 py-4 text-red-400 whitespace-nowrap">{formatCurrency(grandTotal)}</td>
            </tr>
          </tbody>
        </table>
      );

     
}

export default RenderStorageAllocation