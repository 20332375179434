import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, where, getFirestore } from 'firebase/firestore';
import app from '../../firebase-config';

const PaymentList = () => {
  const { deceasedId } = useParams(); // Retrieve the deceased person's ID from the URL parameters
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchPayments = async () => {
      const paymentQuery = query(
        collection(getFirestore(app), 'payments'),
        where('deceasedId', '==', deceasedId)
      );
      const paymentSnapshot = await getDocs(paymentQuery);
      const paymentData = paymentSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPayments(paymentData);
    };

    fetchPayments();
  }, [deceasedId]);

  return (
    <div>
      <h3 className="text-xl font-bold mb-4">Payment List</h3>
      <ul>
        {payments.map((payment) => (
          <li key={payment.id} className="border-b border-gray-300 py-4">
            <p>Payment ID: {payment.id}</p>
            <p>Next of Kin ID: {payment.nextOfKinId}</p>
            <p>Deceased ID: {payment.deceasedId}</p>
            <p>Amount: {payment.amount}</p>
            <p>Payment Date: {payment.paymentDate}</p>
            <p>Payment Type: {payment.paymentType}</p>
            <p>Status: {payment.status}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PaymentList;
