import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where} from 'firebase/firestore';
import { db } from '../../firebase-config';
import { Link } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { formatCurrency } from '../Deceased/helpers';
import useRoleRestriction from "../../context/useRoleRestriction";
import { MoonLoader } from 'react-spinners';
import AccessDenied from '../AccessDenied';
import BreadCrumb from '../BreadCrumb';
import { ToastContainer, toast } from 'react-toastify';
import Papa from 'papaparse';

const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: false },  
    { name: 'Financial Reports', to: '/settings/finance', component: Link, current: false },
    { name: 'Detailed Monthly Income', to: '/settings/finance/detailed-income', component: Link, current: true },     
  ]
  
  const DetailedMonthlyIncome = () => {
    const [transactionsData, setTransactionsData] = useState([]);
    const [loading, setLoading] = useState(false);
     const allowedRoles = ["Admin"];
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedSourceOfIncome, setSelectedSourceOfIncome] = useState('');
    const isAllowed = useRoleRestriction(allowedRoles);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        fetchTransactions();
      }, []);
  
  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, 'transactions'), where('transactionType', '==', 'income'));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        dateIncurred: doc.data().dateIncurred.toDate()
      }));
      setTransactionsData(data);
    } catch (error) {
      toast.error("Error fetching transactions:", error);
    }
    setLoading(false);
  };
  
  const handleGetReport = async () => {
    const filteredData = transactionsData.filter(transaction => {
      const transactionMonth = transaction.dateIncurred.toLocaleString('default', { month: 'long' });
      const transactionYear = transaction.dateIncurred.getFullYear();
      const currentYear = new Date().getFullYear();
      return transactionMonth === selectedMonth && transactionYear === currentYear && transaction.sourceOfIncome === selectedSourceOfIncome;
    });
    if (searchText.trim() !== '') {
        filteredData = filteredData.filter(transaction => {
          return transaction.text.toLowerCase().includes(searchText.toLowerCase()) || transaction.amount.toString().includes(searchText);
        });
      }
      const total = filteredData.reduce((acc, transaction) => acc + transaction.amount, 0);
      setFilteredTransactions(filteredData);
      setTotalAmount(total);
    };
    
    function handleSort(field) {
        const sortedData = [...filteredTransactions];
        sortedData.sort((a, b) => {
          let aValue = a[field];
          let bValue = b[field];
      
          // Handle undefined values gracefully
          if (aValue === undefined || bValue === undefined) {
            return 0; // Consider how you want to treat undefined values in your sorting logic
          }
      
          if (field === 'amount') {
            // Numeric sorting
            return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
          } else if (field === 'dateIncurred') {
            // Date sorting
            return sortOrder === 'asc' ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
          } else {
            // Case-insensitive string sorting for other fields
            aValue = aValue.toString().toLowerCase();
            bValue = bValue.toString().toLowerCase();
            if (aValue < bValue) {
              return sortOrder === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
              return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
          }
        });
      
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setFilteredTransactions(sortedData);
      }
      
  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTransactions.slice(indexOfFirstItem, indexOfLastItem);

  function handlePreviousPage() {
    setCurrentPage(currentPage => Math.max(1, currentPage - 1));
  }
  
  function handleNextPage() {
    setCurrentPage(currentPage => Math.min(totalPages, currentPage + 1));
  }

  const downloadCSV = () => {
    const csv = Papa.unparse(filteredTransactions.map(transaction => ({
      Date: transaction.dateIncurred.toLocaleDateString(),
      Amount: transaction.amount,
      SourceOfIncome: transaction.sourceOfIncome,
      Description: transaction.text,
    })));

    // Create a Blob for the CSV content
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `DetailedMonthlyIncome-${new Date().toLocaleDateString()}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

     
  if (!isAllowed) {
    return < AccessDenied />
  }
    
      return (
        <>
        <BreadCrumb pages={pages}/>

        <div className="container mx-auto p-4">
            <ToastContainer />
            <h4 className="text-xl md:text-xl lg:text-2xl font-bold text-gray-900 mb-2">Detailed Monthly Income</h4>

          <div className="mb-4">
          {/* <input
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)  }}
            className="input input-bordered w-2/5 mr-2 py-1.5 rounded text-sm max-w-xs px-2"
          /> */}

            <select 
              className="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={selectedMonth} 
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              {Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('default', { month: 'long' }))
                .map(month => <option key={month} value={month}>{month}</option>)}
            </select>
            
            <select 
              className="ml-4 mr-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={selectedSourceOfIncome} 
              onChange={(e) => setSelectedSourceOfIncome(e.target.value)}
            >
              <option value="">Select Source of Income</option>
             
              <option value="POS-MoniePoint">POS-MoniePoint</option>
              <option value="POS-NOMBA">POS-NOMBA</option>
              <option value="Cash">Cash</option>
            </select>
    
            <button 
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleGetReport}
            >
              Get Data
            </button>
          </div>
    
          {loading ? (
        <div className="flex justify-center items-center">
          <MoonLoader color="#000000" />
        </div>
      ) : (
        <>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"onClick={() => handleSort('dateIncurred')} >Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"  onClick={() => handleSort('amount')}>Amount</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Net Income via {selectedSourceOfIncome} : <span className='text-sm text-green-700'> {formatCurrency(totalAmount)} </span></th>
                  <button 
            className="bg-green-500 text-xs hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            onClick={downloadCSV}
          >
            Export to CSV
          </button>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentItems.map((transaction, index) => (
                  <tr key={index}>
                    <td className="px-6 py-1.5 whitespace-nowrap text-sm text-gray-900">{transaction.dateIncurred.toLocaleDateString()}</td>
                    <td className="px-6 py-1.5 whitespace-nowrap text-sm text-gray-900">{formatCurrency(transaction.amount)}</td>
                    <td className="px-6 py-1.5 whitespace-nowrap text-sm text-gray-900">{transaction.text}</td>
                  </tr>
                ))}
              </tbody>
            </table>

                  <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to <span className="font-medium">{Math.min(indexOfLastItem, filteredTransactions.length)}</span> of{' '}
            <span className="font-medium">{filteredTransactions.length}</span> results
          </p>
        </div>
        {/* Dynamic Pagination Buttons */}
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50"
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </button>
            
            {/* Dynamically generate page buttons based on totalPages */}
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                aria-current={currentPage === index + 1 ? "page" : undefined}
                onClick={() => setCurrentPage(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${currentPage === index + 1 ? "z-10 bg-indigo-600 text-white" : "text-gray-700 bg-white hover:bg-gray-50"}`}
              >
                {index + 1}
              </button>
            ))}

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50"
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </button>
          </nav>
        </div>
      </div>
                </div>
          

</>
      )}
        </div>
        </>
      );
    };
    
    export default DetailedMonthlyIncome;