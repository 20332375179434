// deceasedService.js
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';

export const fetchDeceasedList = async () => {
  const deceasedList = [];
  const deceasedQuery = query(collection(db, 'deceased'));
  const querySnapshot = await getDocs(deceasedQuery);

  querySnapshot.forEach((doc) => {
    deceasedList.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return deceasedList;
};
