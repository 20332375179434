import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { capitalizeFirstLetter } from '.././Deceased/helpers';
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';
import { Link } from 'react-router-dom';
import '.././Transactions/Spinner.css';
import { ConfirmModal } from './ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BreadCrumb from '../BreadCrumb';


const pages = [
       { name: 'Owners Center', to: '/settings', component: Link, current: false },
    { name: 'Delete Transactions', to: '/settings/admin-transactions', component: Link, current: true },
    ]



function AdminTransactions() {
    const [transactions, setTransactions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortField, setSortField] = useState('date');
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);


    const enrichTransactionsWithDeceasedNames = useCallback(async (transactions) => {
        const deceasedIds = [...new Set(transactions.filter(t => t.text.includes('Deceased'))
            .map(t => t.text.split(' ').pop()))];
        const deceasedData = await Promise.all(deceasedIds.map(fetchDeceasedData));
        const deceasedMap = deceasedIds.reduce((acc, id, index) => ({ ...acc, [id]: deceasedData[index] }), {});

        const transactionsWithNames = transactions.map(t => {
            if (t.text.includes('Deceased')) {
                const deceasedId = t.text.split(' ').pop();
                const deceased = deceasedMap[deceasedId];
                if (deceased) {
                    t.text = t.text.replace(deceasedId, `${deceased.firstName} ${deceased.lastName}`);
                }
            }
            return t;
        });

        setTransactions(transactionsWithNames);
    }, []);

    useEffect(() => {
        const fetchTransactions = async () => {
            setIsLoading(true);
            const querySnapshot = await getDocs(collection(db, 'transactions'));
            const fetchedTransactions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            await enrichTransactionsWithDeceasedNames(fetchedTransactions);
            setIsLoading(false);
        };
    
        fetchTransactions();
    }, [enrichTransactionsWithDeceasedNames]);

   

    const fetchDeceasedData = async (deceasedId) => {
        if (!deceasedId) {
            return null; // or handle this case according to your logic
        }
    
        const deceasedDoc = await getDoc(doc(db, 'deceased', deceasedId));
        return deceasedDoc.exists() ? deceasedDoc.data() : null;
    };
    

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        const sortedTransactions = transactions.slice().sort((a, b) => {
            let result;
            if (sortField === 'date') {
                result = sortOrder === 'asc' 
                    ? b.dateIncurred.toDate() - a.dateIncurred.toDate() 
                    : a.dateIncurred.toDate() - b.dateIncurred.toDate();
            } else if (sortField === 'transactionType') {
                result = sortOrder === 'asc'
                    ? a.transactionType.localeCompare(b.transactionType)
                    : b.transactionType.localeCompare(a.transactionType);
            }
            return result; // Ensure the sorting function returns a value
        });
        setTransactions(sortedTransactions);
    };
    

    const handleSortFieldChange = (field) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder('asc'); // Reset sort order when changing field
        } else {
            toggleSortOrder(); // Otherwise just toggle the sort order
        }
    };
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredTransactions = transactions.filter(
        transaction => transaction.text.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredTransactions.length / pageSize);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const paginatedTransactions = filteredTransactions.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const formatTimestamp = (timestamp) => {
        return timestamp.toDate().toLocaleString();
    };

    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        let pages = Array.from({ length: totalPages }, (_, i) => i + 1);

       

        return (
            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <button
                    onClick={() => onPageChange(Math.max(1, currentPage - 1))}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <button
                    onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{(currentPage - 1) * pageSize + 1}</span> to <span className="font-medium">{Math.min(currentPage * pageSize, filteredTransactions.length)}</span> of{' '}
                        <span className="font-medium">{filteredTransactions.length}</span> results
                    </p>
                </div>
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    {pages.map(page => (
                        <button
                            key={page}
                            onClick={() => onPageChange(page)}
                            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${currentPage === page ? 'bg-indigo-600 text-white' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'} focus:z-20`}
                            aria-current={currentPage === page ? 'page' : undefined}
                        >
                            {page}
                        </button>
                    ))}
                </nav>
            </div>
        </div>
           );
        };



        const handleDelete = (transactionId) => {
            setSelectedTransactionId(transactionId);
            setIsModalOpen(true);
        };
    
        const handleConfirmDelete = async () => {
            if (selectedTransactionId) {
                try {
                    await deleteDoc(doc(db, 'transactions', selectedTransactionId));
                    setTransactions(transactions.filter(transaction => transaction.id !== selectedTransactionId));
                    toast.success("Transaction deleted successfully");
                } catch (error) {
                    console.error("Error deleting transaction:", error);
                    toast.error("Error deleting transaction");
                }
            }
            setIsModalOpen(false);
            setSelectedTransactionId(null);
        };
        

        const allowedRoles = ["Admin", "Owner"];
        const isAllowed = useRoleRestriction(allowedRoles);
      
        if (!isAllowed) {
          return < AccessDenied />
        }
       


        if (isLoading) {
            return (
                <div className="spinner-container">
                    <h1 className='text-xl text-green-800 font-extrabold'>Hold on - Retrieving Transactions</h1>
                    <div className="spinner"></div>
                </div>
            );
        }

  return (

    <>
   <BreadCrumb pages ={pages} />

    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">A list of all the transactions in your account.</h1>
         
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16">
          <input
            type="text"
            placeholder="Search transactions..."
            onChange={handleSearch}
            className="form-input rounded-md"
          />
        </div>
      </div>
      <div className="mt-4">

        <div className="align-middle inline-block min-w-full">

        <ConfirmModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleConfirmDelete}
            />

          <table className="min-w-full border-separate border-spacing-0">  
          <ToastContainer position="top-right" autoClose={5000} />

            <thead className="bg-gray-50">
              <tr>
                <th className="border-b border-gray-300 bg-white px-6 py-3 text-left text-sm font-semibold text-gray-900">Date   
                {" "}      <button onClick={toggleSortOrder} className="text-sm font-medium text-gray-700">
                  {sortOrder === 'asc' ? '🔼' : '🔽'}
            </button></th>
                <th className="border-b border-gray-300 bg-white px-6 py-3 text-left text-sm font-semibold text-gray-900">Type {" "}
                <button onClick={() => handleSortFieldChange('transactionType')} className="text-sm font-medium text-gray-700">
                                     {sortField === 'transactionType' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                </button>
                
                </th>
                <th className="border-b border-gray-300 bg-white px-6 py-3 text-left text-sm font-semibold text-gray-900">Description</th>
                <th className="border-b border-gray-300 bg-white px-6 py-3 text-left text-sm font-semibold text-gray-900">Amount</th>
                <th className="border-b border-gray-300 bg-white px-6 py-3 text-left text-sm font-semibold text-gray-900">Source/Payee</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {paginatedTransactions.map((transaction, idx) => (
                <tr key={transaction.id} className={`${idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                  <td className="border-b border-gray-200 px-6 py-2 text-sm text-gray-900">{formatTimestamp(transaction.dateIncurred)}</td>
                  <td className="border-b border-gray-200 px-6 py-2 text-sm text-gray-500">{capitalizeFirstLetter(transaction.transactionType)}</td>
                  <td className="border-b border-gray-200 px-6 py-2 text-sm text-gray-500">{transaction.text}</td>
                  <td className="border-b border-gray-200 px-6 py-2 text-sm text-gray-500">{transaction.amount.toLocaleString('en-US', { style: 'currency', currency: 'NGN' })}</td>
                  <td className="border-b border-gray-200 px-6 py-2 text-sm text-gray-500">{transaction.sourceOfIncome || transaction.user}</td>
                  <td className="border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                         <button onClick={() => handleDelete(transaction.id)}   className="text-red-600 hover:text-red-900">
                        Delete
                        </button>
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
        <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
          
      </div>
      </>
    );
  

    }
  export default AdminTransactions;