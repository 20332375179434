// src/components/pendingCommissionsService.js
import { db } from "../../firebase-config";
import { collection, getDocs, query, where, doc, updateDoc,addDoc,getDoc } from 'firebase/firestore';



// Function to calculate commission based on agent type and services
export const calculateCommission = async (referralAgentId, services) => {
  const agentRef = doc(db, 'referralAgents', referralAgentId);
  const agentDoc = await getDoc(agentRef);

  if (!agentDoc.exists()) {
      throw new Error('Referral agent not found');
  }

  const agentData = agentDoc.data();
  let commissionAmount = 0;

  if (agentData.agentType === 'Transactional') {
      commissionAmount = agentData.transactionalFee; // Use fixed fee for transactional agents
  } else if (agentData.agentType === 'Partner') {
      // Calculate commission for partner agents based on services
      commissionAmount = services.reduce((total, service) => {
          return total + (service.type === 'storage' ? agentData.partnerStorageFee : 0);
      }, 0);
  }

  return commissionAmount;
};


export async function getPendingCommissions() {
  const pendingCommissionsRef = collection(db, 'pendingCommissions');
  const q = query(pendingCommissionsRef, where('status', '==', 'pending'));
  const querySnapshot = await getDocs(q);

  return querySnapshot;
}


export const updateCommissionStatus = async (commissionId, status) => {
  const commissionRef = doc(db, 'pendingCommissions', commissionId);
  await updateDoc(commissionRef, { status: status });
};

export const addPendingCommission = async (pendingCommission) => {
  try {
    const commissionAmount = 5000; // Fixed commission amount
    const commissionData = {
      ...pendingCommission,
      amount: commissionAmount,
    };

    const docRef = await addDoc(collection(db, "pendingCommissions"), commissionData);
    
  } catch (error) {
    console.error("Error adding pending commission: ", error);
  }
};

export const updateRejectionReason = async (commissionId, rejectionReason) => {
  const commissionRef = doc(db, "pendingCommissions", commissionId);

  await updateDoc(commissionRef, {
    rejectionReason: rejectionReason,
    status: "rejected",
  });
};