import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';
import AgentTable from './Visuals/AgentTable';
import AgentChart from './Visuals/AgentChart';
import Widgets from './Visuals/Widgets';
import { Link } from 'react-router-dom';
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';
import BreadCrumb from '../BreadCrumb';

const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: false },
    { name: 'Financial Reports', to: '/settings/finance', component: Link, current: false },
    { name: 'Agency Reports', to: '/settings/finance/agentreport', component: Link, current: true },
];

const AgentReport = () => {
    const [agentData, setAgentData] = useState([]);
    const [agentStats, setAgentStats] = useState({
        totalAgents: 0,
        partnerAgents: 0,
        transactionalAgents: 0,
        totalDeceasedReferred: 0,
        deceasedPerAgent: {},
        totalCommission: {},
        totalCommissionEarned: 0,
        totalCommissionPaid: 0,
        totalCommissionUnpaid: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            const agentsSnapshot = await getDocs(collection(db, 'referralAgents'));
            const deceasedSnapshot = await getDocs(collection(db, 'deceased'));

            const agents = agentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const deceased = deceasedSnapshot.docs.map(doc => doc.data());

            let totalCommissionEarned = 0;
            let totalCommissionPaid = 0;
            let totalCommissionUnpaid = 0;

            const stats = {
                totalAgents: agents.length,
                partnerAgents: agents.filter(agent => agent.agentType === 'Partner').length,
                transactionalAgents: agents.filter(agent => agent.agentType === 'Transactional').length,
                totalDeceasedReferred: deceased.filter(d => d.referralAgentId).length,
                deceasedPerAgent: {},
                totalCommission: {}
            };

            agents.forEach(agent => {
                const referredDeceased = deceased.filter(d => d.referralAgentId === agent.id);
                stats.deceasedPerAgent[agent.id] = referredDeceased.length;
                stats.totalCommission[agent.id] = referredDeceased.reduce((acc, d) => acc + (d.commissionAmount || 0), 0);
                totalCommissionEarned += stats.totalCommission[agent.id];
            });

            deceased.forEach(d => {
                if (d.commissionApproved) {
                    if (d.commissionPaid) {
                        totalCommissionPaid += d.commissionAmount || 0;
                    } else {
                        totalCommissionUnpaid += d.commissionAmount || 0;
                    }
                }
            });

            // Construct enhanced agent data
            const enhancedAgents = agents.map(agent => ({
                ...agent,
                deceasedReferrals: stats.deceasedPerAgent[agent.id] || 0,
                totalCommission: stats.totalCommission[agent.id] || 0
            }));

            setAgentData(enhancedAgents);
            setAgentStats({
                ...stats,
                totalCommissionEarned,
                totalCommissionPaid,
                totalCommissionUnpaid
            });
        };

        fetchData();
    }, []);
    
    const allowedRoles = ["Admin", "Finance", "Front-Desk"];
    const isAllowed = useRoleRestriction(allowedRoles);
  
    if (!isAllowed) {
      return < AccessDenied />
    }
  

    return (
        <>
      <BreadCrumb pages={pages} />


        <div className="container mx-auto p-2">
            <h2 className="text-xl font-semibold mb-4">Referrals Analytics</h2>

            <Widgets agentStats={agentStats} totalCommissionEarned={agentStats.totalCommissionEarned} />

            <AgentTable data={agentData} /> 

             <AgentChart data={agentData} />    


        </div>

        </>
    );
};

export default AgentReport;
