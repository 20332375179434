import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

const ProtectedRoute = ({ Component }) => {
  const { user } = useUserAuth();
 

  return user ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
