// src/components/ReferralAgents.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import {db} from '../../firebase-config';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import { Link } from 'react-router-dom';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import BreadCrumb from '../BreadCrumb';
import banks from '../Helpers/banks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const pages = [
    { name: 'Finance', to: '/finance', component: Link, current: false },
    { name: 'Agents', to: '/finance/agent', component: Link, current: false },
    { name: 'Manage Agents', to: '/finance/agents/manage', component: Link, current: true },
    ]

const ReferralAgents = () => {
    const [referralAgents, setReferralAgents] = useState([]);
    const [form, setForm] = useState({ email: '', firstName: '', lastName: '', phone: '', title: '', bankName: '',
    accountNo: '',accountName: '', agentType: 'Transactional', transactionalFee: 0, partnerStorageFee: 0,  embalmentCommission: 0, });
    const [isEditing, setIsEditing] = useState(false);
    const [selectedAgentId, setSelectedAgentId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [agentToDelete, setAgentToDelete] = useState(null);


    const showSuccess = (message) => {
      toast.success(message);
    };
  
    // Function to display error notification
    const showError = (message) => {
      toast.error(message);
    };

    const fetchReferralAgents = async () => {
      const querySnapshot = await getDocs(collection(db, 'referralAgents'));
      const agents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setReferralAgents(agents);
    };
  
    useEffect(() => {
      fetchReferralAgents();
    }, []);


      const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
      };
      
      const handleSubmit = async (e) => {
            e.preventDefault();

            if (!form.firstName || !form.lastName || !form.email || !form.phone) {
              showError("Please fill in all required fields");
              return;
            }
          
      
            const dataToSave = {
              ...form,
              transactionalFee: form.agentType === 'Transactional' ? parseFloat(form.transactionalFee) : 0,
              partnerStorageFee: form.agentType === 'Partner' ? parseFloat(form.partnerStorageFee) : 0,
              embalmentCommission: form.agentType === 'Partner' ? parseFloat(form.embalmentCommission) : 0,
            };
              
            try {
              if (isEditing) {
                await updateDoc(doc(db, 'referralAgents', selectedAgentId), dataToSave);
              } else {
                await addDoc(collection(db, 'referralAgents'), dataToSave);
              }
              const querySnapshot = await getDocs(collection(db, 'referralAgents'));
              const agents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
              setReferralAgents(agents);
              setForm({ email: '', firstName: '', lastName: '', phone: '', title: '', agentType: 'Transactional', transactionalFee: 0, partnerStorageFee: 0 });
              setIsEditing(false);
              setSelectedAgentId(null);
              showSuccess(isEditing ? 'Agent updated successfully' : 'Agent added successfully');
            } catch (error) {
              console.error("Error adding/updating agent: ", error);
              showError('Error occurred while processing your request');
            }
      };
      
         
    
      const handleEdit = (agentId) => {
        const agent = referralAgents.find((a) => a.id === agentId);
        setForm({
            ...agent,
            bankName: agent.bankName || '',
            accountNo: agent.accountNo || '',
            accountName: agent.accountName || ''
        });
        setIsEditing(true);
        setSelectedAgentId(agentId);
    };
    
    
     
      const handleDeleteClick = (agentId) => {
        setAgentToDelete(agentId);
        setShowDeleteModal(true);
      };
    
      const onAgentDeleted = () => {
        // Refresh the agents list and close the modal
        fetchReferralAgents();
        setShowDeleteModal(false);
        showSuccess('Agent Deleted');
      };
    
      const onError = (error) => {
        console.error("Error deleting agent: ", error);
        showError('Error Deleting Agent');
        setShowDeleteModal(false);
      };
      
     
  
      const allowedRoles = ["Admin", "Finance", "Front-Desk"];
      const isAllowed = useRoleRestriction(allowedRoles);
    
      if (!isAllowed) {
        return < AccessDenied />
      }
             

  return (
    <>
   <BreadCrumb pages={pages} />

   <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

   

    <DeleteConfirmationModal 
        show={showDeleteModal} 
        onClose={() => setShowDeleteModal(false)} 
        agentId={agentToDelete} 
        onAgentDeleted={onAgentDeleted} 
        onError={onError} 
      />

    <div className="container mx-auto px-4 py-1">
    
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                    <div className="bg-white shadow-md rounded-md p-4">
                      <h3 className="text-xl font-semibold mb-4">Add Referral Agent</h3>
                      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-md px-8 pt-1 pb-4 mb-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                          Title
                                        </label>
                                        <select
                                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          id="title"
                                          name="title"
                                          value={form.title}
                                          onChange={handleChange}
                                        >
                                          <option value="">Agent Title</option>
                                          <option value="Mr">Mr</option>
                                          <option value="Mrs">Mrs</option>
                                          <option value="Miss">Miss</option>
                                          <option value="Doctor">Doctor</option>
                                          <option value="Engr">Engr</option>
                                          <option value="Prof">Prof</option>
                                          <option value="Pastor">Pastor</option>
                                          <option value="Reverend">Reverend</option>
                                          <option value="Chief">Chief</option>
                                        </select>
                                  </div>
                              
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                          First Name
                                        </label>
                                        <input
                                         className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          id="firstName"
                                          type="text"
                                          name="firstName"
                                          value={form.firstName}
                                          onChange={handleChange}
                                        />
                                      </div>

                                      <div className="mb-4">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                          Last Name
                                        </label>
                                        <input
                                       className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        id="lastName"
                                        type="text"
                                        name="lastName"
                                        value={form.lastName}
                                        onChange={handleChange}
                                      />
                                    </div>

                                    <div className="mb-4">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Email
                                      </label>
                                      <input
                                         className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                         placeholder="you@example.com"
                                        id="email"
                                        type="email"
                                        name="email"
                                        value={form.email}
                                        onChange={handleChange}
                                      />
                                    </div>

                                    <div className="mb-4">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Phone
                                      </label>
                                      <input
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        id="phone"
                                        type="text"
                                        name="phone"
                                        value={form.phone}
                                        onChange={handleChange}
                                      />
                                    </div>
                  
                                    {/* Bank Name Input Field */}
                                    <div className="mb-4">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                          Bank Name
                                      </label>
                                      <select
                                          className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          id="bankName"
                                          name="bankName"
                                          value={form.bankName}
                                          onChange={handleChange}
                                      >
                                          <option value="">Select Bank</option>
                                          {banks.map((bank, index) => (
                                              <option key={index} value={bank}>{bank}</option>
                                          ))}
                                      </select>
                                  </div>

                                          {/* Account Number Input Field */}
                                          <div className="mb-4">
                                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                                  Account Number
                                              </label>
                                              <input
                                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                  id="accountNo"
                                                  type="text"
                                                  name="accountNo"
                                                  value={form.accountNo}
                                                  onChange={handleChange}
                                              />
                                          </div>

                                          {/* Account Name Input Field */}
                                          <div className="mb-4">
                                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                                  Account Name
                                              </label>
                                              <input
                                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                  id="accountName"
                                                  type="text"
                                                  name="accountName"
                                                  value={form.accountName}
                                                  onChange={handleChange}
                                              />
                                          </div>

                                  {/* New field for agent type */}
                                    <div className="mb-4">
                                          <label className="block text-sm font-medium leading-6 text-gray-900">
                                              Agent Type
                                          </label>
                                          <select
                                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              id="agentType"
                                              name="agentType"
                                              value={form.agentType}
                                              onChange={handleChange}
                                          >
                                              <option value="Transactional">Transactional</option>
                                              <option value="Partner">Partner</option>
                                          </select>
                                      </div>

                                      {/* New field for transactional fee */}
                                      {form.agentType === 'Transactional' && (
                                          <div className="mb-4">
                                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                                  Referal Amount (One-Off)
                                              </label>
                                              <div className="relative mt-2 rounded-md shadow-sm">
                                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                              <span className="text-gray-500 sm:text-sm">₦</span>
                                            </div>

                                              <input
                                                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                  placeholder="0.00"
                                                  aria-describedby="price-currency"
                                                  id="transactionalFee"
                                                  type="text"
                                                  name="transactionalFee"
                                                  value={form.transactionalFee}
                                                  onChange={handleChange}
                                              />
                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                              NGN
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      )}

                                      {/* New field for partner storage fee */}
                                      {form.agentType === 'Partner' && (
                                        <>
                                          <div className="mb-4">
                                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                                  Partner Daily Storage Commission
                                              </label>
                                              <div className="relative mt-2 rounded-md shadow-sm">
                                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                              <span className="text-gray-500 sm:text-sm">₦</span>
                                            </div>
                                              <input
                                                   className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                   placeholder="0.00"
                                                   aria-describedby="price-currency"
                                                  id="partnerStorageFee"
                                                  type="text"
                                                  name="partnerStorageFee"
                                                  value={form.partnerStorageFee}
                                                  onChange={handleChange}
                                              />
                                           <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                              NGN
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                                <div className="mb-4">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                  Embalment Commission
                                                </label>
                                                <div className="relative mt-2 rounded-md shadow-sm">
                                                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                    <span className="text-gray-500 sm:text-sm">₦</span>
                                                  </div>
                                                  <input
                                                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder="0.00"
                                                    aria-describedby="price-currency"
                                                    id="embalmentCommission"
                                                    type="number"
                                                    name="embalmentCommission"
                                                    value={form.embalmentCommission}
                                                    onChange={handleChange}
                                                  />
                                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                      NGN
                                                    </span>
                                                  </div>
                                                </div>
                                                </div>

                                          </>
                                      )}

                                  <div className="flex items-center justify-between">
                                    <button
                                      className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      type="submit"
                                    > <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> {isEditing ? 'Update' : 'Add'}  Agent
                                    </button>
                                  </div>

                           </div>       
                      </form>
                    </div>


                        {/* Table */}
                      <div className="bg-white shadow-md rounded-md px-1 py-6">
                      <h3 className="text-xl font-semibold mb-4">Referral Agents</h3>
                      <table className="min-w-full border-separate border-spacing-0">
                          <thead>
                            <tr>
                            
                            <th scope="col"  className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold
                             text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                              Title</th>
                              <th scope="col"  className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold
                             text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                Name</th>
                                <th scope="col"  className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold
                             text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                Email</th>
                                <th scope="col"  className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold
                             text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                Phone</th>
                                <th scope="col"  className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold
                             text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                Type</th>
                                <th scope="col"  className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold
                             text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                Actions</th>
                            </tr>
                          </thead>
                          <tbody className="text-gray-600 text-xs font-light">
                            {referralAgents.map((agent) => (
                              <tr key={agent.id} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-2 text-left">
                                  <div className="flex items-center">
                                    <span className='px-4'>{agent.title}</span>
                                  </div>
                                </td>
                                <td className="py-3 text-left whitespace-nowrap">
                                  <div className="flex items-center">
                                    <span>{`${agent.firstName} ${agent.lastName}`}</span>
                                  </div>
                                </td>
                                <td className="py-3 px-2 text-left">
                                  <div className="flex items-center">
                                    <span>{agent.email}</span>
                                  </div>
                                </td>
                                <td className="py-3 px-2 text-left">
                                  <div className="flex items-center">
                                    <span>{agent.phone}</span>
                                  </div>
                                </td>

                                <td className="py-3 px-2 text-left">
                                  <div className="flex items-center">
                                    <span>{agent.agentType}</span>
                                  </div>
                                </td>
                              
                                <td className="py-3 px-2 text-center">
                                  <div className="flex justify-center items-center">
                                  <button
                                        onClick={() => handleEdit(agent.id)}
                                        className="text-blue-500 hover:text-blue-700 focus:outline-none"
                                        >
                                        Edit
                                        </button>
                                        <button
                                        onClick={() => handleDeleteClick(agent.id)}
                                        className="ml-4 text-red-500 hover:text-red-700 focus:outline-none"
                                        >
                                        Delete
                                        </button>

                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                </div>
    </div>

    </>
     );
};

export default ReferralAgents;
