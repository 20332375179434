// DeceasedTable.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchDeceasedList, fetchNextOfKinList, fetchReferralAgentsList } from './deceasedService';
import { computeStorageDays, computeDeceasedTotalCost } from './computeStorageDays';
import useRoleRestriction from '../../context/useRoleRestriction';
import AccessDenied from '../AccessDenied';
import {formatDate} from '../../functions/formatDate';
import EditDeceased from './EditDeceased'; 
import BreadCrumb from '../BreadCrumb';
import { calculateAccumulatedStorageFees , formatCurrency, capitalizeFirstLetter} from '../Deceased/helpers';
import Papa from 'papaparse';
import { MoonLoader } from 'react-spinners';


const pages = [
  { name: 'Deceased', to: '/deceased', current: false, component: Link },
  { name: 'Deceased in Storage', to: '/dtable', current: true, component: Link },
 ]

function DeceasedTable() {
  const [deceasedList, setDeceasedList] = useState([]);
  const [nextOfKinList, setNextOfKinList] = useState([]);
  const [referralAgentsList, setReferralAgentsList] = useState([]);
  const [search, setSearch] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDeceasedId, setSelectedDeceasedId] = useState(null);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const allowedRoles = ["Admin", "Finance", "Mortician", "Front-Desk"];
  const isAllowed = useRoleRestriction(allowedRoles);
  const [arrivalDateFilter, setArrivalDateFilter] = useState('');
  const [totalDaysFilter, setTotalDaysFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  


  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const { allDeceased } = await fetchDeceasedList();
        
       
        const processedDeceasedList = allDeceased.map(deceased => {
          if (deceased.status === 'in storage' && deceased.allocations && deceased.allocations.length > 0) {
            const mostRecentAllocation = deceased.allocations.reduce((latest, current) => {
              const latestDate = new Date(latest.dateAssigned.seconds * 1000); // Convert Firestore Timestamp to JavaScript Date
              const currentDate = new Date(current.dateAssigned.seconds * 1000); // Convert Firestore Timestamp to JavaScript Date
              return latestDate > currentDate ? latest : current;
            }, deceased.allocations[0]);
    
            return {
              ...deceased,
              mostRecentStorageNumber: mostRecentAllocation.storageNumber
            };
          }
    
          return {
            ...deceased,
            mostRecentStorageNumber: 'N/A'
          };
        });
    
        setDeceasedList(processedDeceasedList); // Update state with processed list
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching deceased data:", error);
        setDeceasedList([]); 
        setIsLoading(false); 
      }
    
      // Fetch additional data (next of kin, referral agents)
      try {
        const [nextOfKin, referralAgents] = await Promise.all([
          fetchNextOfKinList(),
          fetchReferralAgentsList(),
        ]);
    
        setNextOfKinList(nextOfKin || []); 
        setReferralAgentsList(referralAgents || []); 
      } catch (error) {
        console.error("Error fetching additional data:", error);
        // Handle error for next of kin and referral agents data
      }
    }
    


     fetchData();
  }, []);


  const filteredDeceasedList = deceasedList.filter((deceased) => {
    const lastName = deceased.lastName ? deceased.lastName.toLowerCase() : '';
    const firstName = deceased.firstName ? deceased.firstName.toLowerCase() : '';
    const status = deceased.status ? deceased.status.toLowerCase() : '';
    const arrivalDateMatch = arrivalDateFilter ? deceased.dateDeposited === arrivalDateFilter : true;
    const totalDaysInStorage = computeStorageDays(deceased.dateDeposited);
    const totalDaysMatch = totalDaysFilter ? totalDaysInStorage >= totalDaysFilter : true;
  
  return (
    (firstName.includes(search.toLowerCase()) || lastName.includes(search.toLowerCase())) && 
    (status === 'in storage') &&
    arrivalDateMatch && 
    totalDaysMatch
  );
});

  function handleSort(field) {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  }

  const sortedDeceasedList = filteredDeceasedList.sort((a, b) => {
    let fieldA = a[sortField];
    let fieldB = b[sortField];
  
    // Custom handling for dates
    if (sortField === 'dateDeposited') {
      fieldA = new Date(fieldA);
      fieldB = new Date(fieldB);
    }
  
    // Assuming storage numbers are numeric for simplicity. Adjust as needed for alphanumeric.
    if (sortField === 'mostRecentStorageNumber' && !isNaN(fieldA) && !isNaN(fieldB)) {
      fieldA = parseInt(fieldA, 10);
      fieldB = parseInt(fieldB, 10);
    }
  
    // Adjust comparison logic as needed for different types
    if (fieldA < fieldB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (fieldA > fieldB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });
  


  const refreshDeceasedList = async () => {
    try {
        const { allDeceased } = await fetchDeceasedList();
        setDeceasedList(allDeceased || []);
    } catch (error) {
        console.error("Error fetching deceased data:", error);
        setDeceasedList([]);
    }
};
  
  if (!isAllowed) {
    return < AccessDenied />
  }

  const handleEditClick = (deceasedId) => {
    setSelectedDeceasedId(deceasedId); // Set the selected deceased ID
    setIsEditModalOpen(true);
  };
  

  const exportToCSV = () => {
    // Filter the list for deceased with 'in storage' status
    const filteredList = deceasedList.filter(deceased => deceased.status === 'in storage');
  
    // Map the filtered data for CSV export
    const csvData = filteredList.map(deceased => ({
      RegNo: deceased.bodyNumber,
      FirstName: deceased.firstName,
      LastName: deceased.lastName,
      Arrival: formatDate(deceased.dateDeposited),
      TotalDaysInStorage: computeStorageDays(deceased.dateDeposited),
      RecentStorageNumber: deceased.mostRecentStorageNumber,
      StorageCostIncurred: formatCurrency(computeDeceasedTotalCost(deceased)),
    }));
  
    // Convert to CSV and proceed with the rest of the export logic as before
    const csv = Papa.unparse(csvData);
    const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(csvBlob);
    link.setAttribute('download', 'deceased_data_in_storage.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
 

  const calculateTotalReceivables = (deceased) => {
    // Use the imported calculateAccumulatedStorageFees function
    // Assuming calculateAccumulatedStorageFees takes allocations and exitDate as arguments
    const accumulatedStorageFees = calculateAccumulatedStorageFees(deceased.allocations, deceased.exitDate);
  
    const totalInvoiceAmount = deceased.invoices.reduce((sum, invoice) => {
      return sum + invoice.total; // Assuming invoice.total is the total amount of each invoice
    }, 0);
  
    // Calculate wallet balance from transactions
    const walletBalance = deceased.walletTransactions.reduce((sum, transaction) => {
      if (transaction.transactionType === 'credit') {
        return sum + transaction.amount;
      }
      return sum;
    }, 0);
  
    // Return the total receivables
    return accumulatedStorageFees + totalInvoiceAmount - walletBalance;
  };
  
   
  const totalSumAcrossDeceased = deceasedList.reduce((sum, deceased) => {
    if (deceased.status === 'in storage') {
      return sum + calculateTotalReceivables(deceased);
    }
    return sum;
  }, 0);
  

  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <MoonLoader color="#00008B" size={80} />
      </div>
    );
  }
  

  
  return (
    <>
   <BreadCrumb pages={pages} />


    <div className='flex flex-col justify-center items-center p-4'>

    <h4 className="text-xl font-bold mb-2">Deceased in Storage</h4>

    <div className="flex flex-wrap -mx-2">
  <div className="w-full md:w-1/2 px-2 mb-4 ">
  <label className='text-sm text-gray-500'>Search:</label>
    <input
      type="text"
      placeholder="Name Search"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      className="border border-gray-300 p-2 rounded-lg mb-2 text-sm text-center"
    />
    
  </div>
              
  <div className="w-full md:w-1/2 px-2 mb-4 pl-3">
    <label className='text-sm text-gray-500'>Date Filter:</label>
    <input 
      type="date" 
      value={arrivalDateFilter} 
      onChange={(e) => setArrivalDateFilter(e.target.value)}
      className="border border-gray-300 p-2 ml-4 uppercase  w-1/2 rounded-lg mb-2 text-sm text-center"
    />
  </div>
</div>

        
     <table className="table-auto border-collapse border border-gray-200">   
        
        <thead className="bg-gray-50">
          <tr>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Reg No
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={() => handleSort('firstName')}>
              First Name
            </th>            
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={() => handleSort('lastName')}>
              Last Name
            </th>
           
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            onClick={() => handleSort('dateDeposited')}>
              DOA
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tenure(Days)
            </th>   
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            onClick={() => handleSort('mostRecentStorageNumber')}>
            Storage No:
          </th>              
            
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Storage Cost
            </th>          
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <button onClick={exportToCSV} className="bg-green-500 hover:bg-green-600 text-white text-xs px-2 py-1 rounded transition duration-300">
  Export to CSV
</button>
            </th>

          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 ">
          {filteredDeceasedList.map((deceased, index) => {
            const nextOfKin = nextOfKinList.find(
              (nextOfKin) => nextOfKin.deceasedId === deceased.id
            );
            const referralAgent = referralAgentsList.find(
              (referralAgent) => referralAgent.id === deceased.referralAgentId
            );
            const totalCost = computeDeceasedTotalCost(deceased); 
            
            return (
              <tr key={deceased.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-2 py-2 text-xs text-gray-900">{deceased.bodyNumber}</td>
                <td className="px-2 py-2 text-sm text-gray-900">{capitalizeFirstLetter(deceased.firstName)}</td>
                <td className="px-2 py-2 text-sm text-gray-900">{capitalizeFirstLetter(deceased.lastName)}</td>               
                
                <td className="px-2 py-2 text-sm text-gray-900">{formatDate(deceased.dateDeposited)}</td>

                <td className="px-14 py-2 text-sm text-gray-900">
                {computeStorageDays(deceased.dateDeposited)}
              </td>

              <td className="px-2 py-2 text-sm text-gray-900">{deceased.mostRecentStorageNumber}</td>

                <td className="px-2 py-2 text-sm text-gray-900">{formatCurrency(totalCost)}</td> 
               
                          
                <td className="px-2 py-2 text-xs text-gray-900">
                  <div className="flex justify-between space-x-2">
                    <Link
                      to={`/deceased/${deceased.id}`}
                      className="bg-blue-500 hover:bg-blue-600 text-white text-xs px-2 py-1 rounded transition duration-300 ease-in-out flex-grow"
                    >
                      Details
                    </Link>

                    <button
                      onClick={() => handleEditClick(deceased.id)}
                      className="bg-yellow-500 hover:bg-yellow-600 text-white text-xs px-2 py-1 rounded transition duration-300 ease-in-out flex-grow"
                    >
                      Edit
                    </button>
                  
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>

    {isEditModalOpen && (
  <EditDeceased
    id={selectedDeceasedId} 
    onClose={() => setIsEditModalOpen(false)} 
    refreshDeceasedList={() => {
      // You can re-fetch the list here or add your refresh logic
      async function fetchData() {
        const deceased = await fetchDeceasedList();
        setDeceasedList(deceased);
      }
      fetchData();
    }}
  />
)}


    </>
  );
}

export default DeceasedTable;

