import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, addDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db, auth } from '../../firebase-config';
import AccessDenied from '../AccessDenied';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import useRoleRestriction from '../../context/useRoleRestriction';
import {generatePDFReceipt} from './generatePDFReceipt';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { formatCurrency, convertTimestampToString } from '../Deceased/helpers';



const pages = [
    { name: 'Finance', to: '/finance', component: Link, current: false },
    { name: 'Generate Receipt', to: '/finance/receipt', component: Link, current: true },
    ]

const PaymentsReceived = () => {
    // State variables
    const [invoices, setInvoices] = useState([]);
    const [invoiceId, setInvoiceId] = useState('');
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [dateReceived, setDateReceived] = useState(new Date());
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [paymentAmount, setPaymentAmount] = useState('');
    const [amountReceived, setAmountReceived] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [paymentSource, setPaymentSource] = useState('');
    const [deceasedName, setDeceasedName] = useState('');
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const user = auth.currentUser;
    const [showModal, setShowModal] = useState(false);
    const currentDate = new Date().toISOString().substr(0, 10);
    const isFormValid = selectedInvoice && paymentAmount && paymentSource;


  
    useEffect(() => {
      const fetchInvoices = async () => {
        const invoicesCol = collection(db, 'invoices');
        const invoicesSnapshot = await getDocs(invoicesCol);
        const invoicesData = invoicesSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setInvoices(invoicesData);
      };
      fetchInvoices();
    }, []);



    useEffect(() => {
      const fetchInvoiceDetails = async (invoiceId) => {
        if (invoiceId) {
          const invoiceDocRef = doc(db, "invoices", invoiceId);
          const invoiceSnapshot = await getDoc(invoiceDocRef);
      
          if (invoiceSnapshot.exists()) {
             setInvoiceDetails({ ...invoiceSnapshot.data(), id: invoiceSnapshot.id });
          } else {
            setInvoiceDetails(null);
          }
        } else {
          setInvoiceDetails(null);
        }
      };
  
      if (selectedInvoice) {
        fetchInvoiceDetails(selectedInvoice.id);
      } else {
        setInvoiceDetails(null);
      }
    }, [selectedInvoice]);
  

  
    const handleInvoiceChange = async (selectedOption) => {
      const invoiceId = selectedOption.value;
    
      // Ensure invoiceId is valid before proceeding
      if (!invoiceId) {
        setSelectedInvoice(null);
        setCustomerName('');
        setDeceasedName('');
        return;
      }
    
      const invoiceDocRef = doc(db, "invoices", invoiceId);
      const invoiceSnapshot = await getDoc(invoiceDocRef);
    
      if (invoiceSnapshot.exists()) {
        const invoiceData = invoiceSnapshot.data();
        setSelectedInvoice({ ...invoiceData, id: invoiceSnapshot.id });
    
        setCustomerName(invoiceData.customerName || '');
    
        // Proceed only if deceasedId is available
        if (invoiceData.deceasedId) {
          const deceasedDocRef = doc(db, "deceased", invoiceData.deceasedId);
          const deceasedSnapshot = await getDoc(deceasedDocRef);
    
          if (deceasedSnapshot.exists()) {
            const deceasedData = deceasedSnapshot.data();
            setDeceasedName(`${deceasedData.firstName} ${deceasedData.lastName}`);
          } else {
            // Handle case where deceased data might not be available
            console.log(`Deceased data not found for ID: ${invoiceData.deceasedId}`);
            setDeceasedName('Deceased data unavailable');
          }
        } else {
          // Handle case where deceasedId is not available in the invoice data
          console.log('No deceasedId available in invoice data');
          setDeceasedName('');
        }
      } else {
        console.log('Invoice data not found');
        setSelectedInvoice(null);
        setCustomerName('');
        setDeceasedName('');
      }
    };
    
    



  
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      try {
        // Calculate payment status
        let paymentStatus;
        if (paymentAmount < selectedInvoice.total) {
          paymentStatus = 'part-payment';
        } else if (paymentAmount === selectedInvoice.total) {
          paymentStatus = 'fully-paid';
        } else {
          paymentStatus = 'over-payment';
        }
    
        const paymentData = {
          invoiceId: selectedInvoice.id, 
          dateReceived,
          customerName,
          customerEmail,
          amountReceived,
          paymentSource,
          deceasedName,
          paymentAmount,
          paymentStatus,
        };
        
        handleEmailReceipt(paymentData);
        generatePDFReceipt(paymentData);
        setShowModal(true);
    
        // Save Transaction
        const transactionDescription = `Payment for Invoice - ${deceasedName}`;
        
        const transactionsCol = collection(db, "transactions");
        const transactionData = {
          amount: paymentAmount,
          dateIncurred: paymentDate,
          dateOfPosting: paymentDate,
          sourceOfIncome: paymentSource,
          text: transactionDescription,
          transactionType: "income",
          user: user.email,
        };
    
        const transactionDocRef = await addDoc(transactionsCol, transactionData);
    
        // Update invoice
        const invoiceDocRef = doc(db, 'invoices', selectedInvoice.id);
        const invoiceSnapshot = await getDoc(invoiceDocRef);
        const invoiceData = invoiceSnapshot.data();
        const newAmountPaid = (invoiceData.amountPaid || 0) + paymentAmount;
    
                  let newStatus;
            if (newAmountPaid < invoiceData.total) {
              newStatus = 'part-payment';
            } else if (newAmountPaid === invoiceData.total) {
              newStatus = 'fully paid';
            } else {
              newStatus = 'over-payment';
            }

            await updateDoc(invoiceDocRef, { amountPaid: newAmountPaid, status: newStatus });

            const deceasedDocRef = doc(db, 'deceased', invoiceData.deceasedId);
            const deceasedSnapshot = await getDoc(deceasedDocRef);
            const deceasedData = deceasedSnapshot.data();
    
                    // Initialize new transaction for walletTransactions
              const newWalletTransaction = {
                transactionId: transactionDocRef.id, // Use the ID from the transaction document reference
                transactionType: newStatus === 'over-payment' ? 'credit' : 'debit',
                amount: paymentAmount,
                date: paymentDate,
                invoiceId: selectedInvoice.id, // if the transaction is related to an invoice
                description: transactionDescription,
              };

           
          
                if (newStatus === 'over-payment') {
                  // Initialize new transaction for walletTransactions
                  const newWalletTransaction = {
                    transactionId: transactionDocRef.id, // Use the ID from the transaction document reference
                    transactionType: 'credit',
                    amount: newAmountPaid - invoiceData.total, // store only the excess amount
                    date: paymentDate,
                    invoiceId: selectedInvoice.id, // if the transaction is related to an invoice
                    description: transactionDescription,
                  };
                  const newWallet = (deceasedData.wallet || 0) + (newAmountPaid - invoiceData.total);
                  const updatedOutstandingBill = Math.max(0, parseFloat(deceasedData.outstandingBill) - paymentAmount);
                  await updateDoc(deceasedDocRef, 
                  { wallet: newWallet, 
                    outstandingBill: updatedOutstandingBill,
                    transactions: arrayUnion(transactionDocRef.id),
                    walletTransactions: arrayUnion(newWalletTransaction),
                  });

                } else if (newStatus === 'fully-paid') {
                    const updatedOutstandingBill = Math.max(0, deceasedData.outstandingBill - paymentAmount);
                  await updateDoc(deceasedDocRef, {
                    outstandingBill: updatedOutstandingBill,
                    transactions: arrayUnion(transactionDocRef.id), 
                  });
                }

        // Clear form
        setSelectedInvoice(null);
        setDateReceived(new Date());
        setPaymentAmount(0);
        setPaymentSource("");
      } catch (error) {
        console.error("Error updating invoice: ", error);
      }
    };
    
  
       const handleEmailReceipt = async (paymentData) => {
                const functions = getFunctions();
                const sendReceiptEmail = httpsCallable(functions, 'sendReceiptEmail');
              try {
                  await sendReceiptEmail({
                    dateReceived: paymentData.dateReceived,
                    customerName: paymentData.customerName,
                    customerEmail: paymentData.customerEmail,
                    amountReceived: paymentData.amountReceived,
                    paymentSource: paymentData.paymentSource,
                    deceasedName: paymentData.deceasedName,
                    paymentAmount: paymentData.paymentAmount,
                    paymentStatus: paymentData.paymentStatus,
                    invoiceId: paymentData.invoiceId,                       
                });
              
              } catch (error) {
                console.error('Failed to send email:', error);
              }
         };


      
      const handleCancel = () => {
        setInvoiceId("");
        setDateReceived(new Date());
        setCustomerName("");
        setAmountReceived(0);
        setPaymentSource("");
        setDeceasedName("");
        
      };
      
     
      
      useEffect(() => {
        if (showModal) {
          const timer = setTimeout(() => {
            setShowModal(false);
          }, 5000);
      
          return () => {
            clearTimeout(timer);
          };
        }
      }, [showModal]);
      
      const options = invoices.map((invoice) => ({
        value: invoice.id,
        label: invoice.deceasedName
          ? `${invoice.deceasedName} - ${invoice.total ? invoice.total.toFixed(2) : 'N/A'}`
          : `${invoice.customerName} - ${invoice.total ? invoice.total.toFixed(2) : 'N/A'}`
      }));

      
      const allowedRoles = ["Admin", "Finance", "Front-Desk"];
      const isAllowed = useRoleRestriction(allowedRoles);
      if (isAllowed === null) return <div>Loading...</div>;
    
      if (!isAllowed) {
        return < AccessDenied />
      }
             
  
    return (
        <>  
         <nav className="flex gap-10 px-14 sm:px-14" aria-label="Breadcrumb">
            <ol  className="flex items-center space-x-4">
              <li>
                <div>
                  <Link to="/" className="text-gray-400 hover:text-gray-500">
                    <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <Link to={page.to}
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
    </nav>

    <div className="container mx-auto py-2">

    {
  showModal && (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Receipt generated successfully
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
<div className="grid grid-cols-1 md:grid-cols-2 gap-4">

<div className=" flex align-center py-2 ml-3 px-12">
    <form >
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="invoice">
                Invoice
                </label>
                <Select 
                      id="invoice"
                      options={options}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight text-sm focus:outline-none focus:shadow-outline"
                      onChange={handleInvoiceChange}
                      value={selectedInvoice ? {value: selectedInvoice.id, label: `${selectedInvoice.customerName} - ${convertTimestampToString(selectedInvoice.invoiceDate)} - ${formatCurrency(selectedInvoice.total) ? formatCurrency(selectedInvoice.total.toFixed(2)) : 'N/A'}`} : ""}
                      isSearchable
                    />
                </div>
  
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
                <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="customerName">
                Customer Name
                </label>
                <div className="mt-2">
                <input
                type="test"
                id="customerName"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={customerName}
                disabled                
                />
                </div>
            </div>

            <div className="sm:col-span-3">
                <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="deceasedName">
                Deceased Name 
                </label>
                <div className="mt-2">
                <input
                type="test"
                id="deceasedName"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={deceasedName}
                disabled
                />
                </div>
            </div>
            </div>


            <div className="sm:col-span-2 sm:col-start-1">
              <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="paymentDate">
                Payment Date
                </label>
                <div className="mt-2">
                <input
                type="date"
                id="paymentDate"
                required
                max={currentDate}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={paymentDate.toISOString().substr(0, 10)}
                onChange={(e) => setPaymentDate(new Date(e.target.value))}
                />
            </div>

            <div className="sm:col-span-2">
                <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="paymentAmount">
                Payment Amount
                </label>
                <div className="mt-2">
                <input
                type="number"
                id="paymentAmount"
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(parseFloat(e.target.value))}
                />
            </div>
            </div>

            <div className="sm:col-span-2">
                <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="customerEmail">
                Customer Email
                </label>
                <div className="mt-2">
                <input
                type="email"
                id="customerEmail"
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                />
            </div>
            </div>


            </div>
            


             <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 pb-5 ">
            <div className="sm:col-span-3">
                <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="paymentSource">
                Payment Source
                </label>
                <select
                id="paymentSource"
                className="shadow appearance-none border rounded w-full text-sm py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={paymentSource}
                required
                onChange={(e) => setPaymentSource(e.target.value)}
                >
                <option value="">Select Payment Source</option>
                <option value="Bank Transfer-Zenith">Bank Transfer-Zenith</option>
                <option value="Bank transfer">Bank Transfer</option>
                <option value="Cash">Cash</option>
                <option value="POS-MoniePoint">MoniePoint POS</option>
                <option value="POS-NOMBA">NOMBA POS</option>
                </select>
            </div>

           
            </div>
          
            <div className="flex flex-col sm:flex-row items-center justify-between">
                <button
                type="submit"
                onClick={handleSubmit}
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-2 sm:mb-0 sm:mr-2 ${isFormValid ? '' : 'opacity-50 cursor-not-allowed'}`}
                disabled={!isFormValid}
              >
                Generate and Mail Receipt
                </button>

                

                <button
                type="button"
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
               onClick={handleCancel}
                >
                Cancel
                </button>
            </div>
            </form>
    </div>
  


    {invoiceDetails && (
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      <h4 className="text-2xl font-bold text-gray-800 mb-4">Selected Invoice Details:</h4>
      <p className="text-base text-gray-700">
        <strong className="font-semibold">Invoice ID:</strong> {invoiceDetails.id}
      </p>
      <p className="text-base text-gray-700">
        <strong className="font-semibold">Invoice Status:</strong> 
        <span className={`inline-block ml-1 rounded px-2 py-1 text-sm font-medium ${invoiceDetails.status === 'Paid' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
          {invoiceDetails.status}
        </span>
      </p>
      <p className="text-base text-gray-700">
        <strong className="font-semibold">Customer Name:</strong> {invoiceDetails.customerName}
      </p>
      <p className="text-base text-gray-700">
        <strong className="font-semibold">Total:</strong> {formatCurrency(invoiceDetails.total.toFixed(2))}
      </p>
      
      <h4 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Invoice Items:</h4>
      <div className="divide-y divide-gray-300">
        {invoiceDetails.invoiceItems && invoiceDetails.invoiceItems.map((item, index) => (
          <div key={index} className="pt-3 pb-2">
            <p className="text-base text-gray-700">
              <strong className="font-semibold">Description:</strong> {item.description}
            </p>
            <p className="text-base text-gray-700">
              <strong className="font-semibold">Quantity:</strong> {item.quantity}
            </p>
            <p className="text-base text-gray-700">
              <strong className="font-semibold">Price:</strong> {formatCurrency(item.price.toFixed(2))}
            </p>
          </div>
        ))}
      </div>
    </div>
    
  )}
</div>









        
      </div>
      </>
    );
  };
  
  export default PaymentsReceived;
  
