import React, { useEffect, useState, useCallback} from 'react';
import { arrayUnion, collection, doc, getDoc, getDocs, Timestamp, query, where,runTransaction } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { getFunctions, httpsCallable } from 'firebase/functions';
import moment from 'moment';
import {formatCurrency, generateBodyNumber, convertDateToFirestoreTimestamp} from './helpers';
import { useNavigate } from 'react-router-dom';
import './Spinner.css';
import { checkForDuplicateRecord } from '../operations/deceasedService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

        
const ConfirmationPage = ({ formData, onBack, onFormSubmitStatus,setCurrentStep }) => {
    
    const [nextOfKinData, setNextOfKinData] = useState(null);
    const [selectedStorageType, setSelectedStorageType] = useState(null);
    const [selectedStorageTypeDetails, setSelectedStorageTypeDetails] = useState(null);
    const [selectedSpaceDetails, setSelectedSpaceDetails] = useState(null);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [bodyNumber, setBodyNumber] = useState('');
    const selectedDeceased = formData.firstName + " " + formData.lastName;                
    const invoiceItems = [];
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loadingError, setLoadingError] = useState(null);
    const [allocationMessage, setAllocationMessage] = useState('');
    const [allocationError, setAllocationError] = useState('');
     
          
  
    // Function to fetch the details of the selected storage type and space
    const fetchStorageDetails = useCallback(async () => {
      if (formData.selectedStorageType) {
         const storageTypeRef = doc(db, 'storage', formData.selectedStorageType);
         const storageTypeSnap = await getDoc(storageTypeRef);
          
        if (storageTypeSnap.exists()) {
          setSelectedStorageTypeDetails(storageTypeSnap.data());
        } else {
          throw new Error('Selected storage type does not exist');
        }
      }
    }, [formData.selectedStorageType]);

    
    useEffect(() => {
      fetchStorageDetails().catch((error) => setLoadingError(error.message));
      setIsLoading(false);
    }, [fetchStorageDetails]);



    const fetchData = useCallback(async () => {
      try {
          const nextOfKinDocRef = doc(db, 'nextOfKin', formData.nextOfKinId);
          const nextOfKinDocSnap = await getDoc(nextOfKinDocRef);
          if (nextOfKinDocSnap.exists()) {
              setNextOfKinData(nextOfKinDocSnap.data());
          } else {
              throw new Error('Next of Kin data not found.');
          }
      } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingError(error.message || 'Failed to load data.');
      } finally {
          setIsLoading(false);
      }
    }, [formData.nextOfKinId]); 
    
    useEffect(() => {
      if (formData.nextOfKinId) {
          fetchData();
      }
    }, [fetchData, formData.nextOfKinId]); 
    
        
        
        const fetchReferralAgent = async (id) => {
                        const docRef = doc(db, 'referralAgents', id);
                        const docSnap = await getDoc(docRef);
                
                        if (docSnap.exists()) {
                          setSelectedAgent(docSnap.data());
                          } else {
                            console.log('No such document!');
                          }
                        };
                      
                      useEffect(() => {
                          if (formData.referralAgentId) {
                            fetchReferralAgent(formData.referralAgentId);
                          }
                        }, [formData]);


          // Fetch bodyNumber from Firestore
           const fetchBodyNumber = useCallback(async (deceasedId) => {
                      const docRef = doc(db, 'deceased', deceasedId);
                      const docSnap = await getDoc(docRef);

                      if (docSnap.exists()) {
                          setBodyNumber(docSnap.data().bodyNumber);
                      } else {
                          console.log('No such deceased document!');
                      }
                  }, []);

                  useEffect(() => {
                      if (formData.deceasedId) {
                          fetchBodyNumber(formData.deceasedId);
                      }
                  }, [formData, fetchBodyNumber]);      
                                                     
                  
                  formData.expenses.forEach((expense) => {
                    invoiceItems.push({
                      description: expense.description,
                      quantity: (parseFloat(expense.quantity)),
                      price: (parseFloat(expense.unitCost)),
                      total: (parseFloat(expense.quantity * expense.unitCost)),
                    });
                  });
                 

                  // Add storage fees                 
                  const storageQuantity = formData.daysSpent === 0 ? 1 : formData.daysSpent;
                  const storagePrice = parseFloat(selectedStorageTypeDetails?.sellingPrice || 0);
                  const totalStorageCost = parseFloat(storagePrice * storageQuantity);

                  if (!isNaN(storagePrice) && !isNaN(totalStorageCost)) {
                      invoiceItems.push({
                          description: 'Storage Fees',
                          quantity: storageQuantity,
                          price: storagePrice,
                          total: totalStorageCost,
                      });
                  } else {
                      console.error('Invalid storage fee data:', storagePrice, totalStorageCost);
                      // Handle this error appropriately, maybe set an error message
                  }
                  
                  const overallBalance = 
                    parseFloat(
                      formData.expenses.reduce((total, expense) => total + expense.unitCost * expense.quantity, 0) +
                        (selectedStorageType ? parseFloat(selectedStorageType?.sellingPrice * storageQuantity ) : 0) 
                    )
                              
                                                                           
                                                                                                                                                
         
                        const submitAndSave = async (e) => {
                          e.preventDefault();
                          setIsSubmitting(true);
                          onFormSubmitStatus('Processing...');
                          
                                let transactionCommittedSuccessfully = false; 
                                let deceasedId; 
                                let invoiceId;

                              

                                 try {     
                                                                 
                                  const isDuplicate = await checkForDuplicateRecord(formData.firstName, formData.lastName, formData.dateOfDeath);
                                  if (isDuplicate) {
                                    throw new Error('Duplicate record found. Please verify the details.');
                                                     }
                                  let uniqueBodyNumber = generateBodyNumber();
                                  const bodyNumberExists = await checkBodyNumberExists(uniqueBodyNumber);
                                  if (bodyNumberExists) {
                                    throw new Error('Generated body number already exists.');
                                  }

                                  if (!formData.expenses || !formData.nextOfKinId || !formData.selectedStorageType || !formData.dateDeposited) {
                                    throw new Error('Missing required data in formData');
                                  }
                                         
                                  const referenceData = formData.isReferred ? "Referred" : "Not Applicable";
                                  let referenceAgent = formData.referralAgentId ? formData.referralAgentId : "";

                                  const dateDepositedTimestamp = convertDateToFirestoreTimestamp(formData.dateDeposited);


                                          await runTransaction(db, async (transaction) => {
                                            // Step 1: Allocate storage space
                                            const selectedSpaceId = formData.selectedSpaceId;
                                            if (!selectedSpaceId) {
                                              throw new Error('No available spaces');
                                            }
                                                
                                              // Step 2: Create deceased record
                                              const deceasedRef = doc(collection(db, 'deceased'));                                                                                                                                                
                                                      const deceasedData = {
                                                      age: formData.age,
                                                      causeOfDeath: formData.causeOfDeath,
                                                      dateDeposited: formData.dateDeposited,
                                                      dateOfDeath: formData.dateOfDeath,
                                                      firstName: formData.firstName.toLowerCase(), 
                                                      lastName: formData.lastName.toLowerCase(), 
                                                      gender: formData.gender,
                                                      bodyNumber: uniqueBodyNumber,
                                                      wallet: parseFloat(0),
                                                      status: "in storage",
                                                      morticianComments: formData.morticianComments,
                                                      nextOfKinId: formData.nextOfKinId,
                                                      isReferred: referenceData,
                                                      expenses: formData.expenses,
                                                      referralAgentId: referenceAgent,
                                                      allocations: [],
                                                      invoices: [],
                                                      transactions: [],
                                                      outstandingBill: overallBalance,
                                                      walletTransactions: [],    
                                                      commissionApproved: false                           
                                                    };
                                                    transaction.set(deceasedRef, deceasedData);
                                                    
                                            // Step 3: Update the allocated space
                                            const spaceRef = doc(db, 'storage', formData.selectedStorageType, 'spaces', selectedSpaceId);
                                            transaction.update(spaceRef, { occupied: true, deceasedId: deceasedRef.id });

                                            
                                             // Step 4: Add to storageAllocations collection
                                              const storageAllocationData = {
                                                assignedAt: dateDepositedTimestamp,
                                                deceasedId: deceasedRef.id,
                                                storageId: selectedSpaceId,
                                                storageType: formData.selectedStorageType,
                                                storageFees: parseFloat(selectedStorageTypeDetails.sellingPrice)
                                              };
                                              const storageAllocationsRef = doc(collection(db, 'storageAllocations'));
                                              transaction.set(storageAllocationsRef, storageAllocationData);

                                              // Step 5: Update allocations in the deceased collection
                                                  transaction.update(deceasedRef, {
                                                    allocations: arrayUnion({
                                                      dateAssigned: dateDepositedTimestamp,
                                                      storageNumber: selectedSpaceId,
                                                      storageType: formData.selectedStorageType,
                                                      storageFees: parseFloat(selectedStorageTypeDetails.sellingPrice)
                                                    })
                                                  });

                                                                                 
                                              // Step 6: Create an initial approval history entry
                                              const approvalHistoryRef = doc(collection(db, `deceased/${deceasedRef.id}/approvalHistory`));
                                              const initialApprovalHistoryEntry = {
                                                actionTaken: 'created',
                                                actionBy: 'system',
                                                actionTimestamp: dateDepositedTimestamp,
                                                comments: 'Initial deceased record creation'
                                              };
                                              transaction.set(approvalHistoryRef, initialApprovalHistoryEntry);

                                               // Step 7: Update nextOfKin with deceasedId
                                                  const nextOfKinRef = doc(db, 'nextOfKin', formData.nextOfKinId);
                                                  transaction.update(nextOfKinRef, {
                                                    deceasedIds: arrayUnion(deceasedRef.id)
                                                  });

                                                  
                                                  // Step 8:  Create Invoice
                                                  const invoiceData = {
                                                    amountPaid: 0,
                                                    customerEmail: nextOfKinData?.nextOfKinEmail,
                                                    customerName: nextOfKinData?.nextOfKinName,
                                                    deceasedId: deceasedRef.id,
                                                    bodyNumber: uniqueBodyNumber,
                                                    deceasedName: selectedDeceased,
                                                    invoiceDate:  dateDepositedTimestamp,
                                                    invoiceItems: invoiceItems,
                                                    paymentForDeceased: true,
                                                    status: 'unpaid',
                                                    total: overallBalance
                                                  };
                                                  const invoiceRef = doc(collection(db, 'invoices'));
                                                  transaction.set(invoiceRef, invoiceData);
                                                  
                                                  // Step 9: Link invoice to deceased
                                                  transaction.update(deceasedRef, {
                                                    invoices: arrayUnion({
                                                     invoiceId : invoiceRef.id,
                                                     invoiceDate: dateDepositedTimestamp,
                                                    }                                                     
                                                      )
                                                  });

                                                  transactionCommittedSuccessfully = true;
                                                  deceasedId = deceasedRef.id; 
                                                  invoiceId = invoiceRef.id;

                                                }); 
                                               
                                                // Check if transaction was committed successfully
                                                if (transactionCommittedSuccessfully) {
                                                  // Prepare the data for sending the email invoice
                                                  
                                                  const emailInvoiceData = {
                                                    invoiceId,
                                                    nextOfKinEmail: nextOfKinData?.nextOfKinEmail,
                                                    customerName: nextOfKinData?.nextOfKinName,
                                                    bodyNumber: uniqueBodyNumber,
                                                    invoiceItems,
                                                    overallBalance,
                                                    invoiceDate: new Date(),
                                                    deceasedId
                                                  };
                                                  
                                                  // Sending an email invoice after transaction
                                                  await handleEmailInvoice(emailInvoiceData);

                                                  // Post transaction success actions
                                                   toast.success('Deceased record created, storage allocated, and email sent successfully.');
                                                  setCurrentStep('Complete');
                                                  navigate('/dtable'); // Or wherever you need to redirect the user to
                                                }
                                              } catch (error) {
                                                console.error('Error during submission: ', error);
                                                toast.error(error.message || 'An unexpected error occurred.');
                                                onFormSubmitStatus(error.message || 'An unexpected error occurred.');
                                              } finally {
                                                setIsSubmitting(false);
                                              }
                                            };
                                                
                                                                                                                                                                
                                                                                               
                                            async function handleEmailInvoice({invoiceId, nextOfKinEmail, customerName, bodyNumber, invoiceItems, overallBalance, invoiceDate, deceasedId}) {
                                              if (!formData.expenses) {
                                                  console.error('No Expense details available');
                                                  return;
                                              }
                                              if (!nextOfKinEmail) {
                                                  console.error('Next of Kin email is missing');
                                                  return; // Exit the function if email is undefined
                                              }
                                              const formattedInvoiceDate = moment(invoiceDate).format('DD-MM-YYYY');
                                              const functions = getFunctions();
                                              const sendInvoiceEmail = httpsCallable(functions, 'sendInvoiceEmail');
                                              try {                                            
                                                  if (!Array.isArray(invoiceItems)) {
                                                      throw new Error("Invoice items are not formatted as an array");
                                                  }
                                                  await sendInvoiceEmail({
                                                      id: invoiceId,
                                                      customerEmail: nextOfKinEmail,
                                                      customerName: customerName,
                                                      invoiceItems: JSON.stringify(invoiceItems),
                                                      bodyNumber : bodyNumber,
                                                      total: overallBalance,
                                                      invDate: formattedInvoiceDate, 
                                                      deceasedId
                                                  });                                              
                                              } catch (error) {
                                                  console.error('Failed to send invoice email:', error);
                                              }
                                          }
                                                                                                                                                                                                      
                                                                                                                                    
                                                 
                                            async function checkBodyNumberExists(bodyNumber) {
                                              const deceasedRef = collection(db, 'deceased');
                                              const q = query(deceasedRef, where('bodyNumber', '==', bodyNumber));
                                              const snapshot = await getDocs(q);
                                              return !snapshot.empty;
                                            }
                                                               
                                                                
                     

      return (
        <>
         <ToastContainer />
        
        <div className="container mx-auto p-4 sm:p-8">
          <div className="text-center mb-6">            
            <h2 className="text-xl font-semibold leading-7  text-gray-900 sm:text-2xl">Deceased Onboarding Information</h2>
            <p className="text-sm text-gray-500">Please review details provided.</p>
            </div>

        

            <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                <div className="border-t border-gray-100 px-4 py-5 sm:col-span-1 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{formData.firstName}{" "} {formData.lastName}</dd>
                </div>

                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Age of Deceased</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{formData.age} Years</dd>
                </div>

                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Deceased Gender</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{formData.gender}</dd>
                </div>

                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Cause of Death</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{formData.causeOfDeath}</dd>
                </div>

                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Date of Demisee</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">  {moment(formData.dateOfDeath).format('DD, MMM, YYYY')}</dd>
                </div>

                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Date Deposted</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">  {moment(formData.dateDeposited).format('DD, MMM, YYYY')}</dd>
                </div>

                <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            
            
            {/* Display selected storage type and space details */}
            {formData.selectedStorageType && formData.selectedSpaceId && (
                 <>                    
                
                <div className="border-t border-gray-200 px-4 py-5 sm:border-0 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Selected Space</dt>
                  <dd className="mt-1 text-sm text-gray-900">{formData.selectedSpaceId}</dd>
                </div>
              
              </>
            )}
          </dl>

                
                {nextOfKinData  ? (

                        <>
                                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Next Of Kin</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">   {nextOfKinData.nextOfKinName}</dd>
                              </div>

                              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Next Of Kin Email</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">   {nextOfKinData.nextOfKinEmail}</dd>
                                </div>

                                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">NOK Relationship </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">   {nextOfKinData.nextOfKinRelationship}</dd>
                                </div>

                                                          

                                </>
                              ) : isLoading ? (
                  <div className="text-center text-gray-500"> Loading ...</div>
                  ) : (
                    <div className="text-center text-red-500">{loadingError}</div>

                            )}
        

                                {selectedAgent ? (
                                    <>
                                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Referral Agent Name</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">  
                                {selectedAgent.title} {''} {selectedAgent.firstName} {''} {selectedAgent.lastName}</dd>
                                </div>
                            </>
                              ) : (
                          <div className='mt-10 text-sm text-red-600 font-medium'>No Referral Agent...</div>
                            )}
                  
                                  
              </dl>

              { selectedStorageTypeDetails ? (

              <div className="overflow-x-auto mt-2 mb-4">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Schedule of Onboarding Fees</h3>
              <table className="w-full border-collapse table-auto">
                <thead>
                  <tr>
                    <th className="border px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Description</th>
                    <th className="border px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                    <th className="border px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Unit Cost</th>
                    <th className="border px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Total</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {formData.expenses.map((expense, index) => (
                    <tr key={index}>
                      <td className="border px-6 py- text-sm whitespace-nowrap">{expense.description}</td>
                      <td className="border px-6 py-4 text-sm whitespace-nowrap">{expense.quantity}</td>
                      <td className="border px-6 py-4 text-sm whitespace-nowrap">{formatCurrency(parseFloat(expense.unitCost))}</td>
                      <td className="border px-6 py-4 text-sm whitespace-nowrap">{formatCurrency(expense.quantity * expense.unitCost)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td className="border px-6 py- text-sm whitespace-nowrap">Storage Fees</td>
                    <td className="border px-6 py-4 text-sm whitespace-nowrap">{storageQuantity}</td>
                    <td className="border px-6 py-4 text-sm whitespace-nowrap">{formatCurrency(parseFloat(selectedStorageTypeDetails.sellingPrice))}</td>
                    <td className="border px-6 py-4 text-sm whitespace-nowrap">{formatCurrency(parseFloat(selectedStorageTypeDetails.sellingPrice) * storageQuantity)}</td>
                  </tr>
                  
                </tbody>
                <tfoot>
                        <tr>
                      <td className="border px-6 py- text-sm whitespace-nowrap font-semibold">Amount Due</td>
                      <td className="border"></td>
                      <td className="border"></td>
                      <td className="border px-6 py-4 text-sm whitespace-nowrap font-semibold">
                        {formatCurrency(
                          parseFloat(
                            formData.expenses.reduce(
                              (total, expense) => total + expense.unitCost * expense.quantity,
                              0
                            ) + (selectedStorageTypeDetails ? parseFloat(selectedStorageTypeDetails.sellingPrice * storageQuantity ) : 0) 
                          )
                        )}
                      </td>
                    </tr>

                </tfoot>
              </table>
  </div>
              ) : (
                <div>Loading...</div>
                          )}

                  
                        
                <div className="flex items-center justify-between space-x-4"> 
                  <button
                    type="button"
                    onClick={onBack}
                    className="mt-2 bg-indigo-500 text-white text-sm hover:bg-indigo-600 px-5 py-3 rounded font-medium shadow-sm" 
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={submitAndSave}
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" // Increased padding
                  >
                    Confirm and Submit
                  </button>
                </div>


                {allocationMessage && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                  {allocationMessage}
                </div>
                 )}

                {allocationError && (
                  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    {allocationError}
                  </div>
                )}


                        {isSubmitting && (
                            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
                                <div className="loader"></div>
                            </div>
                        )}

            </div>
          </div>                

</>
    
        );
    
};

export default ConfirmationPage;
