import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { formatCurrency } from '../Deceased/helpers';


const IncomeExpenses = () => {
    const [income, setIncome] = useState(0);
    const [expense, setExpense] = useState(0);
  
    useEffect(() => {
        const fetchTransactions = async () => {
            const transactionsRef = collection(db, 'transactions');
            const querySnapshot = await getDocs(transactionsRef);
            
            let totalIncome = 0;
            let totalExpense = 0;

            querySnapshot.forEach((doc) => {
                const transaction = doc.data();
                if (transaction.transactionType === 'income') {
                    totalIncome += transaction.amount;
                } else if (transaction.transactionType === 'expense') {
                    // Ensure we're adding the absolute value of expenses
                    totalExpense += Math.abs(transaction.amount);
                }
            });

            setIncome(totalIncome);
            // Store the total expense as a positive number for display purposes
            setExpense(totalExpense);
        };

        fetchTransactions();
    }, []);

    // Ensure the calculation for Gross Profit/Loss uses expense as a positive value
    const grossProfitLoss = income - expense;

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="font-semibold text-lg text-gray-700">Total Income</h3>
                <p className="text-2xl text-green-600">{formatCurrency(income)}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="font-semibold text-lg text-gray-700">Total Expenses</h3>              
                <p className="text-2xl text-red-600">{formatCurrency(expense)}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="font-semibold text-lg text-gray-700">Gross Profit/Loss</h3>
                <p className={`text-2xl ${grossProfitLoss >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                    {formatCurrency(grossProfitLoss)}
                </p>
            </div>
        </div>
    );
};

export default IncomeExpenses;
