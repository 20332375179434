import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { addProduct } from './productService';
import AccessDenied from '../AccessDenied';
import useRoleRestriction from '../../context/useRoleRestriction';
import BreadCrumb from '../BreadCrumb';
import { toast, ToastContainer } from 'react-toastify';


const pages = [
  { name: 'Owner', to: '/settings', component: Link, current: false },
  { name: 'Manage Inventory', to: '/settings/products', component: Link, current: false },
  { name: 'Add New Inventory Product', to: '/settings/products/add', component: Link, current: true },
 
]

const AddProductForm = () => {
  const [description, setDescription] = useState('');
  const [costPrice, setCostPrice] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [keywords, setKeywords] = useState('');
  const [measurementUnit, setMeasurementUnit] = useState('');
  const [thresholdQuantity, setThresholdQuantity] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await addProduct(description, costPrice, sellingPrice, quantity, keywords, measurementUnit, thresholdQuantity);
        setDescription('');
        setCostPrice('');
        setSellingPrice('');
        setQuantity('');
        setKeywords('');
        setMeasurementUnit('');
        setThresholdQuantity('');
        toast.success('Product added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate('/settings/products');
      } catch (error) {
        console.error('Error adding product:', error.message);
        toast.error('Failed to add product. Please check your input and try again!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };

  const allowedRoles = ["Admin", "Finance"];
  const isAllowed = useRoleRestriction(allowedRoles);
  if (isAllowed === null) return <div>Loading...</div>;

  if (!isAllowed) {
    return < AccessDenied />
  }

  return (

    <>
  <BreadCrumb pages={pages} />

    <div className="container m-auto grid grid-cols-2 max-w-2xl">
    <ToastContainer />


<form onSubmit={handleSubmit}>
    
      <div className="space-y-12">
             <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Add Product Page</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            The Sale Price will be displayed publicly for Customers so be careful what you post.
          </p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            
            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <textarea
                 id="description"
                 value={description}
                 rows={3}
                 onChange={(e) => setDescription(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
              </div>
              <p className="mt-3 text-sm leading-6 text-gray-600">Add Relevant Details about the Product.</p>
            </div>

           
           
          </div>
            </div>

        <div className="border-b border-gray-900/10 pb-12">
          
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="costPrice" className="block text-sm font-medium leading-6 text-gray-900">
                Cost Price
              </label>
              <div className="mt-2">
                <input
                 type="number"
                 id="costPrice"
                 min="0"
                 step="0.01"
                 value={costPrice}
                 onChange={(e) => setCostPrice(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="sellingPrice" className="block text-sm font-medium leading-6 text-gray-900">
                Selling Price
              </label>
              <div className="mt-2">
                <input
                   type="number"
                   id="sellingPrice"
                   min="0"
                   step="0.01"
                   value={sellingPrice}
                   onChange={(e) => setSellingPrice(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="Quantity" className="block text-sm font-medium leading-6 text-gray-900">
                Quantity
              </label>
              <div className="mt-2">
                <input
                    type="number"
                    id="quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>


            <div className="sm:col-span-3">
                    <label htmlFor="measurementUnit" className="block text-sm font-medium leading-6 text-gray-900">
                        Measurement Unit
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            id="measurementUnit"
                            value={measurementUnit}
                            onChange={(e) => setMeasurementUnit(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="thresholdQuantity" className="block text-sm font-medium leading-6 text-gray-900">
                        Threshold Quantity
                    </label>
                    <div className="mt-2">
                        <input
                            type="number"
                            id="thresholdQuantity"
                            value={thresholdQuantity}
                            onChange={(e) => setThresholdQuantity(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

              <div className="sm:col-span-4">
                <label htmlFor="keywords" className="block text-sm font-medium leading-6 text-gray-900">
                  Keywords
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="keywords"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">Separate keywords with commas (e.g. "Storage, Cool, Dry")</p>
              </div>

            <div className="mt-2">
                <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Add Product
                 </button>
                 </div>
            
            </div>
      </div>
      </div>

      </form>

      
     </div>   
</>

  );
};

export default AddProductForm
