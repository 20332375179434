import React, { useState } from 'react';
import { MoonLoader } from 'react-spinners';
import { db } from '../../firebase-config';
import { Link } from 'react-router-dom'
import { collection, getDocs } from 'firebase/firestore';
import BreadCrumb from '../BreadCrumb';
import { formatCurrency } from '../Deceased/helpers';

const pages = [
    { name: 'Owners Center', to: '/settings', component: Link, current: false },  
    { name: 'Financial Reports', to: '/settings/finance', component: Link, current: false },   
    { name: 'Storage Income', to: '/settings/finance/storage-income', component: Link, current: true },     
  ]

  const StorageIncomeReport = () => {
    const [loading, setLoading] = useState(false);
    const [storageIncomeData, setStorageIncomeData] = useState({});
    const currentYear = new Date().getFullYear();
  
    const fetchStorageIncome = async () => {
      setLoading(true);
      const deceasedRef = collection(db, "deceased");
  
      try {
        const querySnapshot = await getDocs(deceasedRef);
        let totalIncomeByMonth = {};
  
        querySnapshot.forEach((doc) => {
          const deceasedData = doc.data();
          const { walletTransactions } = deceasedData;
  
          walletTransactions.forEach(transaction => {
            // Parse the date using toDate() for Firestore Timestamp
            const transactionDate = transaction.date.toDate(); // Ensure correct date parsing
            if (transactionDate.getFullYear() === currentYear && transaction.transactionType === "credit") {
              const month = transactionDate.getMonth();
              totalIncomeByMonth[month] = (totalIncomeByMonth[month] || 0) + transaction.amount;
            }
          });
        });
  
        setStorageIncomeData(totalIncomeByMonth);
      } catch (error) {
        console.error("Error fetching storage income:", error);
      } finally {
        setLoading(false);
      }
    };

 
  
  
    return (
        <>
        <BreadCrumb pages={pages} />
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold text-center mb-6">Storage Income Report for {currentYear}</h2>
        <button
          onClick={fetchStorageIncome}
          className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        >
          Get Storage Income Data
        </button>
        {loading && <div className="flex justify-center"><MoonLoader /></div>}

        {!loading && Object.keys(storageIncomeData).length > 0 && (

          <div className="overflow-x-auto bg-white shadow rounded-lg p-4 mt-4">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Month
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Income
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(storageIncomeData).map(([month, amount]) => (
                  <tr key={month}>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                      {new Date(currentYear, month).toLocaleString('default', { month: 'long' })}
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                      {formatCurrency(amount)}
                    </td>
                  </tr>
                ))}
              </tbody>
             
            </table>
          </div>
        )}
      </div>
      </>
    );
  };
  
  export default StorageIncomeReport;