import { getFunctions, httpsCallable } from 'firebase/functions';

export const sendApprovalEmail = async (commission) => {
  if (!commission) {
    console.error('No commission details available');
    return;
  }

  const functions = getFunctions();
  const sendApprovalEmailFunction = httpsCallable(functions, 'sendApprovalEmail');
  try {
    await sendApprovalEmailFunction({
      referralAgentEmail: commission.referralAgentEmail,
      deceasedName: commission.deceasedName
    });
  } catch (error) {
    // Handle or display the error
    console.error('Failed to send Approval email:', error);
  }
};
