// PersonalDetails.js
import React from 'react';
import { formatCurrency } from '../Deceased/helpers';
import {formatDate}  from '../../functions/formatDate';
import { FormatStorage } from '../Deceased/helpers';

const PersonalDetails = ({ deceased }) => {
  return (
<>
    <div className="px-8 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
    <dt className="text-sm font-medium text-gray-900">Age</dt>
    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{deceased.age}</dd>
  </div>

  <div className="px-8 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
    <dt className="text-sm font-medium text-gray-900">Gender</dt>
    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{deceased.gender}</dd>
  </div>

  <div className="px-8 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
    <dt className="text-sm font-medium text-gray-900">Cause of Death</dt>
    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{deceased.causeOfDeath}</dd>
  </div>
  <div className="px-8 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
    <dt className="text-sm font-medium text-gray-900">Date of Demise</dt>
    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatDate(deceased.dateOfDeath)}</dd>
  </div>

  <div className="px-8 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
    <dt className="text-sm font-medium text-gray-900">Date of Arrival</dt>
    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatDate(deceased.dateDeposited)}</dd>
  </div>
 
  { deceased.status === "Checked Out" &&(
      <div className="px-8 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
      <dt className="text-sm font-medium text-gray-900">Date of Exit</dt>
      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{deceased.exitDate.toDate().toLocaleDateString()}</dd>
    </div>

  )                     
  
  }

                        
  
  <div className="px-8 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
    <dt className="text-sm font-medium text-gray-900">Morticians Report</dt>
    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
    {deceased.morticianComments}
    </dd>
  </div>

          {deceased.storageType && (
          <div className="px-8 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
            <dt className="text-sm font-medium text-gray-900">Assigned Storage</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{FormatStorage(deceased.storageType)} {deceased.storageSpace}</dd>
          </div>
           )}
     </>
  );
 
};

export default PersonalDetails;
