import { collection,  getDocs, Timestamp, query, where } from 'firebase/firestore';
import { db } from '../../firebase-config';


export const convertDateToFirestoreTimestamp = (dateString) => {
  // Convert the date string to a JavaScript Date object
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error('Invalid date string:', dateString);
    return null; // Or handle the error as you see fit
  }
  
  // Use Firestore's Timestamp.fromDate() to convert it to a Firestore Timestamp
  return Timestamp.fromDate(date);
};

// export const calculateAccumulatedStorageFees = (allocations) => {
//   return allocations.reduce((sum, allocation) => {
//     const startDate = new Date(allocation.dateAssigned.seconds * 1000); // Assuming dateAssigned is a Firestore Timestamp
//     const endDate = allocation.dateUnassigned ? new Date(allocation.dateUnassigned.seconds * 1000) : new Date(); // Current date if unassigned
//     const daysInStorage = calculateDaysInStorage(startDate, endDate);
//     return sum + (daysInStorage * allocation.storageFees);
//   }, 0);
// };

export const calculateAccumulatedStorageFees = (allocations, exitDate = null) => {
  return allocations.reduce((sum, allocation) => {
    const startDate = new Date(allocation.dateAssigned.seconds * 1000); // Assuming dateAssigned is a Firestore Timestamp
    // Use provided exitDate or current date if unassigned
    const endDate = exitDate ? new Date(exitDate) : (allocation.dateUnassigned ? new Date(allocation.dateUnassigned.seconds * 1000) : new Date());
    const daysInStorage = calculateDaysInStorage(startDate, endDate);
    return sum + (daysInStorage * allocation.storageFees);
  }, 0);
};


export const checkForDuplicateDeceased = async (firstName, lastName) => {
  const deceasedRef = collection(db, 'deceased');
  const q = query(deceasedRef, where('firstName', '==', firstName), where('lastName', '==', lastName));
  const snapshot = await getDocs(q);

  if (!snapshot.empty) {
      // Return the first matching document (or handle multiple matches as needed)
      return snapshot.docs[0].data();
  }
  return null;
};





export const calculateDaysInStorage = (dateAssigned, dateUnassigned) => {
  // Ensure dateUnassigned is at least the same as dateAssigned to avoid negative days
  const endDate = dateUnassigned >= dateAssigned ? dateUnassigned : dateAssigned;

  // Calculate the difference in milliseconds
  const durationInMs = endDate.getTime() - dateAssigned.getTime();

  // Convert milliseconds to days and round up to ensure the end day is counted fully
  const durationInDays = Math.ceil(durationInMs / (1000 * 60 * 60 * 24));

  // Return the calculated duration in days, adjusted to include the end date as a full day
  return durationInDays;
};




export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
};

export const formatDate = (date) => {
  if (typeof date === "string") {
    date = convertStringToDate(date);
  }
  return date.toLocaleDateString('en-UK');
};

export const formatDateTime = (date) => {
  if (typeof date === "string") {
    date = convertStringToDate(date);
  }
  return date.toLocaleString('en-US');
};

export const convertStringToDate = (dateInput) => {
  // Check if dateInput is a Firebase Timestamp and convert to Date
  if (dateInput && typeof dateInput.toDate === 'function') {
    return dateInput.toDate();
  }

  // If no dateInput is provided or it's not a Firebase Timestamp, use the current date
  if (!dateInput) {
    return new Date();
  }

  // Handle the case where dateInput is a string
  const [year, month, day] = dateInput.split("-").map(str => parseInt(str, 10));
  return new Date(year, month - 1, day);
};


export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  return convertStringToDate(`${year}-${month}-${day}`);
};

export const timestampToDate = (timestamp) => {
  const milliseconds = (timestamp.seconds * 1000) + (timestamp.nanoseconds / 1000000);
  return new Date(milliseconds);
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const isObject = (item) => {
  return (typeof item === "object" && !Array.isArray(item) && item !== null);
};



export const generateBodyNumber = () => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let bodyNumber = '';
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    bodyNumber += charset[randomIndex];
  }
  return bodyNumber;
};



export const  capitalizeFirstLetter = (text) => {
  const capitalizedText = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  return capitalizedText;
}



export const FormatStorage = ( text ) => {
  const formattedText = text
      .split(/(?=[A-Z])/) // split the text at uppercase letters
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // capitalize the first letter of each word and make the rest lowercase
      .join(' '); // join the words with spaces
  return formattedText;
}



export const convertTimestampToString = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp.seconds * 1000); // Convert to milliseconds
    return date.toLocaleDateString("en-UK"); // Format as you need
  }
  return '';
};