// InvoicePayments.js
import React from 'react';

const InvoicePayments = ({ invoices, formatCurrency, credits, formatDate }) => {
  return (
    <div className="flex-1 bg-gray-50 p-4 rounded-lg">
      <h3 className="font-semibold text-lg mb-2">Invoice Payments Received:</h3>
      <ul className="list-disc pl-1">
        {invoices.map((invoice, index) => (
          <li className='text-sm' key={index}>
            {formatDate(invoice.invoiceDate)}: {formatCurrency(invoice.total)} ({invoice.status})
          </li>
        ))}
      </ul>
      <p className="mt-2 font-bold text-green-600">Total: {formatCurrency(credits)}</p>
    </div>
  );
};

export default InvoicePayments;
