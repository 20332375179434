// UserRoleContext.js
import { createContext, useContext, useState, useEffect } from "react";
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";

const UserRoleContext = createContext();

export function useUserRole() {
  return useContext(UserRoleContext);
}

export function UserRoleProvider({ children }) {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      } else {
        setUserRole(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    userRole,
    setUserRole,
    loading
  };

  return <UserRoleContext.Provider value={value}>{children}</UserRoleContext.Provider>;
}
