export function computeStorageDays(dateDeposited) {
    const depositDate = new Date(dateDeposited);
    const currentDate = new Date();

    // Resetting hours, minutes, seconds and milliseconds
    depositDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const differenceInMilliseconds = currentDate - depositDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return Math.floor(differenceInDays); // Return the rounded-down number of days
}

export function computeDeceasedTotalCost(deceased) {
  let totalCost = 0;

  if (deceased.allocations && deceased.allocations.length > 0) {
    deceased.allocations.forEach((allocation) => {
      const storageFee = parseFloat(allocation.storageFees);
      let daysInStorage;

      if (allocation.dateUnassigned) {
        // Calculate days between dateAssigned and dateUnassigned
        const dateAssigned = new Date(allocation.dateAssigned.toDate()); // Assuming toDate() is necessary for Firestore timestamp
        const dateUnassigned = new Date(allocation.dateUnassigned.toDate());
        dateAssigned.setHours(0, 0, 0, 0);
        dateUnassigned.setHours(0, 0, 0, 0);
        const differenceInMilliseconds = dateUnassigned - dateAssigned;
        daysInStorage = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      } else {
        // Use the existing computeStorageDays function
        daysInStorage = computeStorageDays(deceased.dateDeposited);
      }

      daysInStorage = Math.floor(daysInStorage); // Ensure we're working with whole numbers
      const costForThisAllocation = daysInStorage * storageFee;
      totalCost += costForThisAllocation;
    });
  }

  return totalCost;
}

  