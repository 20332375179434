// DeleteConfirmationModal.js
import React from 'react';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase-config';

const DeleteConfirmationModal = ({ show, onClose, agentId, onAgentDeleted, onError }) => {
  if (!show) return null;

  const handleConfirmDelete = async () => {
    try {
      await deleteDoc(doc(db, 'referralAgents', agentId));
      onAgentDeleted();
    } catch (error) {
      onError(error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-lg shadow-xl">
        <p>Are you sure you want to delete this agent?</p>
        <div className="mt-4 flex justify-end space-x-2">
          <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 rounded px-4 py-2">
            Cancel
          </button>
          <button onClick={handleConfirmDelete} className="bg-red-500 hover:bg-red-600 text-white rounded px-4 py-2">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
