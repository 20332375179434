import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { MoonLoader } from 'react-spinners';

const StorageSummary = () => {
    const [storages, setStorages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchStorages = async () => {
            setIsLoading(true);
            setLoading(true);
            try {
                const storageSnapshot = await getDocs(collection(db, 'storage'));
                const storageData = await Promise.all(storageSnapshot.docs.map(async (doc) => {
                    const storageDoc = doc.data();
                    const spacesSnapshot = await getDocs(collection(doc.ref, 'spaces'));
                    const totalSpaces = spacesSnapshot.docs.length;
                
                    // Query to find unoccupied spaces
                    const unoccupiedSpacesQuery = query(collection(doc.ref, 'spaces'), where('occupied', '==', false));
                    const unoccupiedSpacesSnapshot = await getDocs(unoccupiedSpacesQuery);
                    const unoccupiedSpaces = unoccupiedSpacesSnapshot.docs.length;
                    const occupiedSpaces = totalSpaces - unoccupiedSpaces;
                    const occupancyPercentage = totalSpaces > 0 ? ((occupiedSpaces / totalSpaces) * 100).toFixed(2) : 0; // Calculate occupancy percentage
                
                    return {
                        description: storageDoc.description,
                        totalSpaces,
                        occupiedSpaces,
                        unoccupiedSpaces,
                        occupancyPercentage // Add the calculated occupancy percentage here
                    };
                }));
                
                setStorages(storageData);
            } catch (error) {
                console.error("Error fetching storage data:", error);
                // Handle error
            }
            setLoading(false);
            setIsLoading(false);
        };

        fetchStorages();
    }, []);

    if (loading) {
        return   <div className="flex justify-center items-center h-screen">
        <MoonLoader color="#000" />
      </div>
    }

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-semibold text-center mb-4 text-gray-800">Storage Summary</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {storages.map((storage, index) => (
                    <div key={index} 
                        className="relative border border-gray-300 rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out bg-white hover:bg-blue-100">
                        <h3 className="text-lg font-bold mb-3 text-blue-700">{storage.description}</h3>
                        <p className="text-md mb-2">Total Spaces: <span className="font-semibold text-gray-700">{storage.totalSpaces}</span></p>
                        <p className="text-md">Occupied Spaces: <span className="font-semibold text-red-500">{storage.occupiedSpaces}</span></p>
                        <p className="text-md">Unoccupied Spaces: <span className="font-semibold text-green-500">{storage.unoccupiedSpaces}</span></p>
                           <span className="absolute top-0 right-0 text-2xl mt-14 mr-4 text-red-500 font-semibold">{storage.occupancyPercentage}%</span>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default StorageSummary;
