import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase-config';
import { toast,ToastContainer } from 'react-toastify';

const AddTransaction = () => {
    const [text, setText] = useState('');
    const [amount, setAmount] = useState(0);
    const [transactionType, setTransactionType] = useState('income');
    const [sourceOfIncome, setSourceOfIncome] = useState('');
    const [category, setCategory] = useState('');
    const [modeOfPayment, setModeOfPayment] = useState('');
    const [dateIncurred, setDateIncurred] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!text || !amount || !dateIncurred) {
            toast.warn('Please fill in all fields');
            return;
        }

        const user = auth.currentUser;
        const dateOfPosting = new Date();

        const newTransaction = {
            text,
            amount: parseFloat(transactionType === 'expense' ? -amount : amount),
            transactionType,
            dateIncurred: new Date(dateIncurred),
            dateOfPosting,
            user: user.displayName || user.email,
            sourceOfIncome: transactionType === 'income' ? sourceOfIncome : null,
            modeOfPayment: transactionType === 'expense' ? modeOfPayment : null,
            ...(transactionType === 'expense' && { category }),
        };

        try {
            await addDoc(collection(db, 'transactions'), newTransaction);
            toast.success('Transaction added successfully');
            
            // Reset form fields after successful submission
            setText('');
            setAmount(0);
            setTransactionType('income');
            setSourceOfIncome('');
            setCategory(''); 
            setModeOfPayment('');
            setDateIncurred('');
        } catch (error) {
            console.error("Error adding document: ", error);
            toast.error('Failed to add transaction');
        }
    };


  return (
    <>
      <div className="bg-white p-4 rounded-lg shadow-md">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    <form onSubmit={onSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
        
        <div className="flex flex-col">
            <label htmlFor="text" className="font-medium text-gray-700">
                Description
            </label>
            <input
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="p-2 border border-gray-300 rounded-md"
                placeholder="Detailed Description"
            />
        </div>
        
        <div className="flex flex-col">
            <label htmlFor="amount" className="font-medium text-gray-700">
                Amount
            </label>
            <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="p-2 border border-gray-300 rounded-md"
                placeholder="Enter amount..."
            />
        </div>

        <div className="flex flex-col">
            <label htmlFor="transactionType" className="font-medium text-gray-700">
                Transaction Type
            </label>
            <select
                value={transactionType}
                onChange={(e) => setTransactionType(e.target.value)}
                className="p-2 border border-gray-300 rounded-md"
            >
                <option value="income">Income</option>
                <option value="expense">Expense</option>
            </select>
        </div>

        <div className="flex flex-col">
            <label htmlFor="dateIncurred" className="font-medium text-gray-700">
                Date Incurred
            </label>
            <input
                type="date"
                value={dateIncurred}
                onChange={(e) => setDateIncurred(e.target.value)}
                className="p-2 border border-gray-300 rounded-md"
            />
        </div>

        {transactionType === 'income' && (
            <div className="flex flex-col col-span-2">
                <label htmlFor="sourceOfIncome" className="font-medium text-gray-700">
                    Income Source
                </label>
                <select
                    value={sourceOfIncome}
                    onChange={(e) => setSourceOfIncome(e.target.value)}
                    className="p-2 border border-gray-300 rounded-md"
                >
                    <option value="">Select income source</option>
                    <option value="Bank">Bank</option>
                    <option value="Cash">Cash</option>
                    <option value="POS">POS</option>
                </select>
            </div>
        )}

{transactionType === 'expense' && (
    <>
                                 <div className="flex flex-col">
                                <label htmlFor="category" className="font-medium text-gray-700">Expense Category</label>
                                <select
                                    id="category"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    className="p-2 border border-gray-300 rounded-md"
                                >
                                    <option value="">Select Category</option>
                                    <option value="Petrol">Petrol</option>
                                    <option value="Car Parts">Car Parts</option>
                                    <option value="Furniture">Furniture</option>
                                    <option value="Building Material">Building Material</option>
                                    <option value="Salary">Salary</option>
                                    <option value="Raw Materials">Raw Materials</option>
                                    <option value="Utilities">Utilities</option>
                                </select>
                                </div>
                            
                                <div className="flex flex-col">
                            <label className="font-medium text-gray-700 mt-2">Mode of Payment</label>

                             <div className="flex items-center mt-2">
                            <label className="flex items-center mr-4">
                                <input 
                                    type="radio" 
                                    name="modeOfPayment" 
                                    value="Cash" 
                                    onChange={(e) => setModeOfPayment(e.target.value)}
                                    className="form-radio" 
                                />
                                <span className="ml-2">Cash</span>
                            </label>
                            <label className="flex items-center">
                                <input 
                                    type="radio" 
                                    name="modeOfPayment" 
                                    value="Bank Transfer" 
                                    onChange={(e) => setModeOfPayment(e.target.value)}
                                    className="form-radio" 
                                />
                                <span className="ml-2">Bank Transfer</span>
                            </label>
                            </div>
                            </div>
            
            </>
        )}

        <button 
            className="col-span-2 mt-4 py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md"
            type="submit"
        >
            Add Transaction
        </button>

    </form>
</div>

    </>
  );
};

export default AddTransaction;
