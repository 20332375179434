// src/components/IncomeExpenditureList.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, getFirestore } from 'firebase/firestore';
import app from '../../firebase-config';
import { timestampToDate, formatDate, formatCurrency } from '../Deceased/helpers';


const IncomeExpenditureList = () => {
  const [incomeExpenditureList, setIncomeExpenditureList] = useState([]);

  useEffect(() => {
    const fetchIncomeExpenditure = async () => {
      const incomeExpenditureQuery = query(
        collection(getFirestore(app), 'incomeExpenditure'),
        orderBy('timestamp', 'desc')
      );
      const incomeExpenditureSnapshot = await getDocs(incomeExpenditureQuery);
      const incomeExpenditureData = incomeExpenditureSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setIncomeExpenditureList(incomeExpenditureData);
    };

    fetchIncomeExpenditure();
  }, []);

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold mb-4">Income & Expenditure List</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Type
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Description
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Amount
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {incomeExpenditureList.map((item, index) => (
            <tr key={item.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
              <td className="px-6 py-4 text-sm text-gray-900">{item.type}</td>
              <td className="px-6 py-4 text-sm text-gray-900">{item.description}</td>
              <td className="px-6 py-4 text-sm text-gray-900">{formatCurrency(item.amount)}</td>
              <td className="px-6 py-4 text-sm text-gray-900">
                {formatDate(timestampToDate(item.timestamp.seconds))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IncomeExpenditureList;
